<template>
  <!-- 无人仓新增修改-->
  <div class="storeAdd">
    <!-- 门店详情-->
    <div v-show="false" id="container" />
    <div class="storeAdd__left">
      <div class="fs20 fWB borderBottom paddingBottom10">{{ title }}</div>
      <div class="scrollbar marginTop10 flexGrow1">
        <div class="marginBottom10 paddingRight10 paddingLeft10">
          <el-form ref="form" :inline="true" label-position="left" :model="form" label-width="80px">
            <el-form-item label="门店编号">
              <el-input v-model="form.ccode"></el-input>
            </el-form-item>
            <el-form-item label="门店名称">
              <el-input v-model="form.cname"></el-input>
            </el-form-item>
            <el-form-item label="门店类型">
              <el-select v-model="form.cstoreType" placeholder="请选择门店类型">
                <el-option label="无人仓" value="1"></el-option>
                <el-option label="无人柜" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属商户">
              <el-select v-model="form.cmerchantId" filterable placeholder="请选择所属商户">
                <el-option v-for="item in merchantOptions" :key="item.sid" :label="item.cname" :value="item.sid" />
              </el-select>
            </el-form-item>
            <el-form-item label="管理员">
              <el-input v-model="form.cmanagerName"></el-input>
            </el-form-item>
            <el-form-item label="电话号码">
              <el-input v-model="form.cmanagerPhone"></el-input>
            </el-form-item>
            <el-form-item label="理货员">
              <el-input v-model="form.ctallymanName"></el-input>
            </el-form-item>
            <el-form-item label="电话号码">
              <el-input v-model="form.ctallymanPhone"></el-input>
            </el-form-item>
            <el-form-item label="启用日期">
              <el-date-picker v-model="value1" type="date" placeholder="选择日期" @change="handleDateChange"></el-date-picker>
            </el-form-item>
            <div class="flex">
              <div class="wFP">
                <el-form-item label="所在区域">
                  <el-cascader v-model="form.cregion" :options="options" :props="defaultProps" />
                </el-form-item>
                <el-form-item label="地址">
                  <el-select v-model="form.clocation" clearable filterable :filter-method="getReceivingAddress" placeholder="请选择" @change="changeInfo">
                    <el-option v-for="item in addrOptions" :key="item.id" :label="item.name" :value="item">
                      <div class="flex directionColumn">
                        <div>{{ item.name }}</div>
                        <div class="grey">{{ item.address }}</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="经度">
                  <el-input v-model="form.clongitude" class="w150" @input="setLongitude(form.clongitude)" />
                </el-form-item>
                <el-form-item label="纬度">
                  <el-input v-model="form.clatitude" class="w150" @input="setLatitude(form.clatitude)" />
                </el-form-item>
                <el-form-item label="详细地址">
                  <el-input v-model="form.caddr" class="w500" type="textarea" />
                </el-form-item>
              </div>
              <div class="wFP margin10">
                <el-amap :center="center" :zoom="zoom" class="hHP wHP" @AMAP_READY_EVENT="handleMapClick">
                  <el-amap-marker v-for="(marker, index) in markers" :key="index" :position="marker.position" />
                </el-amap>
              </div>
            </div>
            <div class="marginTop10">
              <div class="fs16 fw700 marginBottom10">配送范围</div>
              <div class="flex">
                <el-select v-model="radius" placeholder="请选择配送范围" class="marginBottom10">
                  <el-option v-for="item in deliveryScope" :key="item.scope" :label="item.label" :value="item.scope" />
                </el-select>
              </div>
              <div class="h500 wHP">
                <el-amap :center="center" :zoom="zoom">
                  <el-amap-circle :center="circleCenter" :radius="radius" :stroke-color="'#ADD8E6'" :fill-color="'#ADD8E6'" :stroke-opacity="1" :stroke-weight="3" :fill-opacity="0.35" />
                </el-amap>
              </div>
            </div>
            <div class="marginTop10">
              <div class="fs16 fw700">更多信息</div>
              <div class="padding10">营业时间 24小时营业</div>
            </div>
          </el-form>
        </div>
      </div>
      <div class="h50 padding10 marginTop10 borderTop">
        <el-row type="flex" justify="end">
          <el-button @click="onCancel">取 消</el-button>
          <el-button type="primary" @click="onSubmit">提 交</el-button>
        </el-row>
      </div>
    </div>
    <div class="storeAdd__right scrollbar">
      <div class="paddingTop15 paddingBottom15 borderBottom textCenter wHP">门店形象</div>
      <el-scrollbar class="flexCenter" style="height: 670px">
        <image-upload class="w150 h150 marginBottom20 marginTop10 scrollbar" @input="fileList"></image-upload>
        <div v-for="(url, index) in urls" :key="index" class="marginBottom20 scrollbar">
          <div class="w150 h150 padding10 border bcGrey marginBottom5">
            <el-image :key="url" class="hHP wHP" fit="fit" :src="url" />
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import ImageUpload from "@/components/ImageUpload";

export default {
  name: "StoreAddAndSet",
  components: { ImageUpload },
  props: {
    setId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      center: [116.397455, 39.909192], // 地图中心点的经纬度
      zoom: 15, // 地图的缩放级别
      markers: [], // 存放标记的数组
      merchantOptions: [],
      title: "",
      circleCenter: [116.397455, 39.909192], // 圆形的中心点
      radius: 1000, // 圆形的半径，单位为米
      deliveryScope: [
        { label: "一公里", scope: 500 },
        { label: "两公里", scope: 1000 },
        { label: "三公里", scope: 1500 },
        { label: "五公里", scope: 2500 },
      ],
      form: {
        ccode: "",
        cname: "",
        cstoreType: "",
        merchantName: "",
        cmanagerName: "",
        cmanagerPhone: "",
        ctallymanName: "",
        ctallymanPhone: "",
        tstartDate: "",
        cregion: "",
        clocation: "",
        clongitude: "",
        clatitude: "",
        caddr: "",
        cbusinessHours: "",
        cmerchantId: "",
      },
      urls: [],
      tempUrls: [],
      value1: "",
      options: [],
      defaultProps: {
        children: "children",
        label: "NODE_NAME",
        value: "NODE_CODE",
      },
      // 地址列表
      addrOptions: [],
      // 地址范围
      value: "",
    };
  },
  created() {
    this.queryBaseAreaList();
    this.getMerchantList();
    if (this.setId) {
      this.title = "门店修改";
      this.getStoreData();
    } else {
      this.title = "门店新增";
    }
  },
  methods: {
    changeInfo(val) {
      let longitude = val.location.lng;
      let latitude = val.location.lat;
      this.form.clocation = val.name;
      this.form.caddr = val.address;
      this.form.clongitude = longitude;
      this.form.clatitude = latitude;
      this.markers = [{ position: [longitude, latitude] }];
      this.circleCenter = [longitude, latitude];
      this.center = [longitude, latitude];
    },
    getReceivingAddress(val) {
      var self = this;
      var map = new AMap.Map("container", {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 11, //初始地图级别
      });
      var keywords = val;
      AMap.plugin("AMap.AutoComplete", function () {
        var autoOptions = {
          city: "全国",
        };
        var autoComplete = new AMap.AutoComplete(autoOptions);
        autoComplete.search(keywords, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 搜索成功时，result即是对应的匹配数据
            self.addrOptions = result.tips.filter((city) => city.id !== null && city.id !== "");
          }
        });
      });
    },
    getStoreData() {
      this.topsAjax.get(`${this.baseUrls.store}/selectById`, { sid: this.setId }).then((response) => {
        console.log(response.regionName);
        let latitude = response.store.clatitude;
        let longitude = response.store.clongitude;
        this.center = [longitude, latitude];
        this.markers = [{ position: [longitude, latitude] }];
        this.circleCenter = [longitude, latitude];
        this.form.cname = response.store.cname;
        this.form.ccode = response.store.ccode;
        this.form.cmerchantId = response.store.cmerchantId;
        this.form.caddr = response.store.caddr;
        this.form.merchantName = response.store.merchantName;
        this.form.cmanagerName = response.store.cmanagerName;
        this.form.cmanagerPhone = response.store.cmanagerPhone;
        this.form.ctallymanName = response.store.ctallymanName;
        this.form.ctallymanPhone = response.store.ctallymanPhone;
        this.form.clocation = response.store.clocation;
        this.form.clongitude = longitude;
        this.form.clatitude = latitude;
        this.form.cbusinessHours = response.store.cbusinessHours;
        if (response.store.cregion) this.form.cregion = JSON.parse(response.store.cregion);
        if (response.store.cstoreType) this.form.cstoreType = response.store.cstoreType.toString();
        if (response.store.tstartDate) {
          let date = response.store.tstartDate;
          this.form.tstartDate = moment(date).format("YYYY-MM-DD");
          this.value1 = date;
        }
      });
    },
    fileList(files) {
      if ("" === files) {
        this.urls = [];
      } else {
        this.urls = this.tempUrls.concat(files.split(","));
      }
    },
    setLongitude(longitude) {
      let newCenter = this.center;
      newCenter[0] = longitude;
      this.center = [...newCenter];
      this.circleCenter = [...newCenter];
      this.markers = [...[{ position: newCenter }]];
    },
    setLatitude(latitude) {
      let newCenter = this.center;
      newCenter[1] = latitude;
      this.center = [...newCenter];
      this.circleCenter = [...newCenter];
      this.markers = [...[{ position: newCenter }]];
    },
    /**
     * 获取商户列表
     */
    getMerchantList() {
      this.topsAjax.get(`${this.baseUrls.merchant}/selectMerchant`).then((response) => {
        this.merchantOptions = response;
      });
    },
    /**
     * 获取省市县数据
     */
    queryBaseAreaList() {
      let url = "/api/system/baseArea/queryList";
      this.topsAjax.get(url).then((res) => {
        res.forEach((item) => {
          if (item.children) {
            item.children.forEach((subItem) => {
              if (subItem.children) {
                subItem.children.forEach((thirdItem) => {
                  if (thirdItem.children) {
                    delete thirdItem.children; // 删除第四层 children
                  }
                });
              }
            });
          }
        });
        this.options = res;
      });
    },
    handleDateChange(value) {
      this.form.tstartDate = moment(new Date(value)).format("YYYY-MM-DD");
    },
    /**
     * 地图点击事件
     * @param map
     */
    handleMapClick(map) {
      let that = this;
      map.on("click", function (e) {
        let longitude = e.lnglat.getLng();
        let latitude = e.lnglat.getLat();
        that.form.clongitude = longitude;
        that.form.clatitude = latitude;
        that.markers = [{ position: [longitude, latitude] }];
        that.circleCenter = [longitude, latitude];
      });
    },
    /**
     * 提交
     */
    onSubmit() {
      const data = JSON.parse(JSON.stringify(this.form));
      if (data.tstartDate) data.tstartDate = moment(data.tstartDate).format("YYYY-MM-DDTHH:mm:ss");
      if (this.setId) {
        data.sid = this.setId;
      }
      this.$confirm("确认提交？")
        .then((_) => {
          this.topsAjax.post(`${this.baseUrls.store}/submit`, { store: data, urls: this.urls }).then((response) => {
            this.$message({
              message: this.setId ? "修改成功" : "新增成功",
              type: "success",
            });
            this.onCancel();
          });
        })
        .catch((_) => {});
    },
    onCancel() {
      this.$emit("navigateStoreList");
    },
  },
};
</script>

<style lang="scss" scoped>
.storeAdd {
  display: flex;
  height: 100%;
  padding: 10px 5px 5px 5px;

  &__left {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 85%;
    background-color: white;
    box-shadow: 1px 1px 5px #888888;
    margin-right: 20px;
  }

  &__right {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    box-shadow: 1px 1px 5px #888888;
    background-color: white;
  }
}

.thisScrollbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
<style lang="scss" scoped>
.el-select-dropdown__item {
  height: auto;
}
</style>
