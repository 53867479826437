var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ReviewInventoryIn" },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "系统单号" } }, [
            _vm._v(_vm._s(_vm.info.csys)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "入库类型" } }, [
            _vm._v(_vm._s(_vm._f("inType")(_vm.info.ptype))),
          ]),
          _c("el-descriptions-item", { attrs: { label: "原始单号" } }, [
            _vm._v(_vm._s(_vm.info.ccode)),
          ]),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "入库门店" } }, [
            _vm._v(_vm._s(_vm.info.sstore)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "入库时间" } }, [
            _vm._v(_vm._s(_vm._f("time")(_vm.info.tcreate))),
          ]),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "制单人" } }, [
            _vm._v(_vm._s(_vm.info.sin)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "制单时间" } }, [
            _vm._v(_vm._s(_vm._f("time")(_vm.info.tcreate))),
          ]),
          _c("el-descriptions-item", { attrs: { label: "审核状态" } }, [
            _vm._v(_vm._s(_vm._f("auditState")(_vm.info.paudit))),
          ]),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-descriptions",
        { attrs: { column: 1 } },
        [
          _c("el-descriptions-item", { attrs: { label: "单据备注" } }, [
            _vm._v(_vm._s(_vm.info.cremark)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h450" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: { data: _vm.tableData, height: "450" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  width: "50",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "SKU",
                  align: "center",
                  width: "70",
                  prop: "c_barcode",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "图片", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.c_master_url != null
                          ? _c("el-image", {
                              staticClass: "w50 h50",
                              attrs: {
                                "preview-src-list": [scope.row.c_master_url],
                                src: scope.row.c_master_url,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "货品编码",
                  align: "center",
                  width: "130",
                  prop: "c_sku",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "货品名称", align: "center", prop: "c_name" },
              }),
              _c("el-table-column", {
                attrs: { label: "单位", align: "center", width: "50" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库位",
                  align: "center",
                  width: "70",
                  prop: "z_inventory",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "数量",
                  align: "center",
                  width: "50",
                  prop: "n_out",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatTotal(scope.row.n_out)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "货品批次",
                  align: "center",
                  width: "100",
                  prop: "n_price",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex alignItemsCenter",
          staticStyle: { "justify-content": "space-between", flex: "1" },
        },
        [
          _c("div", [
            _vm._v(" 共计 "),
            _c("span", { staticClass: "blue" }, [
              _vm._v(_vm._s(_vm.tableData.length) + "种"),
            ]),
            _vm._v(" 货品 "),
          ]),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("el-input", {
                staticClass: "marginRight10",
                attrs: { placeholder: "请输入拒绝理由" },
                model: {
                  value: _vm.auditRemark,
                  callback: function ($$v) {
                    _vm.auditRemark = $$v
                  },
                  expression: "auditRemark",
                },
              }),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.reviewFailed(_vm.info.sid)
                    },
                  },
                },
                [_vm._v("拒绝通过")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.btnDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.approved(_vm.info.sid)
                    },
                  },
                },
                [_vm._v("审核通过")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }