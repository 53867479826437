<template>
  <div class="container">
    <div class="video_main">
      <video-player
        class="video-player"
        id="videoDiv"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
        @ended="onPlayerEnded($event)"
        @waiting="onPlayerWaiting($event)"
        @playing="onPlayerPlaying($event)"
        @loadeddata="onPlayerLoadeddata($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @canplay="onPlayerCanplay($event)"
        @canplaythrough="onPlayerCanplaythrough($event)"
        @statechanged="playerStateChanged($event)"
        @ready="playerReadied"
      ></video-player>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
export default {
  name: "index",
  components: {videoPlayer},
  props: {
    src: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: this.src,
          },
        ],
        poster: "", // 封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "加载中......", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  computed: {},
  watch: {
    src: {
      handler(newval) {
        this.playerOptions.sources[0].src = newval;
        this.srcvideo = newval;
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 播放的回调
    onPlayerPlay(e) {
      this.isplay = true;
    },
    // 暂停的回调
    onPlayerPause(e) {
      this.isplay = false;
    },
    // 结束的回调
    onPlayerEnded(e) {
      this.isplay = false;
    },
    // 等待的回调
    onPlayerWaiting(e) {},
    // 播放中回调
    onPlayerPlaying(e) {
      this.isplay = true;
    },
    // 视频加载完成的回调
    onPlayerLoadeddata(e) {
      // e.duration() 可获取视频的总时长
    },
    // 视频播放时，时间的回调
    onPlayerTimeupdate(e) {
      // this.currentVolume = (e.volume() * 100).toFixed(2) * 1;
    },
    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },
    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },
    // 视频播放时状态回调
    playerStateChanged(e) {
      // e.timeupdate 存在时视频正在播放中，e.timeupdate 值为当前播放时间
      // e.pause 存在时视频为暂停状态
      // e.play 存在时视频为播放状态
    },
    // 视频加载完成回调，此时可以赋值 myPlayer
    playerReadied(e) {
      // this.myPlayer = e;
    },
  },
};
</script>

<style scoped>
.video-js .vjs-big-play-button {
  width: 88px !important;
  height: 88px !important;
  border-radius: 50% !important;
  border: 0;
  line-height: 88px;
  font-size: 56px;
  margin-left: -50px;
  margin-top: -60px;
  object-fit: cover;
}
.video-js .vjs-control-bar {
  height: 58px;
  line-height: 58px;
  background: rgba(0, 0, 0, 0.24) !important;
}
.vjs-button > .vjs-icon-placeholder:before {
  font-size: 40px;
}
.video-js .vjs-control {
  font-size: 22px;
  line-height: 58px;
}
.vjs-playback-rate .vjs-playback-rate-value {
  line-height: 58px;
}
.vjs-button > .vjs-icon-placeholder:before {
  line-height: 60px;
}
.vjs-remaining-time-display {
  font-size: 24px;
}
.video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.5);
}
.video-js .vjs-slider {
  background-color: rgba(255, 255, 255, 0.5);
}
/* 视频封面 */
.video-js .vjs-poster {
  background-size: cover !important;
}
</style>
