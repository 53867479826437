<template>
  <!--  添加盘点表-->
  <div class="inventorySheetEdit a">
    <el-descriptions :column="3">
      <el-descriptions-item label="盘点单号">
        <div>{{ csys }}</div>
      </el-descriptions-item>
      <el-descriptions-item label="所在门店">
        {{ storeInfo.cname }}
      </el-descriptions-item>
      <el-descriptions-item label="盘点方式">
        {{ Way === "1" ? "自由盘点" : "指定盘点" }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions :column="3">
      <el-descriptions-item label="盘点类型">
        <el-select v-model="Type" placeholder="请选择">
          <el-option v-for="item in inventorySheetTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="盘点周期">
        <el-select v-model="cycle" placeholder="请选择">
          <el-option v-for="item in inventorySheetCycleOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="盘点人">
        <el-select v-model="inventoryer" multiple collapse-tags placeholder="请选择">
          <el-option v-for="item in inventoryerOptions" :key="item.user_id" :label="item.nick_name" :value="item.user_id"></el-option>
        </el-select>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <div class="flex">
      <el-descriptions :column="1">
        <el-descriptions-item label="单据备注">
          <el-input v-model="remarks" placeholder="请输入内容" clearable />
        </el-descriptions-item>
      </el-descriptions>
      <div class="w200 flex" style="justify-content: flex-end">
        <el-button v-if="Way === '2'" size="mini" @click="addWarehouse">添加库位</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="h450">
      <el-table ref="multipleTable" :data="tableData" max-height="450">
        <el-table-column type="selection" align="center" width="50" />
        <el-table-column label="序号" align="center" width="50" type="index" />
        <el-table-column label="SKU" align="center" width="70" prop="c_remark" />
        <el-table-column label="图片" align="center" width="70">
          <template #default="scope">
            <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="货品编码" align="center" width="120" prop="c_sku" />
        <el-table-column label="货品名称" align="center" prop="s_goods" />
        <el-table-column label="单位" align="center" width="70" />
        <el-table-column label="库位号" align="center" width="70" prop="c_num">
          <template #default="scope">
            {{ scope.row.c_num ? scope.row.c_num : scope.row.c_warehouse }}
          </template>
        </el-table-column>
        <el-table-column label="库存数" align="center" width="70" prop="n_surplus" />
      </el-table>
    </div>
    <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
      <div />
      <div>
        <el-button plain @click="doSubmit(1)">保存</el-button>
        <el-button type="primary" plain @click="doSubmit(2)">提交</el-button>
      </div>
    </div>

    <el-dialog v-if="addWarehouseDialogVisible" class="elDialog" :close-on-click-modal="false" title="选择库位" width="670px" :visible.sync="addWarehouseDialogVisible" append-to-body>
      <div class="flex">
        <div class="wFP paddingRight10">
          <div class="flex marginBottom10 flexSpaceBetween">
            <el-select v-model="shelves" placeholder="请选择货层" @change="inventorySelectWarehouse">
              <el-option v-for="item in shelvesOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-model="layer" placeholder="请选择货架" class="marginLeft5" @change="inventorySelectWarehouse">
              <el-option v-for="item in layerOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
          <el-input v-model="search" clearable placeholder="库位/SKU/编号/货品名称" class="input-with-select marginBottom10" @change="inventorySelectWarehouse">
            <el-button slot="append" icon="el-icon-search" @click="inventorySelectWarehouse" />
          </el-input>
          <div class="h400 scrollAuto cardHeader">
            <el-card v-for="warehouse in warehouseList" :key="warehouse.warehouseId" class="box-card">
              <div slot="header">
                <el-checkbox v-model="warehouse.checked" @change="handleSelectOne(warehouse)">
                  <span class="fs12">{{ warehouse.c_num }}</span>
                </el-checkbox>
                <span class="fs12 black">
                  SKU
                  <span class="fs12 grey">{{ warehouse.c_remark }}</span>
                </span>
                <span class="fs12 black">
                  编码
                  <span class="fs12 grey">6901028075862</span>
                </span>
              </div>
              <div class="text item flex h50">
                <div class="w75P paddingRight20 fs13">
                  {{ warehouse.s_goods ? warehouse.s_goods : "..." }}
                  <div class="fs12">{{ warehouse.c_remark ? warehouse.c_remark : "... " }}</div>
                </div>
                <div class="flex1 fs30">{{ warehouse.n_surplus ? warehouse.n_surplus : "NA" }}</div>
              </div>
            </el-card>
          </div>
          <div class="h20 marginTop10">
            <el-checkbox v-model="checked" @change="handleSelectAll">全选</el-checkbox>
          </div>
        </div>
        <div class="wFP paddingLeft10 flex flexSpaceBetween directionColumn">
          <div class="scrollAuto border flexGrow1 borderRadius5 padding10 flex directionColumn">
            <div class="fs12 marginBottom10">选中的库位</div>
            <div class="flex1">
              <div class="h450 wHP scrollAuto">
                <el-tag v-for="tag in tags" :key="tag.warehouseId" class="marginRight5 marginTop5" closable :type="tag.type">
                  {{ tag.c_num }}
                </el-tag>
              </div>
            </div>
          </div>
          <div class="h20 marginTop10 fs12 black">
            共选择 <span class="fs12" style="color: #67c23a">{{ tags.length }}</span> 个库位, <a class="blue fs12" @click="handleClear">清除</a>
          </div>
        </div>
      </div>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="onConfirm">确定</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { determineExists } from "@/utils/utils";

export default {
  name: "InventorySheetEdit",
  components: {},
  filters: {
    getWay(value) {
      const option = this.inventorySheetWayOptions.find((option) => option.value === value);
      return option ? option.label : "";
    },
  },
  props: {
    setWay: {
      type: [String, Object, Array],
      default: "",
    },
    storeId: {
      type: [String, Object, Array],
      default: "",
    },
    inventoryId: {
      type: [String, Object, Array],
      default: "",
    },
  },
  data() {
    return {
      storeInfo: {},
      csys: "",
      // 入库单类型
      Type: "",
      inventorySheetTypeOptions: [
        { value: "1", label: "日常盘库" },
        { value: "2", label: "临时盘库" },
      ],
      Way: "",
      inventorySheetWayOptions: [
        { value: "1", label: "自由盘点" },
        { value: "2", label: "指定盘点" },
      ],
      cycle: "",
      inventorySheetCycleOptions: [
        { value: "1", label: "按年盘点" },
        { value: "2", label: "按季盘点" },
        { value: "3", label: "按月盘点" },
      ],
      inventoryer: [],
      inventoryerOptions: [],
      remarks: "",
      //
      tableData: [],
      checked: false,
      // 添加库位字段 -----------------------------------
      addWarehouseDialogVisible: false,
      warehouseList: [],
      shelvesOptions: [
        { value: "1", label: "1号货架" },
        { value: "2", label: "2号货架" },
        { value: "3", label: "3号货架" },
        { value: "4", label: "4号货架" },
        { value: "5", label: "5号货架" },
        { value: "6", label: "6号货架" },
      ],
      shelves: "",
      layerOptions: [
        { value: "1", label: "1层" },
        { value: "2", label: "2层" },
        { value: "3", label: "3层" },
        { value: "4", label: "4层" },
        { value: "5", label: "5层" },
        { value: "6", label: "6层" },
      ],
      layer: "",
      search: "",
      tags: [],
    };
  },
  created() {
    this.queryTallyList();
    if (determineExists(this.inventoryId)) {
      this.getInventoryInfo(this.inventoryId);
    } else {
      this.Way = this.setWay;
      this.getStoreInfo(this.storeId);
    }
  },
  methods: {
    queryTallyList() {
      let url = `${this.baseUrls.user}/queryTallyList`;
      this.topsAjax.get(url).then((res) => {
        this.inventoryerOptions = res;
      });
    },
    onConfirm() {
      this.tableData = this.tags;
      this.addWarehouseDialogVisible = false;
    },
    handleClear() {
      this.warehouseList.forEach((item) => {
        item.checked = false;
      });
      this.tags = [];
      this.checked = false;
    },
    handleSelectOne(warehouse) {
      if (warehouse.checked) {
        if (!this.tags.some((tag) => tag.warehouseId === warehouse.warehouseId)) {
          this.tags.push(warehouse);
        }
      } else {
        this.tags = this.tags.filter((tag) => tag.warehouseId !== warehouse.warehouseId);
      }
    },
    handleSelectAll(value) {
      this.warehouseList.forEach((item) => {
        item.checked = value;
      });
      if (value) {
        this.warehouseList.forEach((item) => {
          if (!this.tags.some((tag) => tag.warehouseId === item.warehouseId)) {
            this.tags.push(item);
          }
        });
      } else {
        this.tags = [];
      }
    },
    addWarehouse() {
      this.warehouseList = [];
      this.tags = this.tableData;
      this.shelves = "";
      this.layer = "";
      this.search = "";
      this.checked = false;
      this.addWarehouseDialogVisible = true;
      this.inventorySelectWarehouse();
    },
    //
    selectChange(value) {
      if (value === "1") this.tableData = [];
    },
    inventorySelectWarehouse() {
      let url = `${this.baseUrls.warehouse}/inventorySelectWarehouse`;
      this.topsAjax.get(url, { storeId: this.storeInfo.sid, shelves: this.shelves, layer: this.layer, search: this.search }).then((response) => {
        this.warehouseList = response;
        this.warehouseList.forEach((warehouse) => {
          warehouse.checked = this.tags.some((tagItem) => tagItem.warehouseId === warehouse.warehouseId);
        });
      });
    },
    // 获取门店信息
    getStoreInfo(id) {
      let url = `${this.baseUrls.store}/get/` + id;
      this.topsAjax.get(url).then((res) => {
        this.storeInfo = res;
      });
    },
    // 获取盘点表信息
    getInventoryInfo(id) {
      let url = `${this.baseUrls.inventorySheet}/getInventoryInfo`;
      let params = {
        id: id,
      };
      this.topsAjax.get(url, params).then((res) => {
        let inventory = res.data.inventory;
        this.tableData = res.data.inventoryDetailList;
        this.storeInfo.sid = inventory.rstore;
        this.storeInfo.cname = inventory.cstore;
        if (determineExists(inventory.csys)) this.csys = inventory.csys;
        if (determineExists(inventory.cremark)) this.remarks = inventory.cremark;
        if (determineExists(inventory.pway)) this.Way = inventory.pway.toString();
        if (determineExists(inventory.ptype)) this.Type = inventory.ptype.toString();
        if (determineExists(inventory.pcycle)) this.cycle = inventory.pcycle.toString();
        if (inventory.rinventoryer.length > 0) {
          let parsedArray = JSON.parse(inventory.rinventoryer);
          this.inventoryer = [...parsedArray];
        }
      });
    },
    // 取消
    onCancel() {
      this.$emit("handleClose");
    },
    doSubmit: _.debounce(function (state) {
      this.addOrUpdate(state);
    }, 500),
    addOrUpdate: function (state) {
      let url;
      let inventoryIds = [];
      let inventoryNames = [];
      this.inventoryer.forEach((p) => {
        let inventory = this.inventoryerOptions.find((option) => option.user_id === p);
        inventoryIds.push(inventory.user_id);
        inventoryNames.push(inventory.nick_name);
      });
      let parameter = {
        inventorySheet: {
          rstore: this.storeInfo.sid,
          cstore: this.storeInfo.cname,
          ptype: this.Type,
          pway: this.Way,
          pcycle: this.cycle,
          pstate: state,
          cremark: this.remarks,
          sinventoryer: inventoryNames,
          rinventoryer: inventoryIds,
        },
      };
      if (determineExists(this.inventoryId)) {
        parameter.inventorySheet.sid = this.inventoryId;
        url = `${this.baseUrls.inventorySheet}/update`;
        parameter.goodsList = this.tableData;
      } else {
        url = `${this.baseUrls.inventorySheet}/add`;
        parameter.goodsList = this.tableData;
      }
      this.topsAjax.post(url, parameter).then((res) => {
        if (res) {
          this.$message({
            message: this.inventoryId ? "提交成功" : "新增成功",
            type: "success",
          });
          this.onCancel();
        }
      });
    },
  },
};
</script>
<style lang="scss">
.inventorySheetEdit {
  display: flex;
  flex-direction: column;

  .el-divider--horizontal {
    margin: 8px 0;
  }

  .el-input--medium .el-input__inner {
    height: 20px;
    line-height: 20px;
  }

  .el-input__inner {
    border: 0px;
  }

  .el-descriptions__body {
    margin-left: 0px;
  }

  .el-input__icon {
    display: flex !important;
    align-items: center !important;
  }
}
</style>
<style lang="scss">
.cardHeader {
  .el-card__header {
    padding: 8px 15px !important;
  }
}
</style>
