<template>
  <!-- 入库制单-->
<div class="AddInventoryIn">
      <el-descriptions :column="3">
        <el-descriptions-item label="货品SKU">{{ goodsInfo.cbarcode }}</el-descriptions-item>
        <el-descriptions-item label="货品编号">{{ goodsInfo.csku }}</el-descriptions-item>
        <el-descriptions-item label="货品类别"></el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="3">
        <el-descriptions-item label="货品名称">{{ goodsInfo.cname }}</el-descriptions-item>
        <el-descriptions-item label="货品成本价"></el-descriptions-item>
        <el-descriptions-item label="货品零售价">{{ goodsInfo.nprice }}</el-descriptions-item>
      </el-descriptions>
      <div class="h500">
        <el-table ref="multipleTable" :data="tableData" max-height="450" :row-class-name="tableRowClassName">
          <el-table-column type="selection" align="center" width="50" />
          <el-table-column label="序号" align="center" width="50" type="index" />
          <el-table-column label="单据类型" align="center" width="70">
              <template slot-scope="scope">
                <div>{{ scope.row.type | tableType }}</div>
              </template>
          </el-table-column>
          <el-table-column label="出入类型" align="center" width="70">
              <template slot-scope="scope">
                <div>{{ formatPtype(scope.row) }}</div>
              </template>
          </el-table-column>
          <el-table-column label="单据编号" align="center" width="180" prop="c_sys">
            <template slot-scope="scope">
          <div class="hHP wHP flex marginLeft10" style="white-space: break-spaces;">
            <el-link type="primary" @click="handleShow(scope.row)">{{ scope.row.c_sys }}</el-link>
          </div>
        </template>
          </el-table-column>
          <el-table-column label="库位号" align="center" prop="z_inventory" />
          <el-table-column label="发生数量" align="center" width="80" prop="n_out" />
          <el-table-column label="制单人" align="center"  prop="s_name"/>
          <el-table-column label="单据状态" align="center" width="70">
            <template slot-scope="scope">
              <div>{{ scope.row.p_audit | orderAudit }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="更新时间">
            <template slot-scope="scope">
              <div>{{ scope.row.t_time | time }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination :current-page="queryParams.currentPage" :page-size="queryParams.pageSize" :page-sizes="pageSizes" :total="total" layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        </div>
      </div>
      <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
        <div>
        </div>
        <div>
          <!-- <el-button plain @click="onSubmit(0)">打印</el-button> -->
          <el-button plain @click="handleExport">导出Excel</el-button>
          <el-button type="primary" plain @click="onSubmit(1)">确定</el-button>
        </div>
      </div>
      <el-dialog class="elDialog" :close-on-click-modal="false" title="入库单查看" :visible.sync="inventoryInDetailDialogVisible" width="800px" append-to-body>
      <inventoryInDetail v-if="inventoryInDetailDialogVisible" :inventory-in="checkInventoryIn" @handleClose="handleClose" />
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="出库单查看" :visible.sync="inventoryOutDetailDialogVisible" width="800px" append-to-body>
      <inventoryOutDetail v-if="inventoryOutDetailDialogVisible" :inventory-in="checkInventoryIn" @handleClose="handleClose" />
    </el-dialog>
    </div>
</template>
<script>
import moment from "moment";
import { tableType, orderAudit, orderInType, orderOutType } from "@/utils/inventory";
import { getGoodsById, exportInventoryDetail } from "@/api/goods/goods";
import inventoryInDetail from "@/views/store/inventoryOrder/inventoryInDetail.vue";
import inventoryOutDetail from "@/views/store/inventoryOrder/inventoryOutDetail.vue";

export default {
  name: "goodsInventory",
  components: {
    inventoryInDetail,
    inventoryOutDetail
  },
  props: {
    storeId: {
      type: [String],
    },
    goodsId: {
      type: [String],
    },
  },
  filters: {
    time(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    tableType(value) {
      return tableType(value);
    },
    orderAudit(value) {
      return orderAudit(value);
    },
  },
  data() {
    return {
      tableData: [],
      storeInfo: {
        cname: "",
        sid: "",
      },
      goodsInfo: {
        cbarcode: "",
        csku: ""
      },
      queryParams: {
        currentPage: 1,
        pageSize: 10,
      },
      pageSizes: [10, 20, 30, 50, 100],
      total: 0,
      inventoryInDetailDialogVisible: false,
      inventoryOutDetailDialogVisible: false,
      checkInventoryIn: null
    };
  },
  mounted() {
    this.getStoreInfo(this.storeId);
    this.getGoodsInfo(this.goodsId)
    this.queryList()
  },
  methods: {
    handleShow(row) {
      if (row.type === 0) {
        this.checkInventoryIn = row;
        this.inventoryInDetailDialogVisible = true
      } else {
        this.checkInventoryIn = row;
        this.inventoryOutDetailDialogVisible = true
      }
    },
    handleClose() {
      this.inventoryInDetailDialogVisible = false
      this.inventoryOutDetailDialogVisible = false
    },
    formatPtype(row) {
      if (row.type === 0) {
        return orderInType(row.p_type)
      } else {
        return orderOutType(row.p_type)
      }
    },
    queryList() {
      let url = `${this.baseUrls.inventory}/selectDetailByStoreId`;
      let params = this.queryParams;
      params.storeId = this.storeId;
      params.goodsId = this.goodsId;
      this.topsAjax.get(url, params).then((res) => {
        this.total = res.total;
        this.tableData = res.list;
      });
    },
    tableRowClassName({row}) {
      let classes = ''
      if (row.rinventoryOrder) {
        classes = "red";
      }
      if (row.paudit == 5 || row.paudit == 4) {
        classes += " discard-row4"
      }
      return classes;
    },

    getGoodsInfo() {
      getGoodsById(this.goodsId).then((response) => {
        this.goodsInfo = response.data;
      });
    },
    /**
     * 确定
     */
    onSubmit() {
      
            this.$emit("handleClose");
      
    },
    handleExport() {
      exportInventoryDetail({csku: this.goodsInfo.cbarcode, storeId: this.storeInfo.sid, goodsId: this.goodsInfo.sid })
    },
    getStoreInfo(id) {
      let url = `${this.baseUrls.store}/get/` + id;
      this.topsAjax.get(url).then((res) => {
        this.storeInfo = res;
      });
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      this.queryList();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryList();
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table .discard-row4 {
  text-decoration: line-through;
}
.allotOrderOut {
  .el-table__header-wrapper .el-checkbox {
    display: none !important;
  }
}
.AddInventoryIn {
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .el-divider--horizontal {
    margin: 8px 0;
  }

  .el-input--medium .el-input__inner {
    height: 20px;
    line-height: 20px;
  }

  .el-input__inner {
    border: 0px;
  }

  &__hideColon {
    .el-descriptions-item__label:not(.is-bordered-label) {
      display: none;
    }
  }

  .el-descriptions__body {
    margin-left: 0px;
  }

  .el-input__icon {
    display: flex !important;
    align-items: center !important;
  }
}
</style>
