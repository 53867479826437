var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "left",
            "label-width": "120px",
            rules: _vm.rules,
            model: _vm.form,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "设备厂商" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.cmanufacturer,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cmanufacturer", $$v)
                  },
                  expression: "form.cmanufacturer",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备编号", prop: "ccode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.ccode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ccode", $$v)
                  },
                  expression: "form.ccode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.cname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cname", $$v)
                  },
                  expression: "form.cname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备类型", prop: "btype" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择设备类型" },
                  model: {
                    value: _vm.form.btype,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "btype", $$v)
                    },
                    expression: "form.btype",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "云主机", value: "0" } }),
                  _c("el-option", {
                    attrs: { label: "4G集成主机", value: "1" },
                  }),
                  _c("el-option", {
                    attrs: { label: "网口集成主机", value: "2" },
                  }),
                  _c("el-option", {
                    attrs: { label: "灯光&4G双路物联主机", value: "3" },
                  }),
                  _c("el-option", { attrs: { label: "视频监控", value: "4" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.btype === "4"
            ? _c(
                "el-form-item",
                { attrs: { label: "设备密码", prop: "cpwd" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.cpwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cpwd", $$v)
                      },
                      expression: "form.cpwd",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "启用状态" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.bvalid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bvalid", $$v)
                  },
                  expression: "form.bvalid",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.btnLoading, type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }