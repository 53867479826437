<template>
  <!-- 设备管理-->
  <div class="storeDeviceList bcWhite box-shadow padding10">
    <div class="fs20 fWB borderBottom paddingBottom10">门店列表</div>
    <dc-search-panel :params="queryPara" @search="doSearch" @reset="doSearch">
      <div class="align-items">
        <div>
          <el-input id="searchInput" v-model="queryPara.cname" clearable placeholder="输入门店名称或地址" class="w250 input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
          </el-input>
        </div>
        <!--        <div>-->
        <!--          <div class="button-group">-->
        <!--            <el-button class="marginRight5" size="mini" @click="exportTemplate">模板导出</el-button>-->
        <!--            <el-upload action="/api/system/device/importDevice" :data="uploadParams" :headers="headers" multiple :limit="3" :file-list="fileList">-->
        <!--              <el-button size="mini" type="primary" @click="importTemplate">设备导入</el-button>-->
        <!--            </el-upload>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </dc-search-panel>
    <dc-datatable ref="list" :url="urlApi" :params="queryPara">
      <el-table-column align="center" type="index" label="序" width="100" />
      <el-table-column align="left" header-align="center" label="门店名称">
        <template slot-scope="scope">
          <div class="paddingLeft10">
            <div slot="reference" class="cornflowerblue pointer" @click="openDialog(scope.row)">
              {{ scope.row.cname }}
            </div>
            <div slot="reference" class="fs12 lightgray">{{ scope.row.clocation }}（{{ scope.row.caddr }}）</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="类型" width="100">
        <template slot-scope="scope">
          {{ scope.row.cstoreType === 1 ? "无人仓" : "无人柜" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="cmanagerName" label="管理员" width="100" />
      <el-table-column align="center" prop="cmanagerPhone" label="手机号" width="150">
        <template slot-scope="scope">
          <div slot="reference" class="cornflowerblue pointer">
            {{ scope.row.cmanagerPhone }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作" width="100">
        <template slot-scope="scope">
          <!--          <el-button type="text" size="mini" @click="locationManage(scope.row.sid)">管理</el-button>-->
          <el-button type="text" size="mini" @click="openDialog(scope.row)">管理</el-button>
        </template>
      </el-table-column>
    </dc-datatable>
    <el-dialog :top="'5vh'" :visible.sync="dialogVisible" width="90%" append-to-body :title="title">
      <StoreDevice v-if="dialogVisible" :store-name="title" :store-id="storeId" @handleClose="onCancer" />
    </el-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { exportDeviceTemplate, importDeviceTemplate } from "@/api/device/device";
import StoreDevice from "@/views/store/storeDevice.vue";
import { getToken } from "@/utils/auth";
export default {
  name: "StoreDeviceList",
  components: {
    StoreDevice,
  },
  props: {
    merchantList: {
      type: Array,
      default: null,
    },
    baseAreaList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      urlApi: `${this.baseUrls.store}/queryPageList`,
      uploadParams: {
        updateSupport: true,
      },
      fileList: [],
      headers: {
        Authorization: getToken(),
      },
      queryPara: {
        cname: null,
        baseArea: null,
        merchant: null,
      },
      //弹窗信息
      dialogVisible: false,
      title: "",
      storeId: "",
    };
  },
  watch: {
    merchantList() {
      this.queryPara.merchant = this.merchantList + "";
      this.doSearch();
    },
    baseAreaList() {
      this.queryPara.baseArea = this.baseAreaList + "";
      this.doSearch();
    },
  },
  mounted() {
    let that = this;
    var searchInput = document.getElementById("searchInput");
    searchInput.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        that.doSearch();
      }
    });
  },
  methods: {
    onCancer() {
      this.title = "";
      this.storeId = "";
      this.dialogVisible = false;
    },
    /**
     * 门店详细
     * @constructor
     */
    storeInfo(id) {
      this.$emit("StoreInfo", id);
    },
    locationManage(sid) {
      this.$emit("DeviceManage", sid);
    },
    openDialog(store) {
      this.title = store.cname;
      this.storeId = store.sid;
      this.dialogVisible = true;
    },
    exportTemplate() {
      importDeviceTemplate();
    },
    importTemplate() {},
    doSearch: _.debounce(function () {
      // 这里是你想要执行的代码
      this.$refs.list.load();
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.storeDeviceList {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .el-dialog {
    margin-top: 5vh;
  }
}

.align-items {
  display: flex;
  justify-content: space-between;
}

.button-group {
  display: flex;
}
</style>
