<template>
  <!-- 无人仓库位新增修改-->
  <div>
    <el-form ref="form" :rules="rules" label-position="left" label-width="120px" :model="form">
      <el-form-item label="库位编码" prop="cnum">
        <el-input v-model="form.cnum"></el-input>
      </el-form-item>
      <el-form-item label="库位名称" prop="cname">
        <el-input v-model="form.cname"></el-input>
      </el-form-item>
      <el-form-item label="库位容量" prop="ntotal">
        <el-input v-model="form.ntotal" @input="handleInput"></el-input>
      </el-form-item>
      <el-form-item label="排序号" prop="nindex">
        <el-input v-model="form.nindex"></el-input>
      </el-form-item>
      <el-form-item label="库位描述">
        <el-input v-model="form.cremark" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="启用状态">
        <el-switch v-model="form.bvalid"></el-switch>
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="onSubmit">提 交</el-button>
    </el-row>
  </div>
</template>
<script>
import { rules as rule } from "./addLocationRules";
export default {
  name: "AddLocation",
  props: {
    storeId: {
      type: String, // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
    warehouseId: {
      type: String, // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      rules: rule,
      form: {
        sid: "",
        cnum: "",
        cname: "",
        ntotal: "",
        bvalid: true,
        nindex: "",
        cremark: "",
      },
    };
  },
  created() {
    if (this.warehouseId) {
      this.selectWarehouseById();
    }
  },
  methods: {
    handleInput(value) {
      // 先移除所有非数字字符，这也将删除负号和小数点
      let newValue = value.replace(/[^\d]/g, "");

      // 将得到的字符串转换为数字，然后判断是否大于零
      // 如果不是数字或不是正整数，则清空输入，或者可以设为默认值如1
      newValue = parseInt(newValue, 10);
      if (!newValue || newValue <= 0) {
        this.form.ntotal = ""; // 或者可以设置为 '1' 或其他默认正整数值
      } else {
        // 是正整数，更新模型值
        this.form.ntotal = newValue.toString(); // 因为v-model绑定需要字符串
      }
    },
    selectWarehouseById() {
      this.topsAjax.get(`${this.baseUrls.warehouse}/selectById`, { sid: this.warehouseId }).then((response) => {
        this.form.sid = response.sid;
        this.form.cnum = response.cnum;
        this.form.cname = response.cname;
        this.form.nindex = response.nindex;
        this.form.ntotal = response.ntotal;
        this.form.cremark = response.cremark;
        this.form.bvalid = response.bvalid === 0;
      });
    },
    //提交
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交？")
            .then((_) => {
              const data = JSON.parse(JSON.stringify(this.form));
              data.cstoreId = this.storeId;
              data.bvalid = data.bvalid === true ? 0 : 1;
              this.topsAjax.post(`${this.baseUrls.warehouse}/saveOrUpdate`, data).then((response) => {
                this.$message({
                  message: data.sid ? "修改成功" : "新增成功",
                  type: "success",
                });
                this.cancel();
              });
            })
            .catch((_) => {});
        } else {
        }
      });
    },
    //取消
    cancel() {
      this.$emit("handleClose");
    },
  },
};
</script>
