var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "el-input",
            {
              staticClass: "w300",
              attrs: {
                clearable: "",
                placeholder: "货品名称/货品编号/SKU/库位",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.queryPageStoreGoods.apply(null, arguments)
                },
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.queryPageStoreGoods },
                slot: "append",
              }),
            ],
            1
          ),
          _c(
            "el-button-group",
            { staticStyle: { "margin-left": "auto" } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "货品上架",
                    placement: "top-end",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { type: "primary", icon: "el-icon-plus" },
                    on: { click: _vm.handleGoodsAdd },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "货品导出",
                    placement: "top-end",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { type: "primary", icon: "el-icon-download" },
                    on: { click: _vm.developing },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-radio-group",
            {
              staticClass: "marginLeft10 storeGoodsButtonGroup",
              on: { change: _vm.handleChange },
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "详情",
                    placement: "top-end",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 10 } }, [
                    _c("i", { staticClass: "el-icon-s-unfold" }),
                  ]),
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "内容",
                    placement: "top-end",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 9 } }, [
                    _c("i", { staticClass: "el-icon-menu" }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.radio === 10,
              expression: "radio === 10",
            },
          ],
          ref: "table2",
          staticClass: "marginTop10",
          attrs: {
            height: "500",
            data: _vm.goodsData,
            border: "",
            "summary-method": _vm.getSummaries,
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "index",
              label: "序号",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "图片", width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.c_master_url != null
                      ? _c("el-image", {
                          staticClass: "w50 h50",
                          attrs: {
                            "preview-src-list": [scope.row.c_master_url],
                            src: scope.row.c_master_url,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "c_remark",
              label: "SKU",
              width: "70",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "c_sku",
              label: "货品编码",
              width: "120",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "c_name",
              label: "货品名称",
              width: "180",
              "show-overflow-tooltip": true,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "hHP wHP flex marginLeft10",
                        staticStyle: { "white-space": "break-spaces" },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleViewGoods(scope.row.goodsId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.c_name))]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "s_unit",
              label: "单位",
              width: "70",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "n_price",
              label: "零售价",
              width: "70",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.n_price ? "￥" + scope.row.n_price : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "n_total",
              label: "实际库存",
              width: "90",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.formatTotal(scope.row)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "n_surplus",
              label: "可用库存",
              width: "90",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.formatSurplus(scope.row)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "c_warehouse",
              label: "存放库位",
              width: "90",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showGoodsInventory(scope.row.goodsId)
                          },
                        },
                      },
                      [_vm._v("往来")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showGoodsInventorySet(scope.row)
                          },
                        },
                      },
                      [_vm._v("设置")]
                    ),
                    scope.row.r_warehouse
                      ? _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.offById(scope.row)
                              },
                            },
                          },
                          [_vm._v("下架货品")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "blue",
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShelvesUp(scope.row)
                              },
                            },
                          },
                          [_vm._v("重新上架")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.radio === 9
        ? _c(
            "el-row",
            {
              staticClass: "marginTop10",
              staticStyle: { height: "500px", overflow: "auto" },
              attrs: { gutter: 10 },
            },
            _vm._l(_vm.goodsData, function (goodsItem, index) {
              return _c("el-col", { key: index, attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "marginBottom10" },
                  [
                    _c(
                      "el-card",
                      {
                        attrs: {
                          "body-style": { padding: "0", height: "135px" },
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { staticClass: "margin10", attrs: { gutter: 10 } },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "bg-purple",
                                  staticStyle: { "margin-top": "5px" },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "61px",
                                      height: "61px",
                                    },
                                    attrs: {
                                      src: goodsItem.c_master_url,
                                      fit: "fit",
                                      "preview-src-list": [
                                        goodsItem.c_master_url,
                                      ],
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "textCenter" }, [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#909399" } },
                                  [_vm._v("零售价")]
                                ),
                              ]),
                              _c("div", { staticClass: "textCenter" }, [
                                _vm._v(_vm._s(goodsItem.n_price)),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: {
                                      display: "-webkit-box",
                                      "-webkit-box-orient": "vertical",
                                      "line-clamp": "2",
                                      "-webkit-line-clamp": "2",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "font-weight": "800",
                                    },
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleViewGoods(
                                          goodsItem.goodsId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(goodsItem.c_name))]
                                ),
                                _c("div", [
                                  _vm._v("商品大类 - 商品中类 - 商品小类"),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#909399" } },
                                    [_vm._v("货品SKU:")]
                                  ),
                                  _vm._v(" " + _vm._s(goodsItem.c_sku)),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#909399" } },
                                    [_vm._v("所在库位: ")]
                                  ),
                                  _vm._v(_vm._s(goodsItem.c_num)),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#909399" } },
                                    [_vm._v("可用库存: ")]
                                  ),
                                  _vm._v(_vm._s(goodsItem.n_surplus)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "货品上架",
            visible: _vm.dialogData.dialogVisible,
            width: "60%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogData, "dialogVisible", $event)
            },
          },
        },
        [
          _vm.dialogData.dialogVisible
            ? _c("GoodsAdd", {
                attrs: { "store-data": _vm.storeData },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "出入详情",
            visible: _vm.dialogData.goodsInventoryDialogVisible,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.dialogData,
                "goodsInventoryDialogVisible",
                $event
              )
            },
          },
        },
        [
          _vm.dialogData.goodsInventoryDialogVisible
            ? _c("goodsInventoryInOut", {
                attrs: { "store-id": _vm.storeId, "goods-id": _vm.goodsId },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "货品重新上架",
            visible: _vm.dialogData.goodsShelvesGoodsDialogVisible,
            width: "360px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.dialogData,
                "goodsShelvesGoodsDialogVisible",
                $event
              )
            },
          },
        },
        [
          _vm.dialogData.goodsShelvesGoodsDialogVisible
            ? _c("goodsShelvesWarehouse", {
                attrs: {
                  "store-id": _vm.storeId,
                  "inventory-data": _vm.inventoryData,
                },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "货品设置",
            visible: _vm.dialogData.goodsInventorySetDialogVisible,
            width: "360px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.dialogData,
                "goodsInventorySetDialogVisible",
                $event
              )
            },
          },
        },
        [
          _vm.dialogData.goodsInventorySetDialogVisible
            ? _c("goodsInventorySet", {
                attrs: {
                  "store-id": _vm.storeId,
                  "inventory-data": _vm.inventoryData,
                },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }