var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddInventoryIn" },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "货品SKU" } }, [
            _vm._v(_vm._s(_vm.goodsInfo.cbarcode)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "货品编号" } }, [
            _vm._v(_vm._s(_vm.goodsInfo.csku)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "货品类别" } }),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "货品名称" } }, [
            _vm._v(_vm._s(_vm.goodsInfo.cname)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "货品成本价" } }),
          _c("el-descriptions-item", { attrs: { label: "货品零售价" } }, [
            _vm._v(_vm._s(_vm.goodsInfo.nprice)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h500" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                data: _vm.tableData,
                "max-height": "450",
                "row-class-name": _vm.tableRowClassName,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "50" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  width: "50",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "单据类型", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("tableType")(scope.row.type))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "出入类型", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(_vm.formatPtype(scope.row)))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单据编号",
                  align: "center",
                  width: "180",
                  prop: "c_sys",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "hHP wHP flex marginLeft10",
                            staticStyle: { "white-space": "break-spaces" },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleShow(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.c_sys))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库位号",
                  align: "center",
                  prop: "z_inventory",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发生数量",
                  align: "center",
                  width: "80",
                  prop: "n_out",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "制单人", align: "center", prop: "s_name" },
              }),
              _c("el-table-column", {
                attrs: { label: "单据状态", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("orderAudit")(scope.row.p_audit))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "更新时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("time")(scope.row.t_time))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryParams.currentPage,
                  "page-size": _vm.queryParams.pageSize,
                  "page-sizes": _vm.pageSizes,
                  total: _vm.total,
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex alignItemsCenter",
          staticStyle: { "justify-content": "space-between", flex: "1" },
        },
        [
          _c("div"),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.handleExport } },
                [_vm._v("导出Excel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit(1)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "入库单查看",
            visible: _vm.inventoryInDetailDialogVisible,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.inventoryInDetailDialogVisible = $event
            },
          },
        },
        [
          _vm.inventoryInDetailDialogVisible
            ? _c("inventoryInDetail", {
                attrs: { "inventory-in": _vm.checkInventoryIn },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "出库单查看",
            visible: _vm.inventoryOutDetailDialogVisible,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.inventoryOutDetailDialogVisible = $event
            },
          },
        },
        [
          _vm.inventoryOutDetailDialogVisible
            ? _c("inventoryOutDetail", {
                attrs: { "inventory-in": _vm.checkInventoryIn },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }