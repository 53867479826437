var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeLocationList bcWhite box-shadow padding10" },
    [
      _c("div", { staticClass: "fs20 fWB borderBottom paddingBottom10" }, [
        _vm._v("门店列表"),
      ]),
      _c(
        "dc-search-panel",
        {
          attrs: { params: _vm.queryPara },
          on: { search: _vm.doSearch, reset: _vm.doSearch },
        },
        [
          _c(
            "el-input",
            {
              staticClass: "w250 input-with-select",
              attrs: {
                id: "searchInput",
                clearable: "",
                placeholder: "输入门店名称或地址",
              },
              model: {
                value: _vm.queryPara.cname,
                callback: function ($$v) {
                  _vm.$set(_vm.queryPara, "cname", $$v)
                },
                expression: "queryPara.cname",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.doSearch },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "dc-datatable",
        { ref: "list", attrs: { url: _vm.urlApi, params: _vm.queryPara } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "index",
              label: "序",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "left",
              "header-align": "center",
              label: "门店名称",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "paddingLeft10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "cornflowerblue pointer",
                          attrs: { slot: "reference" },
                          on: {
                            click: function ($event) {
                              return _vm.locationManage(scope.row.sid)
                            },
                          },
                          slot: "reference",
                        },
                        [_vm._v(" " + _vm._s(scope.row.c_name) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "fs12 lightgray",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.c_location) +
                              "（" +
                              _vm._s(scope.row.c_addr) +
                              "）"
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "类型", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.c_store_type === 1 ? "无人仓" : "无人柜"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "管理员", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: scope.row.c_manager_phone,
                          placement: "top",
                        },
                      },
                      [
                        _c("span", { staticClass: "cornflowerblue pointer" }, [
                          _vm._v(_vm._s(scope.row.c_manager_name)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "total_warehouse_count",
              label: "设计库位",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "no_inventory_warehouse_count",
              label: "可用库位",
              width: "100",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }