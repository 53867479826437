<template>
  <div class="container">
    <div class="container__header">
      <div class="indexTabs">
        <el-tabs v-model="tabs" class="marginLeft10" @tab-click="handleTabClick">
          <el-tab-pane v-for="tab in tapArray" :key="tab.component" :label="tab.menuName" :name="tab.component" />
        </el-tabs>
      </div>
    </div>
    <div v-if="pageType === 'storeModule'" class="container__top">
      <div class="left bcWhite box-shadow">
        <storeLeftTree @CheckMerchant="checkMerchant" @CheckBaseArea="CheckBaseArea" />
      </div>
      <div class="right bcWhite box-shadow">
        <StoreList v-if="tabs === 'storeList'" :merchant-list="merchantList" :base-area-list="baseAreaList" @StoreInfo="storeInfo" @AddAndSet="storeAddAndSet" />
        <storeLocationList v-if="tabs === 'storeLocation'" :merchant-list="merchantList" :base-area-list="baseAreaList" @StoreInfo="storeInfo" @LocationManage="locationManage" />
        <storeManage v-if="tabs === 'storeManage'" :merchant-list="merchantList" :base-area-list="baseAreaList" @StoreInfo="storeInfo" @StoreGoods="StoreGoods" />
        <storeDeviceList v-if="tabs === 'storeDevice'" :merchant-list="merchantList" :base-area-list="baseAreaList" @DeviceManage="deviceManage" @StoreInfo="storeInfo" />
      </div>
    </div>
    <div v-if="pageType === 'storePage'" class="container__body">
      <StoreAdd v-if="pageContent === 'StoreAdd'" :set-id="storeId" @navigateStoreList="navigateStoreList" />
      <StoreInfo v-if="pageContent === 'StoreInfo'" :info-id="storeId" @storeList="navigateStoreList" />
      <storeLocation v-if="pageContent === 'StoreLocation'" :store-id="storeId" @storeLocation="navigateStoreLocation" />
      <StoreDevice v-if="pageContent === 'StoreDevice'" :store-id="storeId" />
      <StoreGoods v-if="pageContent === 'StoreGoods'" :store-id="storeId" />
    </div>
  </div>
</template>

<script>
import storeLeftTree from "@/views/store/storeLeftTree.vue";
import StoreManage from "@/views/store/storeManage.vue";
import StoreInfo from "@/views/store/storeInfo.vue";
import StoreList from "./storeList.vue";
import storeLocationList from "./storeLocationList.vue";
import storeLocation from "@/views/store/storeLocation.vue";
import storeDeviceList from "./storeDeviceList.vue";
import store from "../../store";
import StoreAdd from "@/views/store/storeAdd.vue";
import StoreDevice from "@/views/store/storeDevice.vue";
import StoreGoods from "@/views/store/storeGoods.vue";

export default {
  name: "StoreIndex",
  components: {
    storeLeftTree,
    StoreList,
    StoreManage,
    storeDeviceList,
    storeLocationList,
    StoreAdd,
    StoreInfo,
    storeLocation,
    StoreDevice,
    StoreGoods,
  },
  data() {
    return {
      tabs: "",
      tapArray: "",
      storeId: "",
      pageType: "storeModule",
      pageContent: '',
      merchantList: [],
      baseAreaList: [],
    };
  },
  created() {
    const nav = JSON.parse(JSON.stringify(store.getters.navs));
    const result = nav.find((item) => item.component === "storeIndex");
    this.tapArray = result.children;
    this.tabs = this.tapArray[0].component;
  },
  methods: {
    checkMerchant(val) {
      this.merchantList = val;
    },
    CheckBaseArea(val) {
      this.baseAreaList = val;
    },
    handleTabClick() {
      this.pageType = "storeModule";
    },
    /**
     * 库位信息
     */
    deviceManage(id) {
      this.storeId = id;
      this.pageType = "storePage";
      this.pageContent = "StoreDevice";
    },
    /**
     * 库位信息
     */
    locationManage(id) {
      this.storeId = id;
      this.pageType = "storePage";
      this.pageContent = "StoreLocation";
    },
    /**
     * 门店信息
     */
    storeInfo(id) {
      this.storeId = id;
      this.pageType = "storePage";
      this.pageContent = "StoreInfo";
    },
    /**
     * 门店货品信息
     */
    StoreGoods(id) {
      this.storeId = id;
      this.pageType = "storePage";
      this.pageContent = "StoreGoods";
    },
    /**
     * 门店添加与修改
     */
    storeAddAndSet(id) {
      this.pageType = "storePage";
      if (id) {
        this.storeId = id;
      }
      this.pageContent = "StoreAdd";
    },
    navigateStoreList() {
      this.pageType = "storeModule";
      this.tabs = "storeList";
      this.storeId = "";
    },
    navigateStoreLocation() {
      this.pageType = "storeModule";
      this.tabs = "storeLocation";
      this.storeId = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px;
  flex-direction: column;
  overflow: hidden;

  &__header {
    height: 70px;
    margin: 0 3px;
    margin-bottom: 10px;
  }

  &__top {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    overflow: hidden;

    .left {
      padding-bottom: 10px;
      width: 15%;
      margin: 3px;
      margin-right: 15px;
    }

    .right {
      flex: 1;
      margin: 3px;
    }
  }

  &__body {
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
}
</style>
