var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InventorySheetInfo" },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "盘点单号" } }, [
            _vm._v(_vm._s(_vm.sheetInfo.csys)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "所在门店" } }, [
            _vm._v(_vm._s(_vm.sheetInfo.cstore)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "盘点方式" } }, [
            _vm._v(_vm._s(_vm.sheetInfo.pway === 1 ? "自由盘点" : "指定盘点")),
          ]),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "盘点类型" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.sheetInfo.type === 1 ? "日常盘点" : "临时盘点") +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "盘点周期" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.sheetInfo.pcycle === 1
                    ? "按年盘点"
                    : _vm.sheetInfo.pcycle === 2
                    ? "按季盘点"
                    : _vm.sheetInfo.pcycle === 3
                    ? "按月盘点"
                    : ""
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "盘点人" } }, [
            _c("div", [_vm._v("123")]),
          ]),
        ],
        1
      ),
      _c("el-divider"),
      _vm.sheetInfo.pstate === 4 || _vm.sheetInfo.pstate === 5
        ? _c(
            "div",
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "审核意见" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", clearable: "" },
                        model: {
                          value: _vm.auditRemark,
                          callback: function ($$v) {
                            _vm.auditRemark = $$v
                          },
                          expression: "auditRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticStyle: { width: "25%" } },
          [
            _c(
              "el-descriptions",
              { attrs: { column: 1 } },
              [
                _c("el-descriptions-item", { attrs: { label: "单据状态" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("sheetState")(_vm.sheetInfo.pstate)) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { width: "75%" } },
          [
            _c(
              "el-descriptions",
              { attrs: { column: 1 } },
              [
                _c("el-descriptions-item", { attrs: { label: "单据备注" } }, [
                  _vm._v(" " + _vm._s(_vm.sheetInfo.cremark) + " "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("el-divider"),
      _vm.sheetInfo.pstate === 6 || _vm.sheetInfo.pstate === 7
        ? _c(
            "div",
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "审核意见" } }, [
                    _vm._v(" " + _vm._s(_vm.sheetInfo.cauditRemark) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "h450" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: { data: _vm.recordsList, height: "450" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  width: "50",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "SKU",
                  align: "center",
                  width: "70",
                  prop: "c_barcode",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "图片", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.c_master_url != null
                          ? _c("el-image", {
                              staticClass: "w50 h50",
                              attrs: {
                                "preview-src-list": [scope.row.c_master_url],
                                src: scope.row.c_master_url,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "货品编码",
                  align: "center",
                  width: "130",
                  prop: "c_sku",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "货品名称", align: "center", prop: "s_goods" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单位",
                  align: "center",
                  width: "50",
                  prop: "s_unit",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库位号",
                  align: "center",
                  width: "70",
                  prop: "c_num",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库存数",
                  align: "center",
                  width: "70",
                  prop: "n_old_total",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "盘点数",
                  align: "center",
                  width: "70",
                  prop: "n_update_total",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "差额", align: "center", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              Number.isInteger(
                                parseFloat(scope.row.n_old_total) -
                                  parseFloat(scope.row.n_update_total)
                              )
                                ? parseFloat(scope.row.n_old_total) -
                                    parseFloat(scope.row.n_update_total)
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "盘点时间", align: "center", width: "100" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex alignItemsCenter marginTop10",
          staticStyle: { "justify-content": "space-between", flex: "1" },
        },
        [
          _c(
            "div",
            [
              _vm.sheetInfo.pstate === 3 || _vm.sheetInfo.pstate === 4
                ? _c(
                    "el-button",
                    { attrs: { plain: "" }, on: { click: _vm.copyLink } },
                    [_vm._v("复制链接")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.sheetInfo.pstate === 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", plain: "" },
                      on: { click: _vm.modifyInventorySheet },
                    },
                    [_vm._v("撤回")]
                  )
                : _vm._e(),
              _vm.sheetInfo.pstate === 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.startInventory },
                    },
                    [_vm._v("盘点")]
                  )
                : _vm._e(),
              _vm.sheetInfo.pstate === 3
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit(4)
                        },
                      },
                    },
                    [_vm._v("提交审核")]
                  )
                : _vm._e(),
              _vm.sheetInfo.pstate === 5
                ? _c(
                    "el-button",
                    { attrs: { plain: "" }, on: { click: _vm.approved } },
                    [_vm._v("审核通过")]
                  )
                : _vm._e(),
              _vm.sheetInfo.pstate === 5
                ? _c(
                    "el-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit(7)
                        },
                      },
                    },
                    [_vm._v("审核拒绝")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }