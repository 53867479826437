var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "elDialog",
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.addInventoryInDialogVisible,
        width: "800px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.addInventoryInDialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "AddInventoryIn" },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "单号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "207px" },
                    attrs: { placeholder: "请输入内容", clearable: "" },
                    model: {
                      value: _vm.cSys,
                      callback: function ($$v) {
                        _vm.cSys = $$v
                      },
                      expression: "cSys",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "入库类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.inventoryInType,
                        callback: function ($$v) {
                          _vm.inventoryInType = $$v
                        },
                        expression: "inventoryInType",
                      },
                    },
                    _vm._l(_vm.inventoryInTypeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "原始单号" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请输入内容", clearable: "" },
                      model: {
                        value: _vm.originalOrder,
                        callback: function ($$v) {
                          _vm.originalOrder = $$v
                        },
                        expression: "originalOrder",
                      },
                    },
                    [
                      _vm.inventoryInType === "40"
                        ? _c("i", {
                            staticClass:
                              "el-input__icon el-icon-search pointer",
                            attrs: { slot: "prefix" },
                            on: { click: _vm.selectAllotOrderOutNumber },
                            slot: "prefix",
                          })
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 2 } },
            [
              _c("el-descriptions-item", { attrs: { label: "入库门店" } }, [
                _vm._v(_vm._s(_vm.storeInfo.cname)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "入库时间" } }, [
                _vm._v(_vm._s(_vm.deliveryNoteTime)),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _vm.inventoryInType === "40"
            ? _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "wFP" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1 } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "调出门店" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.checkAllotOrder.sstore) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wFP AddInventoryIn__hideColon" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1 } },
                      [
                        _c("el-descriptions-item", [
                          _c("div", { staticClass: "wFP" }, [
                            _vm._v(
                              "调拔类型: " +
                                _vm._s(
                                  _vm._f("allotType")(
                                    _vm.checkAllotOrder.pallotType
                                  )
                                )
                            ),
                          ]),
                          _c("div", { staticClass: "wFP" }, [
                            _vm._v(
                              "调拔价格: " +
                                _vm._s(
                                  _vm._f("allotPrice")(
                                    _vm.checkAllotOrder.pallotPrice
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.inventoryInType === "40" ? _c("el-divider") : _vm._e(),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "单据备注" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入内容", clearable: "" },
                        model: {
                          value: _vm.remarks,
                          callback: function ($$v) {
                            _vm.remarks = $$v
                          },
                          expression: "remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.inventoryInType !== "40"
                ? _c(
                    "div",
                    {
                      staticClass: "w200 flex",
                      staticStyle: { "justify-content": "flex-end" },
                    },
                    [
                      !_vm.repInventoryOrderInId
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                plain: "",
                              },
                              on: { click: _vm.ImpInventoryOrderOut },
                            },
                            [_vm._v("导入")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini", plain: "" },
                          on: { click: _vm.showOrderGoodsAdd },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.repInventoryOrderInId
                                ? "重新选取"
                                : "添加货品"
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.removeSelectedRows },
                        },
                        [_vm._v("移除")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 1, colon: false } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "搜索" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "SKU/条码/货品名称" },
                    on: { input: _vm.handleInput },
                    model: {
                      value: _vm.selectSearch,
                      callback: function ($$v) {
                        _vm.selectSearch = $$v
                      },
                      expression: "selectSearch",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "h450" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: {
                    data: _vm.tableData,
                    "max-height": "400",
                    "header-row-class-name": _vm.isRed ? "red" : "",
                    "row-class-name": _vm.isRed ? "grey" : "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      width: "50",
                      type: "index",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "SKU",
                      align: "center",
                      width: "70",
                      prop: "c_barcode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图片", align: "center", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.c_master_url != null
                              ? _c("el-image", {
                                  staticClass: "w50 h50",
                                  attrs: {
                                    "preview-src-list": [
                                      scope.row.c_master_url,
                                    ],
                                    src: scope.row.c_master_url,
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "货品编码",
                      align: "center",
                      width: "130",
                      prop: "c_sku",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "货品名称",
                      align: "center",
                      prop: "c_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "单位", align: "center", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "库位", align: "center", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.c_num === ""
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("暂无库位"),
                                ])
                              : _c("span", [_vm._v(_vm._s(scope.row.c_num))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "数量",
                      align: "center",
                      width: "50",
                      prop: "num",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isRed ? -scope.row.num : scope.row.num
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.inventoryInType != "40"
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "address",
                          label: "操作",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdate(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1482024205
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex alignItemsCenter",
              staticStyle: { "justify-content": "space-between", flex: "1" },
            },
            [
              _c("div", [
                _vm._v(" 共计 "),
                _c("span", { staticClass: "blue" }, [
                  _vm._v(_vm._s(_vm.tableData.length) + "种"),
                ]),
                _vm._v(" 货品 "),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit(0)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit(1)
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("implInventoryOut", {
            ref: "implInventoryOut",
            attrs: { title: "入库导入" },
            on: { handleClose: _vm.handleClose },
          }),
          _c("inventOrderGoodsAdd", {
            ref: "orderGoodsAdd",
            attrs: {
              goods: [],
              "select-store": { rstore: _vm.storeInfo.sid },
              "max-num": false,
            },
            on: { OrderGoodsAdd: _vm.AddGoods },
          }),
        ],
        1
      ),
      _vm.selectAllotOrderOutNumberDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "elDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "选择调拔出库单号",
                visible: _vm.selectAllotOrderOutNumberDialogVisible,
                width: "360px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.selectAllotOrderOutNumberDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "allotOrderOut" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "w300 marginLeftAuto",
                      attrs: { placeholder: "查询关键字" },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search pointer",
                        attrs: { slot: "suffix" },
                        slot: "suffix",
                      }),
                    ]
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "allotOrderOutTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.allotOrderOutTableData,
                        "tooltip-effect": "dark",
                      },
                      on: {
                        "selection-change": _vm.handleAllotOrderOutChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "盘单号",
                          align: "center",
                          prop: "csys",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticClass: "marginTop20 marginRight20",
                      attrs: { type: "flex", justify: "end" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.updateDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "elDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "入库信息修改",
                visible: _vm.updateDialogVisible,
                width: "360px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.updateDialogVisible = $event
                },
              },
            },
            [
              _c("el-image", {
                staticStyle: {
                  width: "200px",
                  height: "200px",
                  "margin-left": "55px",
                },
                attrs: {
                  src: _vm.updateData.c_master_url,
                  fit: "fit",
                  "preview-src-list": [_vm.updateData.c_master_url],
                },
              }),
              _c(
                "el-form",
                { ref: "form", attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("span", { staticStyle: { color: "#a8abb2" } }, [
                          _vm._v("货品名称"),
                        ]),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "-webkit-box",
                            "-webkit-box-orient": "vertical",
                            "line-clamp": "2",
                            "-webkit-line-clamp": "2",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            "line-height": "20px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.updateData.c_name))]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("span", { staticStyle: { color: "#a8abb2" } }, [
                          _vm._v("货品SKU"),
                        ]),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "-webkit-box",
                            "-webkit-box-orient": "vertical",
                            "line-clamp": "2",
                            "-webkit-line-clamp": "2",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                          },
                        },
                        [_vm._v(_vm._s(_vm.updateData.c_barcode))]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("span", { staticStyle: { color: "#a8abb2" } }, [
                          _vm._v("货品编码"),
                        ]),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "-webkit-box",
                            "-webkit-box-orient": "vertical",
                            "line-clamp": "2",
                            "-webkit-line-clamp": "2",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                          },
                        },
                        [_vm._v(_vm._s(_vm.updateData.c_sku))]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库位名称", prop: "warehouse" } },
                    [
                      _vm.updateData.warehouseId === undefined ||
                      _vm.updateData.warehouseId === "" ||
                      _vm.warehouseIdList.includes(_vm.updateData.warehouseId)
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "210px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择库位",
                              },
                              model: {
                                value: _vm.updateData.warehouseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.updateData, "warehouseId", $$v)
                                },
                                expression: "updateData.warehouseId",
                              },
                            },
                            _vm._l(_vm.warehouseList, function (item) {
                              return _c("el-option", {
                                key: item.sid,
                                attrs: { label: item.c_num, value: item.sid },
                              })
                            }),
                            1
                          )
                        : _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.updateData.c_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.updateData, "c_num", $$v)
                              },
                              expression: "updateData.c_num",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "数量" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "210px" },
                        attrs: { placeholder: "请输入数量" },
                        model: {
                          value: _vm.updateData.num,
                          callback: function ($$v) {
                            _vm.$set(_vm.updateData, "num", $$v)
                          },
                          expression: "updateData.num",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "marginTop20 marginRight20",
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleDoUpdate },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }