var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "120px",
            model: _vm.form,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "库位编码", prop: "cnum" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.cnum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cnum", $$v)
                  },
                  expression: "form.cnum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "库位名称", prop: "cname" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.cname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cname", $$v)
                  },
                  expression: "form.cname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "库位容量", prop: "ntotal" } },
            [
              _c("el-input", {
                on: { input: _vm.handleInput },
                model: {
                  value: _vm.form.ntotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ntotal", $$v)
                  },
                  expression: "form.ntotal",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序号", prop: "nindex" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.nindex,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nindex", $$v)
                  },
                  expression: "form.nindex",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "库位描述" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.cremark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cremark", $$v)
                  },
                  expression: "form.cremark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "启用状态" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.bvalid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bvalid", $$v)
                  },
                  expression: "form.bvalid",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }