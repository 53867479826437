var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "video_main" },
      [
        _c("video-player", {
          ref: "videoPlayer",
          staticClass: "video-player",
          attrs: {
            id: "videoDiv",
            playsinline: true,
            options: _vm.playerOptions,
          },
          on: {
            play: function ($event) {
              return _vm.onPlayerPlay($event)
            },
            pause: function ($event) {
              return _vm.onPlayerPause($event)
            },
            ended: function ($event) {
              return _vm.onPlayerEnded($event)
            },
            waiting: function ($event) {
              return _vm.onPlayerWaiting($event)
            },
            playing: function ($event) {
              return _vm.onPlayerPlaying($event)
            },
            loadeddata: function ($event) {
              return _vm.onPlayerLoadeddata($event)
            },
            timeupdate: function ($event) {
              return _vm.onPlayerTimeupdate($event)
            },
            canplay: function ($event) {
              return _vm.onPlayerCanplay($event)
            },
            canplaythrough: function ($event) {
              return _vm.onPlayerCanplaythrough($event)
            },
            statechanged: function ($event) {
              return _vm.playerStateChanged($event)
            },
            ready: _vm.playerReadied,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }