<template>
  <!-- 入库详细-->
  <div>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="入库制单" :visible.sync="dialogVisible" width="800px" append-to-body>
      <el-input v-model="textarea" type="textarea" :rows="2" placeholder="请输入撤回原因"> </el-input>
      <el-button type="warning" @click="dialogVisible = false">确认</el-button>
      <el-button type="primary" @click="alert('开发中')">取消</el-button>
    </el-dialog>
    <div class="InventoryInInfo">
      <el-descriptions :column="3">
        <el-descriptions-item label="系统单号">{{ info.csys }}</el-descriptions-item>
        <el-descriptions-item label="入库类型">{{ info.ptype | inType }}</el-descriptions-item>
        <el-descriptions-item label="原始单号">{{ info.ccode }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="2">
        <el-descriptions-item label="入库门店">{{ info.sstore }}</el-descriptions-item>
        <el-descriptions-item label="入库时间">{{ info.tcreate | time }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="3">
        <el-descriptions-item label="制单人">{{ info.sin }}</el-descriptions-item>
        <el-descriptions-item label="制单时间">{{ info.tcreate | time }}</el-descriptions-item>
        <el-descriptions-item label="审核状态">
          <span v-if="info.paudit === 3" style="color: green;">{{ info.paudit | auditState }}</span>
          <span v-else-if="info.paudit === 4" style="color: red;">{{ info.paudit | auditState }}</span>
          <span v-else>{{ info.paudit | auditState }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="3">
        <el-descriptions-item label="审单人">{{ info.saudit }}</el-descriptions-item>
        <el-descriptions-item label="审单时间">{{ info.taudit | time }}</el-descriptions-item>
        <el-descriptions-item label="审核说明">{{ info.cauditRemark }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="1">
        <el-descriptions-item label="单据备注">{{ info.cremark }}</el-descriptions-item>
      </el-descriptions>
      <div class="h450">
        <el-table ref="multipleTable" :data="tableData" height="450">
          <el-table-column label="序号" align="center" width="50" type="index" />
          <el-table-column label="SKU" align="center" width="70" prop="c_barcode" />
          <el-table-column label="图片" align="center" width="70">
            <template #default="scope">
              <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="货品编码" align="center" width="130" prop="c_sku" />
          <el-table-column label="货品名称" align="center" prop="c_name" />
          <el-table-column label="单位" align="center" width="50" prop="s_unit" />
          <el-table-column label="库位" align="center" width="70" prop="z_inventory" />
          <el-table-column label="数量" align="center" width="50" prop="n_out" />
        </el-table>
        <!-- <div style="float: right;margin-top: 10px;">
          <el-button  type="danger" plain @click="inventoryInCh">冲红</el-button>
    </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import OrderGoodsAdd from "@/views/order/orderGoodsAdd.vue";
import lodash from "lodash";

export default {
  name: "InventoryInInfo",
  filters: {
    time(time) {
      if (time !== "" && time !== null && time !== undefined) {
        return moment(time).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
    auditState(value) {
      // 审核状态 0:未审核 1:审核通过 2:审核失败
      if (value === 0) {
        return "制单中";
      } else if (value === 1) {
        return "待审核";
      } else if (value === 2) {
        return "审核中";
      } else if (value === 3) {
        return "已通过";
      } else if (value === 4) {
        return "被拒绝";
      } else if (value === 5) {
        return "部分冲红";
      } else if (value === 6) {
        return "已调拨";
      } else if (value === 7) {
        return "已冲红";
      }  else {
        return "";
      }
    },
    inType(type) {
      // 00:其他入库,10:期初入库,20:补货入库,30:退货入库,40:调拔入库,50:盘盈入库
      if (type === "00") {
        return "其他入库";
      } else if (type === "10") {
        return "期初入库";
      } else if (type === "20") {
        return "补货入库";
      } else if (type === "30") {
        return "退货入库";
      } else if (type === "40") {
        return "调拔入库";
      } else if (type === "50") {
        return "盘盈入库";
      } else {
        return "";
      }
    },
  },
  props: {
    inventoryIn: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "1", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      info: {},
      tableData: [],
      dialogVisible: false,
      textarea: "",
    };
  },
  created() {
    this.info = this.inventoryIn;
    this.selectByInId(this.info.sid);
  },
  methods: {
    selectByInId(id) {
      let url = `${this.baseUrls.repInventoryOrderDetailIn}/selectByInId`;
      this.topsAjax.get(url, { inId: id }).then((res) => {
        this.tableData = res;
      });
    },
    inventoryInCh() {
      let title = `确定要冲红该出库单？`;
      this.$IConfirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let title = `确定要冲红该出库单？`;
        this.$IConfirm(title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let url = `${this.baseUrls.repInventoryOrderIn}/inventoryOrderInRed`;
        let params = { inventoryOrderInId: this.info.sid };
          this.topsAjax.post(url, params).then((res) => {
            this.$message({
              showClose: true,
              message: "操作成功",
              type: "success",
            });
            this.dialogVisible = false
          })
        });
      });
    },
  },
};
</script>
<style lang="scss">
.InventoryInInfo {
  height: 680px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .el-divider--horizontal {
    margin: 8px 0;
  }
}
</style>
