<template>
  <!-- 入库制单-->
  <el-dialog class="elDialog" :close-on-click-modal="false" :title="title" :visible.sync="addInventoryInDialogVisible" width="800px" append-to-body>
    <div class="AddInventoryOut">
      <el-descriptions :column="3">
        <el-descriptions-item label="单号">
          <el-input v-model="cSys" placeholder="请输入内容" clearable style="width: 207px" />
        </el-descriptions-item>
        <el-descriptions-item label="出库类型">
          <el-select v-model="inventoryInType" placeholder="请选择">
            <el-option v-for="item in inventoryInTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="原始单号">
          <el-input v-model="originalOrder" placeholder="请输入内容" clearable />
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="2">
        <el-descriptions-item label="出库门店">{{ storeInfo.cname }}</el-descriptions-item>
        <el-descriptions-item label="出库时间">{{ deliveryNoteTime }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div v-if="inventoryInType === '20'" class="flex">
        <div class="wFP">
          <el-descriptions :column="1">
            <el-descriptions-item label="调入门店">
              <el-select v-model="allotStoreInfo.cname" class="w300" filterable placeholder="请选择" :filter-method="selectByStoreName" @change="checkStore">
                <el-option v-for="item in allotStoreOptions" :key="item.sid" :label="item.cname" :value="item" />
              </el-select>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="wFP AddInventoryIn__hideColon">
          <el-descriptions :column="1">
            <el-descriptions-item>
              <div class="wFP flex">
                <div class="w100">调拔类型:</div>
                <el-select v-model="allotType" placeholder="请选择调拔类型">
                  <el-option v-for="item in allotTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="wFP flex">
                <div class="w100">调拔价格:</div>
                <el-select v-model="allotPrice" placeholder="请选择调拔价格">
                  <el-option v-for="item in allotPriceOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <el-divider v-if="inventoryInType === '20'"></el-divider>
      <div class="flex">
        <el-descriptions :column="1">
          <el-descriptions-item label="单据备注">
            <el-input v-model="remarks" placeholder="请输入内容" style="width: 300px" clearable />
          </el-descriptions-item>
        </el-descriptions>
        <div class="w200 flex" style="justify-content: flex-end">
          <el-button v-if="!repInventoryOrderInId" type="primary" size="mini" plain @click="ImpInventoryOrderOut">导入</el-button>
          <el-button type="primary" size="mini" plain @click="showOrderGoodsAdd">{{ repInventoryOrderInId ? "重新选取" : "添加货品" }}</el-button>
          <el-button size="mini" @click="removeSelectedRows">移除</el-button>
        </div>
      </div>
      <el-divider></el-divider>
      <el-descriptions :column="1" :colon="false">
        <el-descriptions-item label="搜索">
          <el-input style="width: 210px;" placeholder="SKU/条码/货品名称" v-model="selectSearch" @input="handleInput"/>
        </el-descriptions-item>
      </el-descriptions>
      <div class="h450">
        <el-table ref="multipleTable" :data="tableData" height="400" :header-row-class-name="isRed ? 'red' : ''" :row-class-name="isRed ? 'grey' : ''" @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center" width="50" />
          <el-table-column label="序号" align="center" width="50" type="index" />
          <el-table-column label="SKU" align="center" width="70" prop="c_barcode" />
          <el-table-column label="图片" align="center" width="70">
            <template #default="scope">
              <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="货品编码" align="center" width="130" prop="c_sku" />
          <el-table-column label="货品名称" align="center" prop="c_name" />
          <el-table-column label="单位" align="center" width="50" />
          <el-table-column label="库位" align="center" width="70" prop="c_num" />
          <el-table-column label="数量" align="center" width="50" prop="num">
            <template #default="scope">
              {{ isRed ? -scope.row.num : scope.row.num }}
            </template>
          </el-table-column>
          <el-table-column label="货品批次" align="center" width="60" prop="todo" />
          <el-table-column v-if="isRed" align="center" prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="showEditNum(scope.row)">修改</el-button>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
        <div>
          共计 <span class="blue">{{ tableData.length }}种</span> 货品
        </div>
        <div>
          <el-button plain @click="onSubmit(0)">保存</el-button>
          <el-button type="primary" plain @click="onSubmit(1)">提交</el-button>
        </div>
      </div>
      <implInventoryOut ref="implInventoryOut" :title="'出库导入'" @handleClose="handleClose" />
      <inventOrderGoodsAdd ref="orderGoodsAdd" :goods="[]" :select-store="{ rstore: storeInfo.sid }" :max-num="true" @OrderGoodsAdd="AddGoods" />
      <el-dialog class="elDialog" :close-on-click-modal="false" title="修改数量" :visible.sync="dialogEditVisible" width="250px" append-to-body>
        <el-input-number style="width: 210px;" placeholder="数量" v-model="editNum" type="number"/><br><br>
        <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
          <div>
          </div>
          <div class="flex">
            <el-button @click="handleEditNum">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>
<script>
import moment from "moment";
import inventOrderGoodsAdd from "@/views/store/inventoryOrder/inventOrderGoodsAdd.vue";
import implInventoryOut from "@/views/store/inventoryOrder/excelDialog/implInventoryInOut.vue";


export default {
  name: "AddInventoryOut",
  components: { inventOrderGoodsAdd, implInventoryOut },
  data() {
    return {
      title: "出库制单",
      storeId: null,
      addInventoryInDialogVisible: false,
      dialogVisible: false,
      dialogEditVisible: false,
      isRed: false,
      // 门店信息
      storeInfo: {
        cname: "",
        sid: "",
      },
      // 入库单类型
      inventoryInType: "",
      inventoryInTypeOptions: [
        { value: "10", label: "订单出库" },
        { value: "20", label: "调拨出库" },
        { value: "30", label: "报损出库" },
        { value: "40", label: "盘亏出库" },
        { value: "50", label: "缺陷出库" },
        { value: "60", label: "线下出库" },
        { value: "00", label: "其他出库" },
      ],
      // 原始单号
      originalOrder: "",
      //入库时间
      deliveryNoteTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      //单据备注
      remarks: "",
      tableData: [],
      selectedRows: [],
      cSys: "",
      repInventoryOrderInId: null,
      repInventoryOrderIn: null,
      //调拨用字段 ---------------------------------------------------------------------------------------
      allotStoreOptions: [],
      allotStoreInfo: {},
      allotTypeOptions: [
        { value: "1", label: "平价调拔" },
        { value: "2", label: "溢价调拔" },
        { value: "3", label: "折价调拔" },
      ],
      allotType: "",
      allotPriceOptions: [
        { value: "1", label: "成本价" },
        { value: "2", label: "特供价" },
        { value: "3", label: "核心价" },
        { value: "4", label: "代理价" },
        { value: "5", label: "批发价" },
      ],
      allotPrice: "",
      selectSearch: "",
      tableRow: {},
      editNum: 0
    };
  },
  methods: {
    showEditNum(row) {
      this.tableRow = row
      this.editNum = -row.num
      this.dialogEditVisible = true
    },
    handleEditNum() {
      this.tableRow.num = -this.editNum
      this.dialogEditVisible = false
    },
    handleInput() {
      let nowIndex = 0
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].c_barcode.indexOf(this.selectSearch) !== -1 || this.tableData[i].c_sku.indexOf(this.selectSearch) !== -1 || this.tableData[i].c_name.indexOf(this.selectSearch) !== -1) {
          nowIndex = i;
          break
        }
      }
      const TableRows = this.$refs.multipleTable.bodyWrapper.querySelectorAll('.el-table__body tbody .el-table__row')
                let scrollTop = 0
                for (let i = 0; i < TableRows.length; i++) {
                    if (i >= nowIndex) {
                        break
                    }
                    scrollTop += TableRows [i].offsetHeight
                }
                this.$refs.multipleTable.bodyWrapper.scrollTop = scrollTop
    },
    // 门店选择
    selectByStoreName(value) {
      if (value.length > 0) {
        let url = `${this.baseUrls.store}/queryList`;
        let stoStore = {
          cName: value,
        };
        this.topsAjax.get(url, stoStore).then((res) => {
          this.allotStoreOptions = res.filter((item) => item.sid !== this.storeInfo.sid);
        });
      } else {
        this.allotStoreOptions = [];
      }
    },
    checkStore(value) {
      this.allotStoreInfo = value;
    },
    ImpInventoryOrderOut() {
      this.$refs.implInventoryOut.showDialog(this.storeId);
    },
    // --------------------------------------------------------------------------------------------------------------------------------
    showOrderGoodsAdd() {
      let arr = [];
      for (let i = 0; i < this.tableData.length; i++) {
        arr.push({ ...this.tableData[i] });
      }
      this.$refs.orderGoodsAdd.setTableData(arr);
    },
    showDialog(storeId, inType, repInventoryOrderInId, isRed) {
      this.tableData = [];
      this.storeId = storeId;
      this.inventoryInType = inType;
      if (inType === "20") this.title = "出库单（调拔）制单";
      this.addInventoryInDialogVisible = true;
      this.getStoreInfo(this.storeId);
      this.isRed = isRed;

      this.repInventoryOrderInId = repInventoryOrderInId;
      if (repInventoryOrderInId) {
        let url = `${this.baseUrls.repInventoryOrderOut}/${repInventoryOrderInId}`;
        this.topsAjax.get(url).then((response) => {
          this.repInventoryOrderIn = response;
          this.cSys = this.repInventoryOrderIn.csys;
          this.originalOrder = this.repInventoryOrderIn.ccode;
          this.remarks = this.repInventoryOrderIn.cremark;
          if (inType === "20") {
            this.allotStoreInfo.sid = response.rallotStore;
            this.allotStoreInfo.cname = response.sallotStore;
            this.allotType = response.pallotType.toString();
            this.allotPrice = response.pallotPrice.toString();
          }
        });
        let goodsUrl = `${this.baseUrls.repInventoryOrderOut}/queryPageStoreGoods`;
        this.topsAjax.get(goodsUrl, { repInventoryOrderOutId: repInventoryOrderInId }).then((response) => {
          this.tableData = response;
        });
      } else {
        this.originalOrder = "";
        this.remarks = "";
        let url = `${this.baseUrls.repInventoryOrderOut}/systemNum`;
        this.topsAjax
          .post(url, {
            rstore: storeId,
            ptype: inType,
          })
          .then((response) => {
            if (response) {
              this.cSys = response;
            }
          });
      }
    },
    /**
     * 确定
     */
    onSubmit(pAudit) {
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.isRed) {
          if (this.tableData[i].num > 0) {
            this.$message.error("SKU[" + this.tableData[i].c_barcode + "]数量必须小于0");
            return;
          }
        } else {
          if (this.tableData[i].num < 0) {
            this.$message.error("SKU[" + this.tableData[i].c_barcode + "]数量必须大于0");
            return;
          }
        }
      }
      if (this.inventoryInType === "" || this.inventoryInType === null) {
        this.$message.error("请选择出库单类型!");
        return;
      } else if (!(this.tableData.length > 0)) {
        this.$message.error("请选择出库货品!");
        return;
      } else {
        let url;
        if (this.repInventoryOrderInId) {
          url = `${this.baseUrls.repInventoryOrderOut}/updateDetail`;
        } else {
          url = `${this.baseUrls.repInventoryOrderOut}/add`;
        }
        let parameter = {
          paudit: pAudit,
          repInventoryOrderOutId: this.repInventoryOrderInId,
          repInventoryOrderOut: {
            rstore: this.storeInfo.sid,
            sstore: this.storeInfo.cname,
            ccode: this.originalOrder,
            ptype: this.inventoryInType,
            cremark: this.remarks,
            paudit: pAudit,
            tin: moment(this.deliveryNoteTime).format("YYYY-MM-DDTHH:mm:ss"),
          },
          goodsList: this.tableData,
        };
        if (this.inventoryInType === "20") {
          if (this.allotStoreInfo.sid === "" || this.allotStoreInfo.sid === null || this.allotStoreInfo.sid === undefined) {
            this.$message.error("请选择调拨门店!");
            return;
          }
          if (this.allotType === "" || this.allotType === null || this.allotType === undefined) {
            this.$message.error("请选择调拨类型!");
            return;
          }
          if (this.allotPrice === "" || this.allotPrice === null || this.allotPrice === undefined) {
            this.$message.error("请选择调拨价格!");
            return;
          }
          parameter.repInventoryOrderOut.rallotStore = this.allotStoreInfo.sid;
          parameter.repInventoryOrderOut.sallotStore = this.allotStoreInfo.cname;
          parameter.repInventoryOrderOut.pallotType = this.allotType;
          parameter.repInventoryOrderOut.pallotPrice = this.allotPrice;
        }
        this.topsAjax.post(url, parameter).then((response) => {
          if (response) {
            this.$message({
              showClose: true,
              message: "新增成功",
              type: "success",
            });
            this.addInventoryInDialogVisible = false;
            this.$emit("handleClose");
          }
        });
      }
    },
    /**
     * 取消
     */
    cancel() {
      this.$emit("handleClose");
    },
    /**
     * 移除
     */
    removeSelectedRows() {
      if (this.selectedRows.length == 0) {
        this.$message({
          message: "请勾选需要移除的记录",
          type: "info",
        });
        return;
      }
      this.tableData = this.tableData.filter((row) => !this.selectedRows.includes(row));
      //this.tableData = [];
      // this.$refs.multipleTable.clearSelection();
    },
    AddGoods(goodsList) {
      this.dialogVisible = false;
      if (goodsList.length > 0) this.tableData = goodsList;
    },
    handleSelectionChange(selection) {
      this.selectedRows = selection;
    },
    handleClose(goodsList) {
      this.tableData = goodsList;
    },
    getStoreInfo(id) {
      let url = `${this.baseUrls.store}/get/` + id;
      this.topsAjax.get(url).then((res) => {
        this.storeInfo = res;
      });
    },
  },
};
</script>

<style lang="scss">
.AddInventoryOut {
  width: 100%;
  display: flex;
  flex-direction: column;

  .el-divider--horizontal {
    margin: 8px 0;
  }

  .el-input--medium .el-input__inner {
    height: 20px;
    line-height: 20px;
  }

  .el-input__inner {
    border: 0px;
  }

  &__hideColon {
    .el-descriptions-item__label:not(.is-bordered-label) {
      display: none;
    }
  }

  .el-input__icon {
    display: flex !important;
    align-items: center !important;
  }

  //隐藏图标
  //.el-input__icon {
  //  display: none;
  //}
}
</style>
