<template>
  <!-- 库位管理 -->
  <div class="storeLocationList bcWhite box-shadow padding10">
    <div class="fs20 fWB borderBottom paddingBottom10">门店列表</div>
    <dc-search-panel :params="queryPara" @search="doSearch" @reset="doSearch">
      <el-input id="searchInput" v-model="queryPara.cname" clearable placeholder="输入门店名称或地址" class="w250 input-with-select">
        <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
      </el-input>
    </dc-search-panel>
    <dc-datatable ref="list" :url="urlApi" :params="queryPara">
      <el-table-column align="center" type="index" label="序" width="100" />
      <el-table-column align="left" header-align="center" label="门店名称">
        <template slot-scope="scope">
          <div class="paddingLeft10">
            <div slot="reference" class="cornflowerblue pointer" @click="locationManage(scope.row.sid)">
              {{ scope.row.c_name }}
            </div>
            <div slot="reference" class="fs12 lightgray">{{ scope.row.c_location }}（{{ scope.row.c_addr }}）</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="类型" width="100">
        <template slot-scope="scope">
          {{ scope.row.c_store_type === 1 ? "无人仓" : "无人柜" }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="管理员" width="100">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.c_manager_phone" placement="top">
            <span class="cornflowerblue pointer">{{ scope.row.c_manager_name }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="total_warehouse_count" label="设计库位" width="100" />
      <el-table-column align="center" prop="no_inventory_warehouse_count" label="可用库位" width="100" />
      <!--      <el-table-column align="center" prop="address" label="操作" width="100">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button type="text" size="mini" @click="locationManage(scope.row.sid)">管理</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </dc-datatable>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "StoreLocationList",
  components: {},
  props: {
    merchantList: {
      type: Array,
      default: null,
    },
    baseAreaList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      urlApi: `${this.baseUrls.store}/queryPageLocation`,
      queryPara: {
        cname: null,
        baseArea: null,
        merchant: null,
      },
    };
  },
  watch: {
    merchantList() {
      this.queryPara.merchant = this.merchantList + "";
      this.doSearch();
    },
    baseAreaList() {
      this.queryPara.baseArea = this.baseAreaList + "";
      this.doSearch();
    },
  },
  mounted() {
    let that = this;
    var searchInput = document.getElementById("searchInput");
    searchInput.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        that.doSearch();
      }
    });
  },
  methods: {
    /**
     * 门店详细
     * @constructor
     */
    storeInfo(id) {
      this.$emit("StoreInfo", id);
    },
    /**
     * 门店添加与修改
     * @constructor
     */
    locationManage(sid) {
      this.$emit("LocationManage", sid);
    },
    doSearch: _.debounce(function () {
      // 这里是你想要执行的代码
      this.$refs.list.load();
    }, 1000),
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.storeLocationList {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
</style>
