var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "goodsAdd__top",
          staticStyle: {
            "background-color": "#f7f6f6",
            "font-weight": "500",
            "line-height": "27px",
          },
        },
        [_vm._v("  " + _vm._s(_vm.storeData.caddr) + " ")]
      ),
      _c("br"),
      _c("el-image", {
        staticStyle: { width: "310px", height: "310px" },
        attrs: {
          src: _vm.inventoryData.c_master_url,
          fit: "fit",
          "preview-src-list": [_vm.inventoryData.c_master_url],
        },
      }),
      _c(
        "div",
        { staticClass: "goodsAdd__body" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.data,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("span", { staticStyle: { color: "#a8abb2" } }, [
                      _vm._v("货品名称"),
                    ]),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        "line-clamp": "2",
                        "-webkit-line-clamp": "2",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                        "line-height": "20px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.inventoryData.c_name))]
                  ),
                ],
                2
              ),
              _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("span", { staticStyle: { color: "#a8abb2" } }, [
                      _vm._v("货品SKU"),
                    ]),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        "line-clamp": "2",
                        "-webkit-line-clamp": "2",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      },
                    },
                    [_vm._v(_vm._s(_vm.inventoryData.c_remark))]
                  ),
                ],
                2
              ),
              _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("span", { staticStyle: { color: "#a8abb2" } }, [
                      _vm._v("货品编码"),
                    ]),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        "line-clamp": "2",
                        "-webkit-line-clamp": "2",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      },
                    },
                    [_vm._v(_vm._s(_vm.inventoryData.c_sku))]
                  ),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "库位名称", prop: "warehouse" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "210px" },
                      attrs: { filterable: "", placeholder: "请选择库位" },
                      model: {
                        value: _vm.data.warehouseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "warehouseId", $$v)
                        },
                        expression: "data.warehouseId",
                      },
                    },
                    _vm._l(_vm.warehouseList, function (item) {
                      return _c("el-option", {
                        key: item.sid,
                        attrs: { label: item.c_num, value: item.sid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("span", [
                      _vm._v("最大库龄"),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]),
                  ]),
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "210px" },
                      attrs: { placeholder: "请输入最大库龄" },
                      model: {
                        value: _vm.data.ph,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "ph", $$v)
                        },
                        expression: "data.ph",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "suffix" }, slot: "suffix" },
                        [_vm._v("天  ")]
                      ),
                    ]
                  ),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "临期提醒" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入最大库龄" },
                    model: {
                      value: _vm.data.ph,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "ph", $$v)
                      },
                      expression: "data.ph",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最大库存" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入最大库龄" },
                    model: {
                      value: _vm.data.ph,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "ph", $$v)
                      },
                      expression: "data.ph",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "安全库存" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入最大库龄" },
                    model: {
                      value: _vm.data.ph,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "ph", $$v)
                      },
                      expression: "data.ph",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最小库存" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入最大库龄" },
                    model: {
                      value: _vm.data.ph,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "ph", $$v)
                      },
                      expression: "data.ph",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "goodsAdd__bottom" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("提 交")]
          ),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("关 闭")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }