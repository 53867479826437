var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "storeAdd" }, [
    _c("div", {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" },
      ],
      attrs: { id: "container" },
    }),
    _c("div", { staticClass: "storeAdd__left" }, [
      _c("div", { staticClass: "fs20 fWB borderBottom paddingBottom10" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("div", { staticClass: "scrollbar marginTop10 flexGrow1" }, [
        _c(
          "div",
          { staticClass: "marginBottom10 paddingRight10 paddingLeft10" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  inline: true,
                  "label-position": "left",
                  model: _vm.form,
                  "label-width": "80px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "门店编号" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.ccode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ccode", $$v)
                        },
                        expression: "form.ccode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "门店名称" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.cname,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cname", $$v)
                        },
                        expression: "form.cname",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "门店类型" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择门店类型" },
                        model: {
                          value: _vm.form.cstoreType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cstoreType", $$v)
                          },
                          expression: "form.cstoreType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "无人仓", value: "1" },
                        }),
                        _c("el-option", {
                          attrs: { label: "无人柜", value: "2" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "所属商户" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          placeholder: "请选择所属商户",
                        },
                        model: {
                          value: _vm.form.cmerchantId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cmerchantId", $$v)
                          },
                          expression: "form.cmerchantId",
                        },
                      },
                      _vm._l(_vm.merchantOptions, function (item) {
                        return _c("el-option", {
                          key: item.sid,
                          attrs: { label: item.cname, value: item.sid },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "管理员" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.cmanagerName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cmanagerName", $$v)
                        },
                        expression: "form.cmanagerName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "电话号码" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.cmanagerPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cmanagerPhone", $$v)
                        },
                        expression: "form.cmanagerPhone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "理货员" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.ctallymanName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ctallymanName", $$v)
                        },
                        expression: "form.ctallymanName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "电话号码" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.ctallymanPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ctallymanPhone", $$v)
                        },
                        expression: "form.ctallymanPhone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "启用日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: { type: "date", placeholder: "选择日期" },
                      on: { change: _vm.handleDateChange },
                      model: {
                        value: _vm.value1,
                        callback: function ($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "wFP" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所在区域" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.options,
                              props: _vm.defaultProps,
                            },
                            model: {
                              value: _vm.form.cregion,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cregion", $$v)
                              },
                              expression: "form.cregion",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                "filter-method": _vm.getReceivingAddress,
                                placeholder: "请选择",
                              },
                              on: { change: _vm.changeInfo },
                              model: {
                                value: _vm.form.clocation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "clocation", $$v)
                                },
                                expression: "form.clocation",
                              },
                            },
                            _vm._l(_vm.addrOptions, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: { label: item.name, value: item },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex directionColumn" },
                                    [
                                      _c("div", [_vm._v(_vm._s(item.name))]),
                                      _c("div", { staticClass: "grey" }, [
                                        _vm._v(_vm._s(item.address)),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "经度" } },
                        [
                          _c("el-input", {
                            staticClass: "w150",
                            on: {
                              input: function ($event) {
                                return _vm.setLongitude(_vm.form.clongitude)
                              },
                            },
                            model: {
                              value: _vm.form.clongitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clongitude", $$v)
                              },
                              expression: "form.clongitude",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "纬度" } },
                        [
                          _c("el-input", {
                            staticClass: "w150",
                            on: {
                              input: function ($event) {
                                return _vm.setLatitude(_vm.form.clatitude)
                              },
                            },
                            model: {
                              value: _vm.form.clatitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clatitude", $$v)
                              },
                              expression: "form.clatitude",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "详细地址" } },
                        [
                          _c("el-input", {
                            staticClass: "w500",
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.form.caddr,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "caddr", $$v)
                              },
                              expression: "form.caddr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "wFP margin10" },
                    [
                      _c(
                        "el-amap",
                        {
                          staticClass: "hHP wHP",
                          attrs: { center: _vm.center, zoom: _vm.zoom },
                          on: { AMAP_READY_EVENT: _vm.handleMapClick },
                        },
                        _vm._l(_vm.markers, function (marker, index) {
                          return _c("el-amap-marker", {
                            key: index,
                            attrs: { position: marker.position },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "marginTop10" }, [
                  _c("div", { staticClass: "fs16 fw700 marginBottom10" }, [
                    _vm._v("配送范围"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "marginBottom10",
                          attrs: { placeholder: "请选择配送范围" },
                          model: {
                            value: _vm.radius,
                            callback: function ($$v) {
                              _vm.radius = $$v
                            },
                            expression: "radius",
                          },
                        },
                        _vm._l(_vm.deliveryScope, function (item) {
                          return _c("el-option", {
                            key: item.scope,
                            attrs: { label: item.label, value: item.scope },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "h500 wHP" },
                    [
                      _c(
                        "el-amap",
                        { attrs: { center: _vm.center, zoom: _vm.zoom } },
                        [
                          _c("el-amap-circle", {
                            attrs: {
                              center: _vm.circleCenter,
                              radius: _vm.radius,
                              "stroke-color": "#ADD8E6",
                              "fill-color": "#ADD8E6",
                              "stroke-opacity": 1,
                              "stroke-weight": 3,
                              "fill-opacity": 0.35,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "marginTop10" }, [
                  _c("div", { staticClass: "fs16 fw700" }, [
                    _vm._v("更多信息"),
                  ]),
                  _c("div", { staticClass: "padding10" }, [
                    _vm._v("营业时间 24小时营业"),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "h50 padding10 marginTop10 borderTop" },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "end" } },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "storeAdd__right scrollbar" },
      [
        _c(
          "div",
          {
            staticClass:
              "paddingTop15 paddingBottom15 borderBottom textCenter wHP",
          },
          [_vm._v("门店形象")]
        ),
        _c(
          "el-scrollbar",
          { staticClass: "flexCenter", staticStyle: { height: "670px" } },
          [
            _c("image-upload", {
              staticClass: "w150 h150 marginBottom20 marginTop10 scrollbar",
              on: { input: _vm.fileList },
            }),
            _vm._l(_vm.urls, function (url, index) {
              return _c(
                "div",
                { key: index, staticClass: "marginBottom20 scrollbar" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w150 h150 padding10 border bcGrey marginBottom5",
                    },
                    [
                      _c("el-image", {
                        key: url,
                        staticClass: "hHP wHP",
                        attrs: { fit: "fit", src: url },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }