var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "goodsAdd" }, [
    _c("div", { staticClass: "goodsAdd__top fs17" }, [
      _vm._v("门店名称: " + _vm._s(_vm.storeName)),
    ]),
    _c(
      "div",
      { staticClass: "goodsAdd__body" },
      [
        _c(
          "el-table",
          {
            ref: "product",
            staticClass: "whp marginTop10 marginBottom10",
            attrs: {
              data: _vm.tableData,
              "row-key": "id",
              stripe: "",
              border: "",
              "highlight-current-row": "",
              "header-cell-class-name": "table_header",
            },
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", label: "SKU", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "选择SKU" },
                          on: {
                            change: function ($event) {
                              return _vm.onChangeSKU(scope.row, $event)
                            },
                          },
                          model: {
                            value: scope.row.cbarcode,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "cbarcode", $$v)
                            },
                            expression: "scope.row.cbarcode",
                          },
                        },
                        _vm._l(_vm.goodsList, function (item) {
                          return _c("el-option", {
                            key: item.sid,
                            attrs: { label: item.cbarcode, value: item },
                          })
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "cSku",
                label: "条码",
                width: "150",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "图片", width: "70" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.masterUrl != null
                        ? _c("el-image", {
                            staticClass: "w50 h50",
                            attrs: { src: scope.row.masterUrl },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                prop: "sGoods",
                label: "货品名称",
                "show-overflow-tooltip": true,
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "库位", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "选择库位" },
                          on: {
                            change: function ($event) {
                              return _vm.onChangeWarehouse(scope.row, $event)
                            },
                          },
                          model: {
                            value: scope.row.cName,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "cName", $$v)
                            },
                            expression: "scope.row.cName",
                          },
                        },
                        _vm._l(_vm.warehouseList, function (item) {
                          return _c("el-option", {
                            key: item.sid,
                            attrs: { label: item.c_name, value: item },
                          })
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "数量", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        staticStyle: { width: "70px" },
                        attrs: { placeholder: "数量" },
                        on: {
                          input: function ($event) {
                            return _vm.handleInput(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.nSurplus,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "nSurplus", $$v)
                          },
                          expression: "scope.row.nSurplus",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "操作", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("i", {
                        staticClass: "el-icon-delete pointer",
                        on: {
                          click: function ($event) {
                            return _vm.onDelete(scope.$index)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "goodsAdd__bottom" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
          [_vm._v("提 交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }