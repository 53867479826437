<template>
  <!-- 入库详细-->
  <div>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="出库制单" :visible.sync="dialogVisible" width="800px" append-to-body>
      <el-input v-model="textarea" type="textarea" :rows="2" placeholder="请输入撤回原因"> </el-input>
      <el-button type="warning" @click="dialogVisible = false">确认</el-button>
      <el-button type="primary" @click="dialogVisible = false">取消</el-button>
    </el-dialog>
    <div class="InventoryInInfo">
      <el-descriptions :column="3" :colon="false">
        <el-descriptions-item>
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">系统单号:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.csys }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">出库类型:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.ptype | inType }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">原始单号:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.ccode }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="3" :colon="false">
        <el-descriptions-item label="出库门店">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">出库门店:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.sstore }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="出库时间">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">出库时间:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.tout | time }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="入库门店">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">入库门店:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.sallotStore }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="3" :colon="false">
        <el-descriptions-item label="制单人">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">制单人:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.sout }}</span></el-descriptions-item>
        <el-descriptions-item label="制单时间">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">制单时间:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.tcreate | time }}</span></el-descriptions-item>
        <el-descriptions-item label="审核状态">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">审核状态:</span>
          </template>
          <span v-if="info.paudit === 3" style="color: green;">{{ info.paudit | auditState }}</span>
          <span v-else-if="info.paudit === 4" style="color: red;">{{ info.paudit | auditState }}</span>
          <span v-else>{{ info.paudit | auditState }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="3" :colon="false">
        <el-descriptions-item label="审单人">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">审单人:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.saudit }}</span></el-descriptions-item>
        <el-descriptions-item label="审单时间">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">审单时间:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.taudit | time }}</span></el-descriptions-item>
        <el-descriptions-item label="审核说明">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">审核说明:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.cauditRemark }}</span></el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="1" :colon="false">
        <el-descriptions-item label="单据备注">
          <template slot="label">
            <span :class="inventoryIn.rinventoryOrder ? 'red' : ''">单据备注:</span>
          </template>
          <span :class="inventoryIn.rinventoryOrder ? 'grey' : ''">{{ info.cremark }}</span></el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="1" :colon="false">
        <el-descriptions-item label="搜索">
          <el-input style="width: 210px;" placeholder="SKU/条码/货品名称" v-model="search" @input="handleInput"/>
        </el-descriptions-item>
      </el-descriptions>
      <div class="h450">
        <el-table ref="multipleTable" :data="tableData" height="400" @selection-change="handleSelectionChange" :header-row-class-name="inventoryIn.rinventoryOrder ? 'red' : ''" :row-class-name="inventoryIn.rinventoryOrder ? 'grey' : ''">
          <el-table-column type="selection" align="center" width="50" />
          <el-table-column label="序号" align="center" width="50" type="index" />
          <el-table-column label="SKU" align="center" width="70" prop="c_barcode" />
          <el-table-column label="图片" align="center" width="70">
            <template #default="scope">
              <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="货品编码" align="center" width="130" prop="c_sku" />
          <el-table-column label="货品名称" align="center" prop="c_name" />
          <el-table-column label="单位" align="center" width="50" prop="s_unit" />
          <el-table-column label="库位" align="center" width="70" prop="z_inventory" />
          <el-table-column label="数量" align="center" width="50" prop="n_out" >
            <template #default="scope">
              {{ inventoryIn.rinventoryOrder ? -scope.row.n_out : scope.row.n_out }}
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right;margin-top: 10px;">
          <el-button  plain @click="handleExport">导出Excel</el-button>
      <el-button type="danger" plain @click="inventoryInCh">冲红</el-button>
    </div>
      </div>
    </div>
  </div>
</template>
<script>
import { exportInventoryOrderDetailOutOne } from "../../../api/goods/goods";
import moment from "moment";

export default {
  name: "InventoryInInfo",
  filters: {
    time(time) {
      if (time !== "" && time !== null && time !== undefined) {
        return moment(time).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
    auditState(value) {
      // 审核状态 0:未审核 1:审核通过 2:审核失败
      if (value === 0) {
        return "制单中";
      } else if (value === 1) {
        return "待审核";
      } else if (value === 2) {
        return "审核中";
      } else if (value === 3) {
        return "已通过";
      } else if (value === 4) {
        return "被拒绝";
      } else if (value === 5) {
        return "部分冲红";
      } else if (value === 6) {
        return "已调拨";
      } else if (value === 7) {
        return "已冲红";
      } else {
        return "";
      }
    },
    inType(value) {
      if (value === "00") {
    return "其他出库";
  } else if (value === "10") {
    return "订单出库";
  } else if (value === "20") {
    return "调拨出库";
  } else if (value === "30") {
    return "报损出库";
  } else if (value === "40") {
    return "盘亏出库";
  } else if (value === "50") {
    return "缺陷出库";
  } else if (value === "60") {
    return "线下出库";
  } else {
    return "";
  }
    },
  },
  props: {
    inventoryIn: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "1", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      info: {},
      tableData: [],
      dialogVisible: false,
      textarea: "",
      search: "",
      totalTableData: [],
      selectedRows: []
    };
  },
  created() {
    this.info = this.inventoryIn;
    this.selectByInId(this.info.sid);
  },
  methods: {
    handleExport() {
      exportInventoryOrderDetailOutOne({outId: this.info.sid });
    },
    handleSelectionChange(selection) {
      this.selectedRows = selection;
    },
    handleInput() {
      let arr = []
      if (this.search !== '') {
        for (let i = 0; i < this.totalTableData.length; i++) {
          if (this.totalTableData[i].c_barcode.indexOf(this.search) !== -1 || this.totalTableData[i].c_sku.indexOf(this.search) !== -1 || this.totalTableData[i].c_name.indexOf(this.search) !== -1) {
            arr.push(this.totalTableData[i])
          }
        }
      } else {
        arr = this.totalTableData
      }
      this.tableData = arr
    },
    selectByInId(id) {
      let url = `${this.baseUrls.repInventoryOrderDetailOut}/selectByOutId`;
      this.topsAjax.get(url, { outId: id }).then((res) => {
        this.tableData = res;
        this.totalTableData = this.tableData
      });
    },
    inventoryInCh() {
      let url = `${this.baseUrls.repInventoryOrderOut}/inventoryOrderOutRed`;
      let params = { repInventoryOrderOutId: this.info.sid, goodsList: this.selectedRows };
      this.topsAjax.post(url, params).then((res) => {
        this.$message({
          showClose: true,
          message: "操作成功",
          type: "success",
        });
        this.$emit("handleClose");
      })
    },
  },
};
</script>
<style lang="scss">
.InventoryInInfo {
  height: 680px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .el-divider--horizontal {
    margin: 8px 0;
  }
}
</style>
