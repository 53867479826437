<template>
  <!-- 门店管理 -->
  <div class="storeManage bcWhite box-shadow padding10">
    <div class="fs20 fWB borderBottom paddingBottom10">门店列表</div>
    <div class="flex" style="padding: 10px;border: 1px solid #ebeef5;">
      <el-input v-model="queryPara.search" clearable placeholder="输入门店名称或地址" class="w250 input-with-select" @keyup.enter.native="queryList">
        <el-button slot="append" icon="el-icon-search" @click="queryList"></el-button>
      </el-input>
    </div>
    <div class="flexGrow1" style="margin-top: 10px;">
    <el-table :data="tableData" border v-loading="loading" element-loading-text="加载中，请稍等...">
      <el-table-column align="center" type="index" :index="indexMethod" label="序" width="100" />
      <el-table-column align="left" header-align="center" label="门店名称">
        <template slot-scope="scope">
          <div class="paddingLeft10">
            <div slot="reference" class="cornflowerblue pointer" @click="StoreGoods(scope.row.sid)">
              {{ scope.row.c_name }}
            </div>
            <div slot="reference" class="fs12 lightgray">{{ scope.row.c_location }}（{{ scope.row.c_addr }}）</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="类型" width="100">无人仓</el-table-column>
      <el-table-column align="center" label="管理员" width="100">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.c_manager_phone" placement="top">
            <span class="cornflowerblue pointer">{{ scope.row.c_manager_name }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="goodsVariety" label="货品种类" width="130"></el-table-column>
      <el-table-column align="center" prop="inventoryQuantity" label="库存总量" width="130"></el-table-column>
      <!--      <el-table-column align="center" prop="address" label="操作" width="100">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button type="text" size="mini" @click="StoreGoods(scope.row.sid)">管理</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    </div>
    <div class="h50" v-if="queryPara.total != 0">
        <el-pagination :current-page="queryPara.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="queryPara.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="queryPara.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "StoreManage",
  props: {
    merchantList: {
      type: Array,
      default: null,
    },
    baseAreaList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      urlApi: `${this.baseUrls.store}/queryPageManage`,
      queryPara: {
        search: null,
        baseArea: null,
        merchant: null,
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      loading: false
    };
  },
  watch: {
    merchantList() {
      this.queryPara.merchant = this.merchantList + "";
      this.queryList();
    },
    baseAreaList() {
      this.queryPara.baseArea = this.baseAreaList + "";
      this.queryList();
    },
  },
  created() {},
  mounted() {
    // let that = this;
    // var searchInput = document.getElementById("searchInput");
    // searchInput.addEventListener("keydown", function (event) {
    //   if (event.key === "Enter") {
    //     that.queryList();
    //   }
    // });
    this.queryList();
  },
  methods: {
    handleSizeChange(val) {
      this.queryPara.pageSize = val;
      this.queryList();
    },
    handleCurrentChange(val) {
      this.queryPara.currentPage = val;
      this.queryList();
    },
    queryList() {
      this.loading = true
      if (this.queryPara.search) {
        this.queryPara.search = this.queryPara.search.trim()
      }
      
      let url = `${this.baseUrls.store}/queryPageManage`;
      this.topsAjax.get(url, this.queryPara).then((res) => {
        this.tableData = res.list;
        this.queryPara.total = res.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      });
    },
    StoreGoods(id) {
      this.$emit("StoreGoods", id);
    },
    doSearch: _.debounce(function () {
      // 这里是你想要执行的代码
      this.$refs.list.load();
    }, 1000),
    /**
     * 门店详细
     * @constructor
     */
    storeInfo(id) {
      this.$emit("StoreInfo", id);
    },
    indexMethod(index) {
      // let page = this.$refs.list.page;
      return index + 1 + (this.queryPara.currentPage - 1) * this.queryPara.pageSize;
    },
  },
};
</script>

<style lang="scss" scoped>
.storeManage {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
</style>
