<template>
  <div class="storeBrief box-shadow">
    <div class="wFP">
      <span class="fs20 marginLeft10 cornflowerblue">{{ storeData.cname }}</span>
      <el-descriptions :column="1" :colon="false" class="marginTop10">
        <el-descriptions-item label="门店号">{{ storeData.ccode }}</el-descriptions-item>
        <el-descriptions-item label="管理员">
          {{ storeData.cmanagerName }} <span class="cornflowerblue">{{ storeData.cmanagerPhone }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="理货员">
          {{ storeData.ctallymanName }}<span class="cornflowerblue">{{ storeData.ctallymanPhone }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="所在区域">{{ storeData.regionName }}</el-descriptions-item>
        <el-descriptions-item label="详细地址">{{ storeData.caddr }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="wFP flex">
      <div class="flexGrow1">
        <el-amap :center="center" :zoom="zoom">
          <el-amap-marker v-for="(marker, index) in markers" :key="index" :position="marker.position" />
          <el-amap-circle :center="circleCenter" :radius="radius" :stroke-color="'#ADD8E6'" :fill-color="'#ADD8E6'" :stroke-opacity="1" :stroke-weight="3" :fill-opacity="0.35"></el-amap-circle>
        </el-amap>
      </div>
      <div class="marginLeft10 w100 scrollbar">
        <div v-if="urls.length === 0" class="scrollbar">
          <el-image :key="1" :src="url" class="border" />
          <el-image :key="2" :src="url" class="border" />
          <el-image :key="3" :src="url" class="border" />
          <el-image :key="4" :src="url" class="border" />
        </div>
        <!-- <el-image v-for="url in urls" v-else :key="url" :src="url"></el-image> -->
        <el-image v-for="(url, index) in urls" :key="index" :src="url"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreBrief",
  props: {
    storeId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      url: require("@/assets/noImage.png"),
      // urls: ["https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg", "https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg", "https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg", "https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg", "https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg", "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg", "https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg"],
      urls: [],
      center: [116.397455, 39.909192], // 地图中心点的经纬度
      zoom: 14, // 地图的缩放级别
      markers: [{ position: [116.397455, 39.909192] }], // 存放标记的数组+
      circleCenter: [116.397455, 39.909192],
      radius: 1000, // 圆形的半径，单位为米
      storeData: {},
    };
  },
  watch: {
    storeId() {
      this.selectStoreById();
    },
  },
  created() {
    this.selectStoreById();
  },
  methods: {
    selectStoreById() {
      this.topsAjax.get(`${this.baseUrls.store}/selectById`, { sid: this.storeId }).then((response) => {
        let latitude = response.store.clatitude;
        let longitude = response.store.clongitude;
        this.center = [longitude ? longitude : 116.397455, latitude ? latitude : 39.909192];
        this.markers = [{ position: [longitude ? longitude : 116.397455, latitude ? latitude : 39.909192] }];
        this.circleCenter = [longitude ? longitude : 116.397455, latitude ? latitude : 39.909192];
        this.storeData = response.store;
        this.storeData.regionName = response.regionName;
      });
    },
  },
};
</script>

<style lang="scss">
.storeBrief {
  max-height: 200px;
  width: 100%;
  display: flex;
  padding: 10px;
}
</style>
