<template>
  <!-- 货品添加弹窗-->
  <div>
    <div class="goodsAdd__top" style="background-color: #f7f6f6;font-weight: 500;line-height: 27px;">&nbsp;&nbsp;{{ storeData.cname }} </div>
    <br>
    <el-image :src="inventoryData.c_master_url" fit="fit" :preview-src-list="[inventoryData.c_master_url]" style="width: 200px; height: 200px; margin-left: 55px;" />
    <div class="goodsAdd__body">
      <el-form ref="form" :model="data" label-width="100px" :rules="rules">
        <el-form-item>
          <template slot="label">
            <span style="color: #a8abb2">货品名称</span>
          </template>
          <span style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; line-height: 20px;">{{ inventoryData.c_name }}</span>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span style="color: #a8abb2">货品SKU</span>
          </template>
          <span style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; ">{{ inventoryData.c_remark }}</span>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span style="color: #a8abb2">货品编码</span>
          </template>
          <span style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; ">{{ inventoryData.c_sku }}</span>
        </el-form-item>
        <el-form-item label="库位名称" prop="warehouse">
          <el-select style="width: 210px;" v-model="data.warehouseId" filterable placeholder="请选择库位">
            <el-option v-for="item in warehouseList" :key="item.sid" :label="item.c_num" :value="item.sid" />
          </el-select>
        </el-form-item>
        <el-form-item label="上架批号">
          <el-input style="width: 210px;" placeholder="请输入上架批号" v-model="data.ph" />
        </el-form-item>
        <el-form-item label="上架库存">
          <el-input style="width: 210px;" placeholder="请输入上架库存" v-model="data.n_total" readonly/>
        </el-form-item>
      </el-form>
    </div>
    <div class="goodsAdd__bottom">
      <el-button type="primary" @click="onSubmit">提 交</el-button>
      <el-button @click="cancel">关 闭</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "GoodsAdd",
  props: {
    storeId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
    inventoryData: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    }
  },
  data() {
    return {
      url: require("@/assets/noImage.png"),
      data: {
        num: 0
      },
      // 商品列表
      goodsList: [],
      storeData: {
        caddr: ''
      },
      rules: {
        warehouse: [ { required: true, message: '请选择库位', trigger: 'blur' }]
      },
      warehouseList: []
    };
  },
  mounted() {
    this.data.n_total = this.inventoryData.n_total
    this.selectEmptyWarehouse();
    this.selectStoreById()
  },
  methods: {
    selectStoreById() {
      this.topsAjax.get(`${this.baseUrls.store}/selectById`, { sid: this.storeId }).then((response) => {
        this.storeData = response.store;
        this.storeData.regionName = response.regionName;
      })
    },
    /**
     * 取消
     */
    cancel() {
      this.$emit("handleClose");
    },
    /**
     * 提交
     */
    onSubmit() {
      if (!this.data.warehouseId) {
        this.$message({
          message: "请选择库位",
          type: "info",
        });
        return
      }
      const url = `${this.baseUrls.repInventoryOrderIn}/goodsShelvesWarehouse`;
      this.data.sid = this.inventoryData.sid
      this.topsAjax.post(url, this.data).then(() => {
        this.$message({
          message: "上架成功",
          type: "success",
        });
        this.cancel();
      });
    },
    selectEmptyWarehouse() {
      this.topsAjax.get(`${this.baseUrls.warehouse}/selectEmptyWarehouse`, { storeId: this.storeId }).then((res) => {
        this.warehouseList = res;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 10px;
}
</style>
