// 盘点表状态
export function sheetState(value) {
  if (value === 1) {
    return "制单中";
  } else if (value === 2) {
    return "待盘点";
  } else if (value === 3) {
    return "盘点中";
  } else if (value === 4) {
    return "待审核";
  } else if (value === 5) {
    return "审核中";
  } else if (value === 6) {
    return "审核通过";
  } else if (value === 7) {
    return "审核失败";
  } else {
    return "";
  }
}

//盘点表类型
export function sheetType(value) {
  if (value === 1) {
    return "日常盘库";
  } else if (value === 2) {
    return "临时盘库";
  } else {
    return "";
  }
}

//盘点方式
export function sheetWay(value) {
  if (value === 1) {
    return "自由盘点";
  } else if (value === 2) {
    return "指定盘点";
  } else {
    return "";
  }
}

//入库类型
export function orderInType(value) {
  if (value === "00") {
    return "其他入库";
  } else if (value === "10") {
    return "期初入库";
  } else if (value === "20") {
    return "补货入库";
  } else if (value === "30") {
    return "退货入库";
  } else if (value === "40") {
    return "调拔入库";
  } else if (value === "50") {
    return "盘盈入库";
  } else {
    return "";
  }
}

//出库类型
export function orderOutType(value) {
  if (value === "00") {
    return "其他出库";
  } else if (value === "10") {
    return "订单出库";
  } else if (value === "20") {
    return "调拨出库";
  } else if (value === "30") {
    return "报损出库";
  } else if (value === "40") {
    return "盘亏出库";
  } else if (value === "50") {
    return "缺陷出库";
  } else if (value === "60") {
    return "线下出库";
  } else {
    return "";
  }
}

export function orderAudit(value) {
  // 审核状态 0:未审核 1:审核通过 2:审核失败
  if (value === 0) {
    return "制单中";
  } else if (value === 1) {
    return "待审核";
  } else if (value === 2) {
    return "审核中";
  } else if (value === 3) {
    return "已通过";
  } else if (value === 4) {
    return "被拒绝";
  } else if (value === 5) {
    return "部分冲红";
  } else if (value === 6) {
    return "已调拨";
  } else if (value === 7) {
    return "已冲红";
  }
}

/**
 * 调拨类型
 * @param value
 * @returns {string}
 */
export function allotType(value) {
  // 审核状态 0:未审核 1:审核通过 2:审核失败
  if (value === 1) {
    return "平价调拔";
  } else if (value === 2) {
    return "溢价调拔";
  } else if (value === 3) {
    return "折价调拔";
  } else {
    return "";
  }
}

/**
 * 调拨价格
 * @param value
 * @returns {string}
 */
export function allotPrice(value) {
  // 审核状态 0:未审核 1:审核通过 2:审核失败
  if (value === 1) {
    return "成本价";
  } else if (value === 2) {
    return "特供价";
  } else if (value === 3) {
    return "核心价";
  } else if (value === 4) {
    return "代理价";
  } else if (value === 5) {
    return "批发价";
  } else {
    return "";
  }
}

export function tableType(value) {
  // 审核状态 0:未审核 1:审核通过 2:审核失败
  if (value === 0) {
    return "入库";
  } else if (value === 1) {
    return "出库";
  } else {
    return "";
  }
}
