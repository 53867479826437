<template>
  <!--  入库审核-->
  <div class="ReviewInventoryIn">
    <el-descriptions :column="3">
      <el-descriptions-item label="系统单号">{{ info.csys }}</el-descriptions-item>
      <el-descriptions-item label="出库类型">{{ info.ptype | inType }}</el-descriptions-item>
      <el-descriptions-item label="原始单号">{{ info.ccode }}</el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions :column="3">
      <el-descriptions-item label="出库门店">{{ info.sstore }}</el-descriptions-item>
      <el-descriptions-item label="出库时间">{{ info.tcreate | time }}</el-descriptions-item>
      <el-descriptions-item label="入库门店">{{ info.sallotStore }}</el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions :column="3">
      <el-descriptions-item label="制单人">{{ info.sout }}</el-descriptions-item>
      <el-descriptions-item label="制单时间">{{ info.tcreate | time }}</el-descriptions-item>
      <el-descriptions-item label="审核状态">{{ info.paudit | auditState }}</el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions :column="1">
      <el-descriptions-item label="单据备注">{{ info.cremark }}</el-descriptions-item>
    </el-descriptions>
    <div class="h450">
      <el-table ref="multipleTable" :data="tableData" height="450" :header-row-class-name="inventoryIn.rinventoryOrder ? 'red' : ''" :row-class-name="inventoryIn.rinventoryOrder ? 'grey' : ''">
        <el-table-column label="序号" align="center" width="50" type="index" />
        <el-table-column label="SKU" align="center" width="70" prop="c_barcode" />
        <el-table-column label="图片" align="center" width="70">
          <template #default="scope">
            <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="货品编码" align="center" width="130" prop="c_sku" />
        <el-table-column label="货品名称" align="center" prop="c_name" />
        <el-table-column label="单位" align="center" width="50" />
        <el-table-column label="库位" align="center" width="70" prop="z_inventory" />
        <el-table-column label="数量" align="center" width="50" prop="n_out" >
          <template #default="scope">
            {{ formatTotal(scope.row.n_out) }}
            </template>
        </el-table-column>
        <el-table-column label="货品批次" align="center" width="100" prop="todo" />
        <!-- <el-table-column v-if="inventoryIn.rinventoryOrder" align="center" prop="address" label="操作" width="140">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="showEditNum(scope.row)">修改</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="修改数量" :visible.sync="dialogVisible" width="250px" append-to-body>
      <el-input-number style="width: 210px;" placeholder="数量" v-model="editNum" type="number"/><br><br>
      <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
      <div>
      </div>
      <div class="flex">
        <el-button @click="handleEditNum">确定</el-button>
      </div>
    </div>
    </el-dialog>
    <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
      <div>
        共计 <span class="blue">{{ tableData.length }}种</span> 货品
      </div>
      <div class="flex">
        <el-input v-model="auditRemark" placeholder="请输入拒绝理由" class="marginRight10" />
        <el-button @click="reviewFailed(info.sid)">拒绝通过</el-button>
        <el-button @click="approved(info.sid)" :disabled="btnDisabled">审核通过</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment/moment";

export default {
  name: "ReviewInventoryIn",
  filters: {
    time(time) {
      if (time !== "" && time !== null && time !== undefined) {
        return moment(time).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
    auditState(value) {
      // 审核状态 0:未审核 1:审核通过 2:审核失败
      if (value === 0) {
    return "制单中";
  } else if (value === 1) {
    return "审核中";
  } else if (value === 2) {
    return "审核中";
  } else if (value === 3) {
    return "已通过";
  } else if (value === 4) {
    return "被拒绝";
  } else if (value === 5) {
    return "部分冲红";
  } else if (value === 6) {
    return "已调拨";
  } else if (value === 7) {
    return "已冲红";
  }
    },
    inType(value) {
      if (value === "00") {
    return "其他出库";
  } else if (value === "10") {
    return "订单出库";
  } else if (value === "20") {
    return "调拨出库";
  } else if (value === "30") {
    return "报损出库";
  } else if (value === "40") {
    return "盘亏出库";
  } else if (value === "50") {
    return "缺陷出库";
  } else if (value === "60") {
    return "线下出库";
  } else {
    return "";
  }
    },
  },
  props: {
    inventoryIn: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "1", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      info: {},
      tableData: [],
      auditRemark: "",
      btnDisabled: false,
      dialogVisible: false,
      editNum: 0,
      tableRow: {}
    };
  },
  created() {
    this.info = this.inventoryIn;
    this.selectByInId(this.info.sid);
  },
  methods: {
    showEditNum(row) {
      this.tableRow = row
      this.editNum = -row.n_out
      this.dialogVisible = true
    },
    handleEditNum() {
      if (-this.editNum < this.tableRow.n_out) {
        this.$message.error(`数量大于初始出库数量，请重新输入!`);
        return;
      }
      this.tableRow.n_out = -this.editNum
      this.dialogVisible = false
    },
    formatTotal(val) {
      if (val < 0) {
        return -val;
      } else {
        return val
      }
    },
    selectByInId(id) {
      let url = `${this.baseUrls.repInventoryOrderDetailOut}/selectByOutId`;
      this.topsAjax.get(url, { outId: id }).then((res) => {
        this.tableData = res;
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].srcn_out = this.tableData[i].n_out
        }
      });
    },
    /**
     * 拒绝通过
     * @param id
     */
    reviewFailed(id) {
      if (this.auditRemark === "" || this.auditRemark === null || this.auditRemark === undefined) {
        this.$message.error(`请输入拒绝理由!`);
        return;
      }
      let url = `${this.baseUrls.repInventoryOrderOut}/reviewFailed`;
      let params = { InventoryOrderOutId: id, auditRemark: this.auditRemark };
      this.topsAjax.post(url, params).then((res) => {
        this.$message({
          showClose: true,
          message: "撤回成功",
          type: "success",
        });
        this.$emit("handleClose");
      });
    },
    /**
     * 审核通过
     * @param id
     */
    approved(id) {
      this.btnDisabled = true
      let url = `${this.baseUrls.repInventoryOrderOut}/approved`;
      let params = { InventoryOrderOutId: id };
      this.topsAjax.post(url, params).then((res) => {
        this.$message({
          showClose: true,
          message: "审核成功",
          type: "success",
        });
        this.$emit("handleClose");
        this.btnDisabled = false
      }).catch(() => {
        this.btnDisabled = false
      });
    },
  },
};
</script>

<style lang="scss">
.ReviewInventoryIn {
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .el-divider--horizontal {
    margin: 8px 0;
  }
}
</style>
