var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeList bcWhite box-shadow padding10" },
    [
      _c("div", { staticClass: "fs20 fWB borderBottom paddingBottom10" }, [
        _vm._v("门店列表"),
      ]),
      _c(
        "div",
        { staticStyle: { padding: "10px", border: "1px solid #ebeef5" } },
        [
          _c(
            "el-input",
            {
              staticClass: "w250 input-with-select",
              attrs: { clearable: "", placeholder: "输入门店名称或地址" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.queryList.apply(null, arguments)
                },
              },
              model: {
                value: _vm.queryPara.cname,
                callback: function ($$v) {
                  _vm.$set(_vm.queryPara, "cname", $$v)
                },
                expression: "queryPara.cname",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.queryList },
                slot: "append",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btns", staticStyle: { float: "right" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { slot: "btns", size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadTemp()
                    },
                  },
                  slot: "btns",
                },
                [_vm._v("下载模板")]
              ),
              _c("input", {
                ref: "upload",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: ".xlsx,.xls" },
                on: { change: _vm.onImportExcel },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { slot: "btns", size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.excel_file()
                    },
                  },
                  slot: "btns",
                },
                [_vm._v("导入")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { slot: "btns", size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.storeAddAndSet()
                    },
                  },
                  slot: "btns",
                },
                [_vm._v("新增门店")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "list",
          attrs: {
            data: _vm.tableData,
            border: "",
            "element-loading-text": "加载中，请稍等...",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "index",
              label: "序号",
              index: _vm.indexMethod,
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "left",
              "header-align": "center",
              label: "门店名称",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "paddingLeft10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "cornflowerblue pointer",
                          attrs: { slot: "reference" },
                          on: {
                            click: function ($event) {
                              return _vm.storeInfo(scope.row.sid)
                            },
                          },
                          slot: "reference",
                        },
                        [_vm._v(" " + _vm._s(scope.row.cname) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "fs12 lightgray",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.clocation) +
                              "（" +
                              _vm._s(scope.row.caddr) +
                              "）"
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "类型", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.cstoreType === 1
                            ? "无人仓"
                            : scope.row.cstoreType === 2
                            ? "无人柜"
                            : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "cmanagerName",
              label: "管理员",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "cmanagerPhone",
              label: "手机号",
              width: "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cornflowerblue pointer",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [_vm._v(" " + _vm._s(scope.row.cmanagerPhone) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "address",
              label: "操作",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.storeAddAndSet(scope.row.sid)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_c("span", { staticClass: "red" }, [_vm._v("删除")])]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.queryPara.total != 0
        ? _c(
            "div",
            { staticClass: "h50" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryPara.currentPage,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryPara.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.queryPara.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }