<template>
  <!-- 门店管理-->
  <div class="storeGoods">
    <div class="storeLocation__left">
      <el-input v-model="filterText" placeholder="输入门店名称查询" class="wHP padding10"></el-input>
      <div class="flex1 scrollbar">
        <el-tree ref="tree" class="filter-tree" :data="storeList" :props="defaultProps2" default-expand-all :filter-node-method="filterNode" @node-click="handleNodeClick"></el-tree>
      </div>
    </div>
    <div class="storeGoods__right">
      <div class="box-shadow bcWhite">
        <storeBrief :store-id="nodeStoreId === '' ? storeId : nodeStoreId" />
      </div>
      <div class="body box-shadow marginTop10">
        <div class="">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="出库列表" name="3" class="padding10 bcWhite"><inventoryOrderOut ref="inventoryOrderOut" /></el-tab-pane>
            <el-tab-pane label="货品列表" name="0" class="padding10 bcWhite"><goodsInventory ref="goodsInventory"/></el-tab-pane>
            <el-tab-pane label="货品库位" name="10" class="padding10 bcWhite"><goodsWarehourse ref="goodsWarehourse"/></el-tab-pane>
            <el-tab-pane label="临期列表" disabled name="1" />
            <el-tab-pane label="库存预警" disabled name="2" />
            <el-tab-pane label="入库列表" name="4" class="padding10 bcWhite"><inventoryOrderIn ref="inventoryOrderIn" /></el-tab-pane>
            <el-tab-pane label="调拔列表" disabled name="5" />
            <el-tab-pane label="盘点列表" name="6" class="padding10 bcWhite"><inventorySheet ref="inventorySheet" /></el-tab-pane>
            <el-tab-pane label="备货列表" disabled name="7" />
            <el-tab-pane label="补货列表" disabled name="8" />
          </el-tabs>
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import storeBrief from "@/views/store/storeBrief.vue";
import inventoryOrderIn from "@/views/store/inventoryOrder/inventoryOrderIn.vue";
import inventoryOrderOut from "@/views/store/inventoryOrder/inventoryOrderOut.vue";
import inventorySheet from "@/views/store/inventorySheet/inventorySheet.vue";
import goodsWarehourse from "@/views/store/goodsInventory/goodsWarehourse.vue";
import goodsInventory from "@/views/store/goodsInventory/goodsInventory.vue";
export default {
  name: "StorageGoods",
  components: {
    storeBrief,
    inventoryOrderIn,
    inventoryOrderOut,
    inventorySheet,
    goodsWarehourse,
    goodsInventory
  },
  props: {
    storeId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "1", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      nodeStoreId: "",
      tabs: "1",
      filterText: "",
      storeList: [],
      defaultProps2: {
        children: "children",
        label: "cname",
      },
      activeName: "3",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.queryInventoryOutList();
    this.selectStoreList();
  },
  methods: {
    selectStoreList() {
      let url = `${this.baseUrls.store}/queryList`;
      let params = {};
      this.topsAjax.get(url, params).then((res) => {
        this.storeList = res;
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.cname.indexOf(value) !== -1;
    },
    handleNodeClick(node) {
      this.nodeStoreId = node.sid;
      let tab = {};
      tab.name = this.activeName;
      this.handleClick(tab);
    },
    handleClick(tab) {
      let name = tab.name;
      if (name === "0") {
        this.queryGoodsInventory();
      } else if (name === "10") {
        this.queryPageStoreGoods();
      } else if (name === "1") {
      } else if (name === "2") {
      } else if (name === "3") {
        this.queryInventoryOutList();
      } else if (name === "4") {
        this.radio1 = "入库单";
        this.queryInventoryInList();
      } else if (name === "5") {
      } else if (name === "6") {
        this.radio2 = "盘点单";
        this.queryPageByStoreId();
      } else if (name === "7") {
      } else if (name === "8") {
      }
    },
    queryGoodsInventory() {
      this.$refs.goodsInventory.activeAndSearchTabs(this.nodeStoreId === "" ? this.storeId : this.nodeStoreId);
    },
    /**
     * 获取库存信息
     */
     queryPageStoreGoods() {
      this.$refs.goodsWarehourse.activeAndSearchTabs(this.nodeStoreId === "" ? this.storeId : this.nodeStoreId);
    },
    /**
     * 获取盘点单
     */
    queryPageByStoreId() {
      this.$refs.inventorySheet.activeAndSearchTabs(this.nodeStoreId === "" ? this.storeId : this.nodeStoreId);
    },
    /**
     * 获取入库单
     */
    queryInventoryInList() {
      this.$refs.inventoryOrderIn.activeAndSearchTabs(this.nodeStoreId === "" ? this.storeId : this.nodeStoreId);
    },
    /**
     * 获取出库单
     */
    queryInventoryOutList() {
      this.$refs.inventoryOrderOut.activeAndSearchTabs(this.nodeStoreId === "" ? this.storeId : this.nodeStoreId);
    }
  },
};
</script>
<style lang="scss" scoped>
.el-table {
  overflow: visible !important;
}
.storeLocation {
  height: 100%;
  display: flex;
  flex-direction: row;

  &__left {
    margin-left: 3px;
    margin-right: 15px;
    width: 15%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 1px 1px 5px #888888;
    z-index: 100;
    position: relative;
  }
}

.red-text {
  color: red;
}

.green-text {
  color: #06de06;
}

.storeGoods {
  height: 100%;
  display: flex;
  flex-direction: row;

  &__left {
    margin-left: 3px;
    margin-right: 15px;
    width: 15%;
    display: block;
    background-color: white;
  }

  &__right {
    margin: 3px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .top {
      background-color: white;
      margin: 3px;
      margin-bottom: 15px;
    }

    .body {
      background-color: white;
      margin: 3px;
      flex: 1;
    }
  }
}
</style>

<style lang="scss" scoped>
.el-tabs__item.is-active {
  background-color: white;
}

.storeGoodsButtonGroup {
  .el-radio-button__inner {
    width: 55px;
  }
}
</style>
