<template>
  <!--  入库审核-->
  <div>
    <div class="flex" style="justify-content: space-between">
      <el-input v-model="inSearch" clearable placeholder="入库单号/订单号/备注" class="w300" @keyup.enter.native="handleInventoryInChange(radio1)">
        <el-button slot="append" icon="el-icon-search" @click="handleInventoryInChange(radio1)"></el-button>
      </el-input>
      <div class="flex">
        <el-button type="primary" @click="importInventory">期初导入</el-button>&nbsp;
        <el-button type="primary" @click="handleExport">导出</el-button>&nbsp;
        <el-select v-model="orderType" placeholder="入库制单" style="width: 120px" @change="orderTypeChange">
          <el-option label="期初入库" value="10"></el-option>
          <el-option label="补货入库" value="20"></el-option>
          <el-option label="调拨入库" value="40"></el-option>
          <el-option label="盘盈入库" value="50"></el-option>
          <el-option label="其他入库" value="00"></el-option>
        </el-select>
        <el-radio-group v-model="radio1" class="marginLeft10" @change="handleInventoryInChange">
          <el-tooltip class="item" effect="dark" content="入库单" placement="top-end"
            ><el-radio-button label="入库单"><i class="el-icon-s-unfold"></i></el-radio-button
          ></el-tooltip>
          <el-tooltip class="item" effect="dark" content="入库商品" placement="top-end"
            ><el-radio-button label="入库商品"><i class="el-icon-menu"></i></el-radio-button
          ></el-tooltip>
        </el-radio-group>
      </div>
    </div>
    <el-table v-if="radio1 === '入库单'" class="marginTop10" height="450" :data="inventoryInList" :row-class-name="tableRowClassName">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" type="index" label="序号" width="50" />
      <el-table-column align="center" prop="csys" label="入库单号" width="170" />
      <el-table-column align="center" label="入库类型">
        <template slot-scope="scope">
          <div>{{ scope.row.ptype | InventoryOrderInType }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.paudit === 0" style="color: grey">{{ scope.row.paudit | orderAudit }}</span>
          <span v-else-if="scope.row.paudit === 1" style="color: #409eff">{{ scope.row.paudit | orderAudit }}</span>
          <span v-else-if="scope.row.paudit === 2" style="color: #409eff">{{ scope.row.paudit | orderAudit }}</span>
          <span v-else-if="scope.row.paudit === 3" style="color: green">{{ scope.row.paudit | orderAudit }}</span>
          <span v-else-if="scope.row.paudit === 4 || scope.row.paudit === 5" style="color: red">{{ scope.row.paudit | orderAudit }}</span>
          <span v-else>{{ scope.row.paudit | orderAudit }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作人" prop="screate" width="80" />
      <el-table-column align="center" label="审核人" prop="saudit" width="80">
        <template slot-scope="scope">
          {{ scope.row.saudit ? scope.row.saudit : "-" }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="cremark" :show-overflow-tooltip="true" width="200">
        <template slot-scope="scope">
          {{ scope.row.cremark ? scope.row.cremark : "-" }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新时间" width="130">
        <template slot-scope="scope">
          <div>{{ scope.row.tin | time }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.paudit === 0" type="text" size="mini" @click="updateinventoryOrderIn(scope.row)">修改</el-button>
          <el-button v-if="scope.row.paudit === 0" style="color: red" type="text" size="mini" @click="deleteInventoryOrderIn(scope.row.sid)">删除</el-button>
          <!-- <el-button v-if="scope.row.paudit === 1" type="text" size="mini" @click="inventoryInInfo(scope.row)">详情</el-button> -->
          <el-button v-if="scope.row.paudit === 1 || scope.row.paudit === 2" type="text" size="mini" @click="reviewInventoryIn(scope.row)">审核</el-button>
          <el-button v-if="scope.row.paudit === 1" type="text" size="mini" @click="inventoryInBack(scope.row.sid)">撤回</el-button>
          <el-button v-if="scope.row.paudit === 3 || scope.row.paudit === 4" type="text" size="mini" @click="inventoryInInfo(scope.row)">详情</el-button>
          <!-- <el-button v-if="scope.row.paudit === 3" style="color: red" type="text" size="mini" @click="inventoryInCh(scope.row.sid)">冲红</el-button> -->
          <el-button v-if="scope.row.paudit === 5" type="text" size="mini" @click="inventoryInDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="radio1 === '入库单'" class="block">
      <el-pagination :current-page="queryParams.currentPage" :page-size="queryParams.pageSize" :page-sizes="pageSizes" :total="total" layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
    <el-table v-if="radio1 === '入库商品'" class="marginTop10" height="450" :data="inventoryInDetailList" :row-class-name="tableRowClassName">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" type="index" label="序号" width="50" />
      <el-table-column align="center" label="入库单号" prop="c_sys" />
      <el-table-column align="center" label="SKU" prop="c_barcode" width="100" />
      <el-table-column align="center" label="图片" width="70">
        <template #default="scope">
          <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="货品条码" prop="c_sku" />
      <el-table-column align="center" label="单位" prop="s_unit" width="100" />
      <el-table-column align="center" label="库位号" prop="z_inventory" width="100" />
      <!-- <el-table-column align="center" label="库存数" prop="n_surplus" width="100" /> -->
      <el-table-column align="center" label="入库数" prop="n_out" width="100">
        <template #default="scope">
          {{ scope.row.rinventoryOrder ? -scope.row.n_out : scope.row.n_out }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库时间">
        <template slot-scope="scope">
          <div>{{ scope.row.t_create | time }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="radio1 === '入库商品'" class="block">
      <el-pagination :current-page="queryParams3.currentPage" :page-size="queryParams3.pageSize" :page-sizes="pageSizes" :total="total3" layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange3" @size-change="handleSizeChange3" />
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" :visible.sync="dialogData.inventoryInInfoDialogVisible" width="800px" append-to-body>
      <template slot="title">
        <span v-if="checkInventoryIn.rinventoryOrder" class="el-dialog__title red">入库红单详情</span>
        <span v-else class="el-dialog__title">入库详情</span>
      </template>
      <inventoryInInfo v-if="dialogData.inventoryInInfoDialogVisible" :inventory-in="checkInventoryIn" @handleClose="handleClose" />
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="入库单查看" :visible.sync="dialogData.inventoryInDetailDialogVisible" width="800px" append-to-body>
      <inventoryInDetail v-if="dialogData.inventoryInDetailDialogVisible" :inventory-in="checkInventoryIn" @handleClose="handleClose" />
    </el-dialog>
    <addInventoryIn ref="addInventoryIn" @handleClose="inventoryInCancel" />

    <!--货品导入 -->

    <implInventory ref="implInventory" @handleClose="inventoryInCancel" />

    <el-dialog class="elDialog" :close-on-click-modal="false" title="入库审核" :visible.sync="dialogData.reviewInventoryInDialogVisible" width="800px" append-to-body>
      <reviewInventoryIn v-if="dialogData.reviewInventoryInDialogVisible" :inventory-in="checkInventoryIn" @handleClose="inventoryInCancel" />
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { exportInventoryOrderIn, exportInventoryOrderInDetail } from "../../../api/goods/goods";
import { orderInType, orderAudit } from "@/utils/inventory";
import addInventoryIn from "@/views/store/inventoryOrder/addInventoryIn.vue";
import implInventory from "@/views/store/inventoryOrder/excelDialog/implInventory.vue";
import inventoryInInfo from "@/views/store/inventoryOrder/inventoryInInfo.vue";
import inventoryInDetail from "@/views/store/inventoryOrder/inventoryInDetail.vue";
import reviewInventoryIn from "@/views/store/inventoryOrder/reviewInventoryIn.vue";
export default {
  name: "InventoryOrderIn",
  components: {
    addInventoryIn,
    implInventory,
    inventoryInInfo,
    inventoryInDetail,
    reviewInventoryIn,
  },
  filters: {
    time(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    InventoryOrderInType(value) {
      return orderInType(value);
    },
    orderAudit(value) {
      return orderAudit(value);
    },
  },
  data() {
    return {
      inventoryInList: [],
      inventoryInDetailList: [],
      checkInventoryIn: [],
      queryParams: {
        currentPage: 1,
        pageSize: 10,
      },
      queryParams3: {
        currentPage: 1,
        pageSize: 10,
      },
      pageSizes: [10, 20, 30, 50, 100],
      total: 0,
      total3: 0,
      dialogData: {
        addInventoryInDialogVisible: false,
        implInventoryDialogVisible: false,
        inventoryInInfoDialogVisible: false,
        inventoryInDetailDialogVisible: false,
        reviewInventoryInDialogVisible: false,
      },
      radio1: "入库单",
      inSearch: "",
      nodeStoreId: "",
      storeId: "",
      orderType: "",
    };
  },
  methods: {
    handleExport() {
      if (this.radio1 === "出库单") {
        exportInventoryOrderIn({ storeId: this.storeId })
      } else {
        exportInventoryOrderInDetail({ storeId: this.storeId })
      }
    },
    tableRowClassName({ row }) {
      let classes = "";
      if (row.rinventoryOrder) {
        classes = "red";
      }
      if (row.paudit == 5 || row.paudit == 4) {
        classes += " discard-row3";
      }
      return classes;
    },
    updateinventoryOrderIn(row) {
      this.$refs.addInventoryIn.showDialog(this.nodeStoreId === "" ? this.storeId : this.nodeStoreId, row.ptype, row.sid, row.rinventoryOrder);
      this.dialogData.addInventoryInDialogVisible = true;
    },
    deleteInventoryOrderIn(sid) {
      this.$confirm("确定要删除该入库单？")
        .then(() => {
          let url = `${this.baseUrls.repInventoryOrderIn}/delete/status/${sid}`;
          this.topsAjax.post(url).then(() => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.queryInventoryInList();
          });
        })
        .catch(() => {});
    },
    orderTypeChange(val) {
      this.orderType = "";
      // if (val === "40") {
      //   alert('调拨入库功能开发中...')
      //   return
      // }
      this.$refs.addInventoryIn.showDialog(this.nodeStoreId === "" ? this.storeId : this.nodeStoreId, val, false);
      this.dialogData.addInventoryInDialogVisible = true;
    },
    activeAndSearchTabs(storeId) {
      this.nodeStoreId = storeId;
      this.storeId = storeId;
      this.queryInventoryInList();
    },
    queryInventoryInList() {
      this.inSearch = this.inSearch.trim();
      this.inventoryInList = [];
      let url = `${this.baseUrls.repInventoryOrderIn}/selectByStoreId`;
      let params = this.queryParams;
      params.storeId = this.storeId;
      params.search = this.inSearch;
      this.topsAjax.get(url, params).then((res) => {
        this.total = res.total;
        this.inventoryInList = res.list;
      });
    },
    inventoryInCancel() {
      this.dialogData.implInventoryDialogVisible = false;
      this.dialogData.reviewInventoryInDialogVisible = false;
      this.dialogData.inventoryInInfoDialogVisible = false;
      this.queryInventoryInList();
    },
    importInventory() {
      this.$refs.implInventory.showDialog(this.nodeStoreId === "" ? this.storeId : this.nodeStoreId);
    },
    handleInventoryInChange(val) {
      if (val === "入库单") {
        this.queryInventoryInList();
      } else {
        this.queryInventoryInDetailList();
      }
    },
    /**
     * 获取入库商品
     */
    queryInventoryInDetailList() {
      this.inSearch = this.inSearch.trim();
      this.inventoryInDetailList = [];
      let url = `${this.baseUrls.repInventoryOrderDetailIn}/queryByStoreId`;
      let params = this.queryParams3;
      params.search = this.inSearch;
      params.storeId = this.nodeStoreId === "" ? this.storeId : this.nodeStoreId;
      // params.search = this.search;
      this.topsAjax.get(url, params).then((res) => {
        this.total3 = res.total;
        this.inventoryInDetailList = res.list;
      });
    },
    developing() {
      alert("此功能待开发");
    },
    inventoryInBack(InventoryOrderInId) {
      let title = `确定要撤回该入库单？`;
      this.$IConfirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = `${this.baseUrls.repInventoryOrderIn}/back`;
          let params = { InventoryOrderInId: InventoryOrderInId };
          this.topsAjax.post(url, params).then((res) => {
            this.$message({
              showClose: true,
              message: "撤回成功",
              type: "success",
            });
            this.queryInventoryInList();
          });
        })
        .catch(() => {});
      // this.checkInventoryIn = value;
      // this.dialogData.inventoryInInfoDialogVisible = true;
    },
    handleClose() {
      this.dialogData.inventoryInInfoDialogVisible = false;
      this.dialogData.inventoryInDetailDialogVisible = false;
      this.handleInventoryInChange(this.radio1);
    },
    inventoryInInfo(value) {
      this.checkInventoryIn = value;
      this.dialogData.inventoryInInfoDialogVisible = true;
    },
    inventoryInDetail(value) {
      this.checkInventoryIn = value;
      this.dialogData.inventoryInDetailDialogVisible = true;
    },
    reviewInventoryIn(value) {
      let url = `${this.baseUrls.repInventoryOrderIn}/waitCheck`;
      let params = { InventoryOrderInId: value.sid };
      this.topsAjax.post(url, params).then((res) => {
        this.queryInventoryInList();
        this.checkInventoryIn = value;
        this.dialogData.reviewInventoryInDialogVisible = true;
      });
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      this.queryInventoryInList();
    },
    handleCurrentChange3(val) {
      this.queryParams3.currentPage = val;
      this.queryInventoryInDetailList();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryInventoryInList();
    },
    handleSizeChange3(val) {
      this.queryParams3.pageSize = val;
      this.queryInventoryInDetailList();
    },
    inventoryInCh(sid) {
      let title = `确定要冲红该入库单？`;
      this.$IConfirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let url = `${this.baseUrls.repInventoryOrderIn}/inventoryOrderInRed`;
        let params = { inventoryOrderInId: sid };
        this.topsAjax.post(url, params).then((res) => {
          this.queryInventoryInList();
        });
      });
    },
  },
};
</script>

<style lang="scss">
.el-table .warning-row3 {
  color: red;
}
.el-table .discard-row3 {
  text-decoration: line-through;
}
.el-radio-button__inner {
  width: 55px;
}
</style>
