<template>
  <!-- 入库制单-->
  <el-dialog class="elDialog" :close-on-click-modal="false" :title="title" :visible.sync="addInventoryInDialogVisible" width="800px" append-to-body>
    <div class="AddInventoryIn">
      <el-descriptions :column="3">
        <el-descriptions-item label="单号">
          <el-input v-model="cSys" placeholder="请输入内容" clearable style="width: 207px" />
        </el-descriptions-item>
        <el-descriptions-item label="入库类型">
          <el-select v-model="inventoryInType" placeholder="请选择">
            <el-option v-for="item in inventoryInTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="原始单号">
          <el-input v-model="originalOrder" placeholder="请输入内容" clearable>
            <i v-if="inventoryInType === '40'" slot="prefix" class="el-input__icon el-icon-search pointer" @click="selectAllotOrderOutNumber" />
          </el-input>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="2">
        <el-descriptions-item label="入库门店">{{ storeInfo.cname }}</el-descriptions-item>
        <el-descriptions-item label="入库时间">{{ deliveryNoteTime }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div v-if="inventoryInType === '40'" class="flex">
        <div class="wFP">
          <el-descriptions :column="1">
            <el-descriptions-item label="调出门店"> {{ checkAllotOrder.sstore }} </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="wFP AddInventoryIn__hideColon">
          <el-descriptions :column="1">
            <el-descriptions-item>
              <div class="wFP">调拔类型: {{ checkAllotOrder.pallotType | allotType }}</div>
              <div class="wFP">调拔价格: {{ checkAllotOrder.pallotPrice | allotPrice }}</div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <el-divider v-if="inventoryInType === '40'"></el-divider>
      <div class="flex">
        <el-descriptions :column="1">
          <el-descriptions-item label="单据备注">
            <el-input v-model="remarks" placeholder="请输入内容" style="width: 300px;"  clearable />
          </el-descriptions-item>
        </el-descriptions>
        <div v-if="inventoryInType !== '40'" class="w200 flex" style="justify-content: flex-end">
          <el-button v-if="!repInventoryOrderInId" type="primary" size="mini" plain @click="ImpInventoryOrderOut">导入</el-button>
          <el-button type="primary" size="mini" plain @click="showOrderGoodsAdd">{{ repInventoryOrderInId ? "重新选取" : "添加货品" }}</el-button>
          <el-button size="mini" @click="removeSelectedRows">移除</el-button>
        </div>
      </div>
      <el-divider></el-divider>
      <el-descriptions :column="1" :colon="false">
        <el-descriptions-item label="搜索">
          <el-input style="width: 210px;" placeholder="SKU/条码/货品名称" v-model="selectSearch" @input="handleInput"/>
        </el-descriptions-item>
      </el-descriptions>
      <div class="h450">
        <el-table ref="multipleTable" :data="tableData" max-height="400" @selection-change="handleSelectionChange" :header-row-class-name="isRed ? 'red' : ''" :row-class-name="isRed ? 'grey' : ''">
          <el-table-column type="selection" align="center" width="50" />
          <el-table-column label="序号" align="center" width="50" type="index" />
          <el-table-column label="SKU" align="center" width="70" prop="c_barcode" />
          <el-table-column label="图片" align="center" width="70">
            <template #default="scope">
              <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="货品编码" align="center" width="130" prop="c_sku" />
          <el-table-column label="货品名称" align="center" prop="c_name" />
          <el-table-column label="单位" align="center" width="50" />
          <el-table-column label="库位" align="center" width="70">
            <template #default="scope">
              <span v-if="scope.row.c_num === ''" style="color: red;">暂无库位</span>
              <span v-else>{{ scope.row.c_num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center" width="50" prop="num" >
            <template #default="scope">
              {{ isRed ? -scope.row.num : scope.row.num }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="货品批次" align="center" width="100" prop="todo" /> -->
          <el-table-column v-if="inventoryInType != '40'" align="center" prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleUpdate(scope.row)">修改</el-button>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
        <div>
          共计 <span class="blue">{{ tableData.length }}种</span> 货品
        </div>
        <div>
          <el-button plain @click="onSubmit(0)">保存</el-button>
          <el-button type="primary" plain @click="onSubmit(1)">提交</el-button>
        </div>
      </div>
      <implInventoryOut ref="implInventoryOut" @handleClose="handleClose" :title="'入库导入'"/>
      <inventOrderGoodsAdd ref="orderGoodsAdd" :goods="[]" :select-store="{ rstore: storeInfo.sid }" :max-num="false" @OrderGoodsAdd="AddGoods" />
    </div>
    <el-dialog v-if="selectAllotOrderOutNumberDialogVisible" class="elDialog" :close-on-click-modal="false" title="选择调拔出库单号" :visible.sync="selectAllotOrderOutNumberDialogVisible" width="360px" append-to-body>
      <div class="allotOrderOut">
        <el-input v-model="search" class="w300 marginLeftAuto" placeholder="查询关键字">
          <i slot="suffix" class="el-input__icon el-icon-search pointer"></i>
        </el-input>
        <el-table ref="allotOrderOutTable" :data="allotOrderOutTableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleAllotOrderOutChange" @current-change="handleCurrentChange">
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column label="序号" type="index" width="50" align="center" />
          <el-table-column label="盘单号" align="center" prop="csys" />
        </el-table>
        <el-row class="marginTop20 marginRight20" type="flex" justify="end">
          <el-button type="primary" @click="submit">确定</el-button>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog v-if="updateDialogVisible" class="elDialog" :close-on-click-modal="false" title="入库信息修改" :visible.sync="updateDialogVisible" width="360px" append-to-body>
      <el-image :src="updateData.c_master_url" fit="fit" :preview-src-list="[updateData.c_master_url]" style="width: 200px; height: 200px; margin-left: 55px;" />
      <el-form ref="form" label-width="100px">
        <el-form-item>
          <template slot="label">
            <span style="color: #a8abb2">货品名称</span>
          </template>
          <span style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; line-height: 20px;">{{ updateData.c_name }}</span>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span style="color: #a8abb2">货品SKU</span>
          </template>
          <span style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; ">{{ updateData.c_barcode }}</span>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span style="color: #a8abb2">货品编码</span>
          </template>
          <span style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; ">{{ updateData.c_sku }}</span>
        </el-form-item>
        <el-form-item label="库位名称" prop="warehouse">
          <el-select v-if="updateData.warehouseId === undefined || updateData.warehouseId === '' || warehouseIdList.includes(updateData.warehouseId)" style="width: 210px;" v-model="updateData.warehouseId" filterable placeholder="请选择库位">
            <el-option v-for="item in warehouseList" :key="item.sid" :label="item.c_num" :value="item.sid" />
          </el-select>
          <el-input v-else v-model="updateData.c_num" style="width: 210px;"  readonly />
        </el-form-item>
        <el-form-item label="数量">
          <el-input style="width: 210px;" placeholder="请输入数量" v-model="updateData.num" />
        </el-form-item>
      </el-form>
      <el-row class="marginTop20 marginRight20" type="flex" justify="end">
        <el-button type="primary" @click="handleDoUpdate">确定</el-button>
      </el-row>
    </el-dialog>
  </el-dialog>
</template>
<script>
import moment from "moment";
import inventOrderGoodsAdd from "@/views/store/inventoryOrder/inventOrderGoodsAdd.vue";
import implInventoryOut from "@/views/store/inventoryOrder/excelDialog/implInventoryInOut.vue";
import { allotType, allotPrice } from "@/utils/inventory";
export default {
  name: "AddInventoryIn",
  components: { inventOrderGoodsAdd, implInventoryOut },
  filters: {
    allotType(value) {
      return allotType(value);
    },
    allotPrice(value) {
      return allotPrice(value);
    },
  },
  data() {
    return {
      title: "入库制单",
      storeId: null,
      addInventoryInDialogVisible: false,
      updateDialogVisible: false,
      dialogVisible: false,
      // 门店信息
      storeInfo: {
        cname: "",
        sid: "",
      },
      // 入库单类型
      inventoryInType: "",
      isRed: false,
      // 00:其他入库,10:期初入库,20:补货入库,30:退货入库,40:调拔入库,50:盘盈入库
      inventoryInTypeOptions: [
        { value: "10", label: "期初入库" },
        { value: "20", label: "补货入库" },
        { value: "30", label: "退货入库" },
        { value: "40", label: "调拔入库" },
        { value: "50", label: "盘盈入库" },
        { value: "00", label: "其他入库" },
      ],
      // 原始单号
      originalOrder: "",
      //入库时间
      deliveryNoteTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      //单据备注
      remarks: "",
      tableData: [],
      selectedRows: [],
      cSys: "",
      repInventoryOrderInId: null,
      repInventoryOrderIn: null,
      // 选择调拨出库单号
      selectAllotOrderOutNumberDialogVisible: false,
      search: "",
      allotOrderOutTableData: [],
      multipleSelection: [],
      checkAllotOrder: {},
      updateData: {},
      warehouseList: [],
      warehouseIdList: [],
      totalTableData: [],
      selectSearch: ''
    };
  },
  methods: {
    handleInput() {
      let nowIndex = 0
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].c_barcode.indexOf(this.selectSearch) !== -1 || this.tableData[i].c_sku.indexOf(this.selectSearch) !== -1 || this.tableData[i].c_name.indexOf(this.selectSearch) !== -1) {
          nowIndex = i;
          break
        }
      }
      const TableRows = this.$refs.multipleTable.bodyWrapper.querySelectorAll('.el-table__body tbody .el-table__row')
                let scrollTop = 0
                for (let i = 0; i < TableRows.length; i++) {
                    if (i >= nowIndex) {
                        break
                    }
                    scrollTop += TableRows [i].offsetHeight
                }
                this.$refs.multipleTable.bodyWrapper.scrollTop = scrollTop
    },
    handleDoUpdate() {
      this.updateDialogVisible = false
      for (let i = 0; i < this.warehouseList.length; i++) {
        if (this.updateData.warehouseId === this.warehouseList[i].sid) {
          this.updateData.c_num = this.warehouseList[i].c_num;
          break;
        }
      }
      
    },
    handleUpdate(row) {
      this.updateData = row
      this.updateDialogVisible = true
    },
    submit() {
      this.selectAllotOrderOutNumberDialogVisible = false;
      this.originalOrder = this.multipleSelection.csys;
      this.checkAllotOrder = this.multipleSelection;
      this.selectByOutId(this.multipleSelection.sid);
    },
    ImpInventoryOrderOut() {
      this.$refs.implInventoryOut.showDialog(this.storeId);
    },
    selectByOutId(id) {
      let url = `${this.baseUrls.repInventoryOrderDetailOut}/selectByOutIdForIn`;
      this.topsAjax.get(url, { outId: id, storeId: this.storeId }).then((res) => {
        this.tableData = res;
      });
    },
    selectAllotOrderOut() {
      let url = `${this.baseUrls.repInventoryOrderOut}/selectAllotOrderOut`;
      let params = {
        storeId: this.storeInfo.sid,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.allotOrderOutTableData = res;
      });
    },
    // 选择调拨出库单
    selectAllotOrderOutNumber() {
      this.selectAllotOrderOutNumberDialogVisible = true;
      this.selectAllotOrderOut();
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.allotOrderOutTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.allotOrderOutTable.clearSelection();
      }
    },
    handleAllotOrderOutChange(val) {
      if (val) {
        if (val.length > 1) {
          this.$refs.allotOrderOutTable.clearSelection();
          this.$refs.allotOrderOutTable.toggleRowSelection(val.pop());
        }
        this.multipleSelection = val[0];
      } else {
        this.$refs.allotOrderOutTable.clearSelection();
        this.multipleSelection = null;
      }
    },
    handleCurrentChange(val) {
      if (val) {
        this.$refs.allotOrderOutTable.clearSelection();
        this.$refs.allotOrderOutTable.toggleRowSelection(val);
        this.multipleSelection = val;
      } else {
        this.$refs.allotOrderOutTable.clearSelection();
        this.multipleSelection = null;
      }
    },
    // --------------------------------------------------------------------------------------------------------------------------------
    showOrderGoodsAdd() {
      let arr = [];
      for (let i = 0; i < this.tableData.length; i++) {
        arr.push({ ...this.tableData[i] });
      }
      this.$refs.orderGoodsAdd.setTableData(arr);
    },
    showDialog(storeId, inType, repInventoryOrderInId, isRed) {
      this.tableData = [];
      this.storeId = storeId;
      this.inventoryInType = inType;
      this.isRed = isRed
      this.addInventoryInDialogVisible = true;
      if (inType === "40") this.title = "入库单（调拔）制单";

      this.getStoreInfo(this.storeId);

      this.repInventoryOrderInId = repInventoryOrderInId;
      if (repInventoryOrderInId) {
        let url = `${this.baseUrls.repInventoryOrderIn}/${repInventoryOrderInId}`;
        this.topsAjax.get(url).then((response) => {
          this.repInventoryOrderIn = response;
          this.cSys = this.repInventoryOrderIn.csys;
          this.originalOrder = this.repInventoryOrderIn.ccode;
          this.remarks = this.repInventoryOrderIn.cremark;
        });
        let goodsUrl = `${this.baseUrls.repInventoryOrderIn}/queryPageStoreGoods`;
        this.topsAjax.get(goodsUrl, { repInventoryOrderInId: repInventoryOrderInId }).then((response) => {
          this.tableData = response;

          console.log(this.tableData);
        });
      } else {
        this.originalOrder = "";
        this.remarks = "";
        let url = `${this.baseUrls.repInventoryOrderIn}/systemNum`;
        this.topsAjax
          .post(url, {
            rstore: storeId,
            ptype: inType,
          })
          .then((response) => {
            if (response) {
              this.cSys = response;
            }
          });
      }
      this.selectEmptyWarehouse()
    },
    /**
     * 确定
     */
    onSubmit(pAudit) {
      const warehouseIdSet = new Set();
      for (let i = 0; i < this.tableData.length; i++) {
        // if (this.tableData[i].warehouseId === undefined || this.tableData[i].warehouseId === '') {
        //   this.$message.error("请为" + this.tableData[i].c_barcode + "选择库位");
        //   return;
        // }
        if (warehouseIdSet.has(this.tableData[i].c_num)) {
          this.$message.error("重复库位" + this.tableData[i].c_num);
          return;
        }
        warehouseIdSet.add(this.tableData[i].c_num);
      }
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].num <= 0) {
          this.$message.error("SKU[" + this.tableData[i].c_barcode + "]数量必须大于0");
          return;
        }
      }
      if (this.inventoryInType === "" || this.inventoryInType === null) {
        this.$message.error("请选择入库单类型!");
        return;
      } else if (!(this.tableData.length > 0)) {
        this.$message.error("请选择入库货品!");
        return;
      } else {
        let url;
        if (this.repInventoryOrderInId) {
          url = `${this.baseUrls.repInventoryOrderIn}/updateOrderDetailIn`;
        } else {
          url = `${this.baseUrls.repInventoryOrderIn}/add`;
        }
        let parameter = {
          repInventoryOrderInId: this.repInventoryOrderInId,
          repInventoryOrderIn: {
            rstore: this.storeInfo.sid,
            sstore: this.storeInfo.cname,
            ccode: this.originalOrder,
            ptype: this.inventoryInType,
            cremark: this.remarks,
            tin: moment(this.deliveryNoteTime).format("YYYY-MM-DDTHH:mm:ss"),
            paudit: pAudit,
          },
          goodsList: this.tableData,
        };
        if (this.inventoryInType === "40") {
          parameter.repInventoryOrderIn.rallot = this.checkAllotOrder.sid;
          parameter.repInventoryOrderIn.pallotType = this.checkAllotOrder.pallotType;
          parameter.repInventoryOrderIn.pallotPriceType = this.checkAllotOrder.pallotPrice;
          parameter.repInventoryOrderIn.rallotStore = this.checkAllotOrder.rstore;
          parameter.repInventoryOrderIn.sallotStore = this.checkAllotOrder.sstore;
        }
        this.topsAjax.post(url, parameter).then((response) => {
          if (response) {
            this.$message({
              showClose: true,
              message: "新增成功",
              type: "success",
            });
            this.addInventoryInDialogVisible = false;
            this.$emit("handleClose");
          }
        });
      }
    },
    /**
     * 取消
     */
    cancel() {
      this.$emit("handleClose");
    },
    /**
     * 移除
     */
    removeSelectedRows() {
      if (this.selectedRows.length == 0) {
        this.$message({
              message: "请勾选需要移除的记录",
              type: "info",
            });
            return
      }
      this.tableData = this.tableData.filter((row) => !this.selectedRows.includes(row));
      //this.tableData = [];
      // this.$refs.multipleTable.clearSelection();
    },
    AddGoods(goodsList) {
      this.dialogVisible = false;
      if (goodsList.length > 0) this.tableData = goodsList;
      console.log(this.tableData);
    },
    handleSelectionChange(selection) {
      this.selectedRows = selection;
    },
    handleClose(goodsList) {
      this.tableData = goodsList
    },
    getStoreInfo(id) {
      let url = `${this.baseUrls.store}/get/` + id;
      this.topsAjax.get(url).then((res) => {
        this.storeInfo = res;
      });
    },
    selectEmptyWarehouse() {
      this.topsAjax.get(`${this.baseUrls.warehouse}/selectEmptyWarehouse`, { storeId: this.storeId }).then((res) => {
        this.warehouseList = res;
        this.warehouseIdList = []
        for (let i = 0; i < this.warehouseList.length; i++) {
          this.warehouseIdList.push(this.warehouseList[i].sid)
        }
      });
    }
  },
};
</script>
<style lang="scss">
.allotOrderOut {
  .el-table__header-wrapper .el-checkbox {
    display: none !important;
  }
}
.AddInventoryIn {
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .el-divider--horizontal {
    margin: 8px 0;
  }

  .el-input--medium .el-input__inner {
    height: 20px;
    line-height: 20px;
  }

  .el-input__inner {
    border: 0px;
  }

  &__hideColon {
    .el-descriptions-item__label:not(.is-bordered-label) {
      display: none;
    }
  }

  .el-descriptions__body {
    margin-left: 0px;
  }

  .el-input__icon {
    display: flex !important;
    align-items: center !important;
  }
}
</style>
