var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "goodsAdd__top",
          staticStyle: {
            "background-color": "#f7f6f6",
            "font-weight": "800",
            "line-height": "27px",
          },
        },
        [_vm._v("  " + _vm._s(_vm.storeData.cname) + " ")]
      ),
      _c("br"),
      _c("el-image", {
        staticStyle: { width: "200px", height: "200px", "margin-left": "55px" },
        attrs: {
          src: _vm.data.c_master_url,
          fit: "fit",
          "preview-src-list": [_vm.data.c_master_url],
        },
      }),
      _c(
        "div",
        { staticClass: "goodsAdd__body" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.data,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("span", { staticStyle: { color: "#a8abb2" } }, [
                      _vm._v("货品名称"),
                    ]),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        "line-clamp": "2",
                        "-webkit-line-clamp": "2",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                        "line-height": "20px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.data.cname))]
                  ),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "货品sku", prop: "sku" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { filterable: "", placeholder: "选择SKU" },
                      on: { change: _vm.onChangeSKU },
                      model: {
                        value: _vm.data.goodsId,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "goodsId", $$v)
                        },
                        expression: "data.goodsId",
                      },
                    },
                    _vm._l(_vm.goodsList, function (item) {
                      return _c("el-option", {
                        key: item.sid,
                        attrs: { label: item.cbarcode, value: item.sid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("span", { staticStyle: { color: "#a8abb2" } }, [
                      _vm._v("货品编码"),
                    ]),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        "line-clamp": "2",
                        "-webkit-line-clamp": "2",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      },
                    },
                    [_vm._v(_vm._s(_vm.data.csku))]
                  ),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "库位号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.data.c_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "c_num", $$v)
                      },
                      expression: "data.c_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上架批号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入上架批号" },
                    model: {
                      value: _vm.data.ph,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "ph", $$v)
                      },
                      expression: "data.ph",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上架库存" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入上架库存" },
                    model: {
                      value: _vm.data.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "num", $$v)
                      },
                      expression: "data.num",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "goodsAdd__bottom" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("提 交")]
          ),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("关 闭")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }