<template>
  <!-- 库位管理-->
  <div class="storeLocation">
    <div class="storeLocation__left">
      <el-input v-model="filterText" placeholder="输入门店名称查询" class="wHP padding10"></el-input>
      <div class="flex1 scrollbar">
        <el-tree ref="tree" class="filter-tree" :data="storeList" :props="defaultProps" default-expand-all :filter-node-method="filterNode" @node-click="handleNodeClick"></el-tree>
      </div>
    </div>
    <div class="storeLocation__right">
      <div class="box-shadow bcWhite">
        <storeBrief :store-id="queryPara.id" />
      </div>
      <div class="flex1 marginTop10 padding10 bcWhite border">
        <div class="borderBottom paddingBottom10 flex">
          <el-page-header @back="goBack" />
          <div class="fs20 fWB">库位管理</div>
        </div>
        <div class="wHP flex1">
          <div class="marginBottom10 marginTop10 search">
            <div class="">
              <el-input v-model="queryPara.search" clearable placeholder="请输入库位编号或库位名称" class="w300">
                <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
              </el-input>
            </div>
            <div class="btns">
              <el-button slot="btns" size="mini" style="margin-left: 10px" type="primary" @click="downloadTemp()">下载模板</el-button>
              <input ref="upload" type="file" style="display: none" accept=".xlsx,.xls" @change="onImportExcel" />
              <el-button slot="btns" size="mini" style="margin-left: 10px" type="primary" @click="excel_file()">导入</el-button>
              <el-button type="primary" size="mini" class="marginLeft10" @click="openDialog()">新增库位</el-button>
            </div>
          </div>
          <dc-datatable ref="list" :url="urlApi" :params="queryPara">
            <el-table-column align="center" type="index" label="序" width="100" />
            <el-table-column align="center" prop="cnum" label="库位编号" width="150" />
            <el-table-column align="center" prop="cname" label="库位名称" width="150" />
            <el-table-column align="center" prop="cremark" label="库位叙述" />
            <el-table-column align="center" prop="ntotal" label="库位容量" width="150" />
            <el-table-column align="center" prop="nindex" label="排序号" width="150" />
            <el-table-column align="center" label="状态" width="150">
              <template slot-scope="scope">
                {{ scope.row.bvalid === 0 ? "启用" : "停用" }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="操作" width="150">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="openDialog(scope.row.sid)">编辑</el-button>
                <el-button type="text" size="mini" @click="delById(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </dc-datatable>
        </div>
      </div>
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" :title="title" :visible.sync="addLocationOpen" width="30%" :before-close="handleClose" append-to-body>
      <addLocation v-if="addLocationOpen" ref="addLocationInfo" :store-id="queryPara.id" :warehouse-id="warehouseId" @handleClose="handleClose" />
    </el-dialog>
  </div>
</template>
<script>
import addLocation from "./addLocation";
import storeBrief from "@/views/store/storeBrief.vue";
import _ from "lodash";

export default {
  name: "StoreLocation",
  components: {
    addLocation,
    storeBrief,
  },
  props: {
    storeId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      title: "",
      addLocationOpen: false,
      warehouseId: "",
      urlApi: `${this.baseUrls.warehouse}/queryPageList`,
      queryPara: {
        id: null,
        search: null,
      },
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      //
      filterText: "",
      storeList: [],
      defaultProps: {
        children: "children",
        label: "cname",
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.queryPara.id = this.storeId;
    this.selectStoreList();
  },
  methods: {
    handleNodeClick(node) {
      this.queryPara.id = node.sid;
      this.queryPara.name = node.cname;
      this.doSearch();
    },
    selectStoreList() {
      let url = `${this.baseUrls.store}/queryList`;
      let params = {};
      this.topsAjax.get(url, params).then((res) => {
        this.storeList = res;
        this.storeList.forEach((p) => {
          if (p.sid === this.storeId) this.queryPara.name = p.cname;
        });
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.cname.indexOf(value) !== -1;
    },
    goBack() {
      this.$emit("storeLocation");
    },
    handleClose() {
      this.title = "";
      this.warehouseId = "";
      this.addLocationOpen = false;
      this.$refs.list.load();
    },
    openDialog(sid) {
      this.title = "新增库位";
      if (sid) {
        this.title = "编辑库位";
        this.warehouseId = sid;
      }
      this.addLocationOpen = true;
    },
    doSearch: _.debounce(function () {
      // 这里是你想要执行的代码
      this.$refs.list.load();
    }, 1000),
    delById(row) {
      this.$confirm('是否确认删除库位"' + row.cname + '"？')
        .then((_) => {
          this.topsAjax.post(`${this.baseUrls.warehouse}/deleteById`, { sid: row.sid }).then((response) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.$refs.list.load();
          });
        })
        .catch((_) => {});
    },
    //选择excel按钮
    excel_file() {
      this.$refs.upload.click();
    },
    //excel导入文件
    onImportExcel(e) {
      // 通过文件选择器选择文件
      this.$loading();
      let selectedFile = event.target.files;
      let url = `${this.baseUrls.inventory}/implStore`;
      let params = {
        file: selectedFile[0],
        storeId: this.queryPara.id,
        storeName: this.queryPara.name,
      };
      this.topsAjax.postForm(url, params).then((res) => {
        this.$loading().close();
        this.$message.success("导入成功！");
      });
      this.$refs.upload.value = null;
    },
    downloadTemp() {
      let url = `${this.baseUrls.inventory}/downloadTemp`;
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.storeLocation {
  height: 100%;
  display: flex;
  flex-direction: row;

  &__left {
    margin-left: 3px;
    margin-right: 15px;
    width: 15%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 1px 1px 5px #888888;
    z-index: 100;
    position: relative;
  }

  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .search {
    display: flex;
    justify-content: space-between;

    .btns {
      //display: flex;
      //flex-direction: row;
      height: 100%;
    }
  }
}
</style>
<style lang="scss">
.el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 5px;
}
</style>
