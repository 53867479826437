<template>
  <!-- 货品添加弹窗-->
  <div class="goodsAdd">
    <div class="goodsAdd__top fs17">门店名称: {{ storeName }}</div>
    <div class="goodsAdd__body">
      <el-table ref="product" :data="tableData" class="whp marginTop10 marginBottom10" row-key="id" stripe border highlight-current-row header-cell-class-name="table_header">
        <el-table-column align="center" label="SKU" width="150">
          <template #default="scope">
            <el-select v-model="scope.row.cbarcode" filterable placeholder="选择SKU" @change="onChangeSKU(scope.row, $event)">
              <el-option v-for="item in goodsList" :key="item.sid" :label="item.cbarcode" :value="item" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="cSku" label="条码" width="150"/>
        <el-table-column align="center" label="图片" width="70">
          <template #default="scope">
            <el-image v-if="scope.row.masterUrl != null" class="w50 h50" :src="scope.row.masterUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="sGoods" label="货品名称" :show-overflow-tooltip="true"/>
        <el-table-column align="center" label="库位" width="150">
          <template #default="scope">
            <el-select v-model="scope.row.cName" filterable placeholder="选择库位" @change="onChangeWarehouse(scope.row, $event)">
              <el-option v-for="item in warehouseList" :key="item.sid" :label="item.c_name" :value="item" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="数量" width="80">
          <template #default="scope">
            <el-input style="width: 70px;" v-model="scope.row.nSurplus" placeholder="数量" @input="handleInput(scope.row)"  />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template #default="scope">
            <i class="el-icon-delete pointer" @click="onDelete(scope.$index)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="goodsAdd__bottom">
      <!-- <el-button @click="cancel">取 消</el-button> -->
      <el-button type="primary" @click="onSubmit">提 交</el-button>
    </div>
  </div>
</template>

<script>
import { isImage } from "@/api/commons";

export default {
  name: "GoodsAdd",
  props: {
    storeData: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      storeName: "",
      tableData: [{}],
      // 商品列表
      goodsList: [],
      // 库位列表
      warehouseList: [],
      // 列表数据
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: ["https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg", "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"],
      input: "",
    };
  },
  mounted() {
    this.storeName = this.storeData.cname;
    console.log(this.storeData)
    this.selectGoodsSKU();
    this.selectEmptyWarehouse();
  },
  methods: {
    /**
     * 输入后新增
     * @param row
     */
    handleBlur(row) {
      let item = this.tableData[this.tableData.length - 1];
      if (Object.keys(item).length > 0) {
        this.tableData.push({});
      }
    },
    /**
     * 判断是否大于最大值
     * @param row
     */
    handleInput(row) {
      // if (parseFloat(row.num) > row.maxNum) {
      //   this.$alert(`数量不得大于 ${row.maxNum}`, "提示", {
      //     confirmButtonText: "确定",
      //   });
      //   row.num = row.maxNum;
      // }
      this.handleBlur()
    },
    /**
     * 刪除
     * @param index
     */
    onDelete(index) {
      if (this.tableData.length > 1) this.tableData.splice(index, 1);
    },
    /**
     * 取消
     */
    cancel() {
      this.$emit("handleClose");
    },
    /**
     * 提交
     */
    onSubmit() {
      let newArray = this.tableData.slice(0, -1);
      console.log(newArray)
      
      if (!(newArray.length > 0)) {
        this.$message.error("请填写对应信息");
        return;
      }
      if (!newArray.every((item) => item.rWarehouse !== null && item.rWarehouse !== "")) {
        this.$message.error("请选择库位");
        return;
      }
      if (!newArray.every((item) => item.rGoods !== null && item.rGoods !== "")) {
        this.$message.error("请选择商品");
        return;
      }
      const ids = this.tableData.map((item) => item.rWarehouse);
      if (ids.some((id, index) => ids.indexOf(id) !== index)) {
        this.$message({
          message: "货品上架库位重复",
          type: "warning",
        });
        return;
      }
      const cSku = this.tableData.map((item) => item.cSku);
      if (cSku.some((id, index) => cSku.indexOf(id) !== index)) {
        this.$message({
          message: "货品SKU重复",
          type: "warning",
        });
        return;
      }
      for (let i = 0; i < newArray.length; i++) {
        if (!newArray[i].nSurplus) {
          this.$message({
            message: "请填写数量",
            type: "warning",
          });
          return;
        }

        newArray[i].nSurplus = newArray[i].nSurplus.trim();

        if (newArray[i].nSurplus === '0') {
          this.$message({
            message: "数量必须大于0",
            type: "warning",
          });
          return;
        }

        const isInteger = /^[1-9]\d*$/.test(newArray[i].nSurplus);
        if (!isInteger) {
          this.$message({
            message: "非法数字[" + newArray[i].nSurplus + "]",
            type: "warning",
          });
          return;
        }


        newArray[i].cStore = this.storeData.cname;
        newArray[i].rStore = this.storeData.sid;
      }
      let parameters = []
      for (let i = 0; i < newArray.length; i++) {
        let json = {
          sid: newArray[i].rWarehouse,
          goodsId: newArray[i].rGoods,
          storeName: newArray[i].cStore,
          goodsName: newArray[i].sGoods,
          csku: newArray[i].cSku,
          cbarcode: newArray[i].cbarcode,
          num: newArray[i].nSurplus
        }
        parameters.push(json)
      }
      this.$confirm("确认提交？")
        .then((_) => {
          const url = `${this.baseUrls.repInventoryOrderIn}/batchAddInventory`;
          newArray.forEach((item) => {
            item.rstore = this.storeData.sid;
            item.cstore = this.storeData.cname;
          });
          let params = {
            parameters: parameters,
          };
          this.topsAjax.post(url, params).then((res) => {
            this.$message({
              message: "上架成功",
              type: "success",
            });
            this.cancel();
          });
        })
        .catch((_) => {});
    },
    /**
     * 选择SKU
     * @param row
     * @param selectedItem
     */
    onChangeSKU(row, selectedItem) {
      row.rGoods = selectedItem.sid;
      row.cSku = selectedItem.csku;
      row.cbarcode = selectedItem.cbarcode
      row.sGoods = selectedItem.cname;
      row.masterUrl = isImage(selectedItem.cmasterUrl);
      this.handleBlur(row)
    },
    /**
     * 选择库位
     * @param row
     * @param selectedItem
     */
    onChangeWarehouse(row, selectedItem) {
      row.cName = selectedItem.c_name;
      row.cNum = selectedItem.c_num;
      row.rWarehouse = selectedItem.sid;
      row.nTotal = selectedItem.n_total;
      this.handleBlur(row)
    },
    /**
     * 获取商品sku
     */
    selectGoodsSKU() {
      const url = `${this.baseUrls.goods}/store/selectGoodsSKU`;
      this.topsAjax.get(url, {storeId: this.storeId}).then((res) => {
        this.goodsList = res;
      });
    },
    /**
     * 获取未上架商品的库位
     */
    selectEmptyWarehouse() {
      this.topsAjax.get(`${this.baseUrls.warehouse}/selectEmptyWarehouse`, { storeId: this.storeData.sid }).then((res) => {
        this.warehouseList = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsAdd {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;

  &__top {
    height: 30px;
  }

  &__body {
    flex: 1;
  }

  &__bottom {
    height: 50px;
    line-height: 50px;
    text-align: right;
  }

  .el-input {
    width: 120px;
  }
}
</style>
