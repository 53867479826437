<template>
  <!-- 无人仓库位新增修改-->
  <div>
    <el-form ref="form" label-position="left" label-width="120px" :rules="rules" :model="form">
      <el-form-item label="设备厂商">
        <el-input v-model="form.cmanufacturer"></el-input>
      </el-form-item>
      <el-form-item label="设备编号" prop="ccode">
        <el-input v-model="form.ccode"></el-input>
      </el-form-item>
      <el-form-item label="设备名称">
        <el-input v-model="form.cname"></el-input>
      </el-form-item>
      <el-form-item label="设备类型" prop="btype">
        <el-select v-model="form.btype" placeholder="请选择设备类型">
          <el-option label="云主机" value="0" />
          <el-option label="4G集成主机" value="1" />
          <el-option label="网口集成主机" value="2" />
          <el-option label="灯光&4G双路物联主机" value="3" />
          <el-option label="视频监控" value="4" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.btype === '4'" label="设备密码" prop="cpwd">
        <el-input v-model="form.cpwd"></el-input>
      </el-form-item>
      <el-form-item label="启用状态">
        <el-switch v-model="form.bvalid"></el-switch>
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="btnLoading" type="primary" @click="onSubmit">提 交</el-button>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "AddDevice",
  props: {
    storeId: {
      type: String, // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
    deviceId: {
      type: String, // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      btnLoading: false,
      form: {
        sid: "",
        cname: "",
        ccode: "",
        btype: "",
        cpwd: "",
        bvalid: true,
        cmanufacturer: "",
      },
      rules: {
        ccode: [{ required: true, message: "请输入设备编号", trigger: "blur" }],
        btype: [{ required: true, message: "请选择设备类型", trigger: "blur" }],
        cpwd: [{ required: true, message: "请输入设备密码", trigger: "blur" }],
      },
    };
  },
  created() {
    if (this.deviceId) {
      this.topsAjax.get(`${this.baseUrls.device}/selectById`, { sid: this.deviceId }).then((response) => {
        this.form.sid = response.sid;
        this.form.ccode = response.ccode;
        this.form.cname = response.cname;
        this.form.btype = response.btype.toString();
        this.form.bvalid = response.bvalid === 0;
        this.form.cmanufacturer = response.cmanufacturer;
      });
    }
  },
  methods: {
    //提交
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm("确认提交？")
            .then((_) => {
              const data = JSON.parse(JSON.stringify(this.form));
              data.rstore = this.storeId;
              data.bvalid = data.bvalid === true ? 0 : 1;
              this.btnLoading = true;
              this.topsAjax.post(`${this.baseUrls.device}/saveOrUpdate`, data).then((response) => {
                this.btnLoading = false;
                this.$message({
                  message: data.sid ? "修改成功" : "新增成功",
                  type: "success",
                });
                this.cancel();
              });
            })
            .catch((_) => {});
        } else {
          return false;
        }
      });
    },
    // changeType() {
    //   if (this.form.btype === "4") {
    //     this.form.cname = "云主机";
    //   }
    // },
    //取消
    cancel() {
      this.$emit("handleClose");
    },
  },
};
</script>
