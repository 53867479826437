<template>
  <!-- 货品添加弹窗-->
  <div>
    <div class="goodsAdd__top" style="background-color: #f7f6f6;font-weight: 800;line-height: 27px;">&nbsp;&nbsp;{{ storeData.cname }} </div>
    <br>
    <el-image :src="data.c_master_url" fit="fit" :preview-src-list="[data.c_master_url]" style="width: 200px; height: 200px; margin-left: 55px;" />
    <div class="goodsAdd__body">
      <el-form ref="form" :model="data" label-width="80px" :rules="rules">
        <el-form-item>
          <template slot="label">
            <span style="color: #a8abb2">货品名称</span>
          </template>
          <span style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; line-height: 20px;">{{ data.cname }}</span>
        </el-form-item>
        <el-form-item label="货品sku" prop="sku">
          <el-select style="width: 200px;" v-model="data.goodsId" filterable placeholder="选择SKU" @change="onChangeSKU">
            <el-option v-for="item in goodsList" :key="item.sid" :label="item.cbarcode" :value="item.sid" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span style="color: #a8abb2">货品编码</span>
          </template>
          <span style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; ">{{ data.csku }}</span>
        </el-form-item>
        <el-form-item label="库位号">
          <el-input style="width: 200px;" v-model="data.c_num" readonly />
        </el-form-item>
        <el-form-item label="上架批号" >
          <el-input style="width: 210px;" placeholder="请输入上架批号" v-model="data.ph" />
        </el-form-item>
        <el-form-item label="上架库存">
          <el-input style="width: 210px;" placeholder="请输入上架库存" v-model="data.num" />
        </el-form-item>
      </el-form>
    </div>
    <div class="goodsAdd__bottom">
      <el-button type="primary" @click="onSubmit">提 交</el-button>
      <el-button @click="cancel">关 闭</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "GoodsAdd",
  props: {
    storeId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
    warehouseData: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    }
  },
  data() {
    return {
      data: {
        c_num: '',
        c_master_url: require("@/assets/noImage.png"),
      },
      // 商品列表
      goodsList: [],
      storeData: {
        caddr: ''
      },
      rules: {
        sku: [ { required: true, message: '请选择货品', trigger: 'blur' }]
      }
    };
  },
  mounted() {
    this.data.c_num = this.warehouseData.c_num
    this.data.warehouseId = this.warehouseData.warehouseId
    this.selectGoodsSKU();
    this.selectStoreById()
  },
  methods: {
    selectStoreById() {
      this.topsAjax.get(`${this.baseUrls.store}/selectById`, { sid: this.storeId }).then((response) => {
        this.storeData = response.store;
        this.storeData.regionName = response.regionName;
      })
    },
    /**
     * 取消
     */
    cancel() {
      this.$emit("handleClose");
    },
    /**
     * 提交
     */
    onSubmit() {
      if (!this.data.goodsId) {
        this.$message({
          message: "请选择商品",
          type: "info",
        });
        return
      }
      for (let i = 0; i < this.goodsList.length; i++) {
        if (this.data.goodsId === this.goodsList[i].sid) {
          this.data.sid = this.warehouseData.sid
          this.data.goodsName = this.goodsList[i].cname
          this.data.cbarcode = this.goodsList[i].cbarcode
          this.data.csku = this.goodsList[i].csku
          this.data.storeName = this.storeData.cname
          this.data.storeId = this.storeData.sid
          break;
        }
      }
      const url = `${this.baseUrls.repInventoryOrderIn}/goodsShelvesGoods`;
      this.topsAjax.post(url, this.data).then(() => {
        this.$message({
          message: "上架成功",
          type: "success",
        });
        this.cancel();
      });
    },
    /**
     * 选择SKU
     * @param row
     * @param selectedItem
     */
    onChangeSKU(val) {
      for (let i = 0; i < this.goodsList.length; i++) {
        if (this.goodsList[i].sid === val) {
          this.data.csku = this.goodsList[i].csku
          this.data.cname = this.goodsList[i].cname
          this.data.c_master_url = this.goodsList[i].cmasterUrl
          break;
        }
      }
    },
    /**
     * 获取商品sku
     */
    selectGoodsSKU() {
      const url = `${this.baseUrls.goods}/store/selectGoodsSKU`;
      this.topsAjax.get(url, {storeId: this.storeId}).then((res) => {
        this.goodsList = res;
      });
    }
  },
};
</script>

<style lang="scss">

</style>
