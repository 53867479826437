var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inventorySheetEdit a" },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c("el-descriptions-item", { attrs: { label: "盘点单号" } }, [
            _c("div", [_vm._v(_vm._s(_vm.csys))]),
          ]),
          _c("el-descriptions-item", { attrs: { label: "所在门店" } }, [
            _vm._v(" " + _vm._s(_vm.storeInfo.cname) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "盘点方式" } }, [
            _vm._v(
              " " + _vm._s(_vm.Way === "1" ? "自由盘点" : "指定盘点") + " "
            ),
          ]),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "盘点类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.Type,
                    callback: function ($$v) {
                      _vm.Type = $$v
                    },
                    expression: "Type",
                  },
                },
                _vm._l(_vm.inventorySheetTypeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "盘点周期" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.cycle,
                    callback: function ($$v) {
                      _vm.cycle = $$v
                    },
                    expression: "cycle",
                  },
                },
                _vm._l(_vm.inventorySheetCycleOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "盘点人" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.inventoryer,
                    callback: function ($$v) {
                      _vm.inventoryer = $$v
                    },
                    expression: "inventoryer",
                  },
                },
                _vm._l(_vm.inventoryerOptions, function (item) {
                  return _c("el-option", {
                    key: item.user_id,
                    attrs: { label: item.nick_name, value: item.user_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "单据备注" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", clearable: "" },
                    model: {
                      value: _vm.remarks,
                      callback: function ($$v) {
                        _vm.remarks = $$v
                      },
                      expression: "remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w200 flex",
              staticStyle: { "justify-content": "flex-end" },
            },
            [
              _vm.Way === "2"
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.addWarehouse },
                    },
                    [_vm._v("添加库位")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "h450" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: { data: _vm.tableData, "max-height": "450" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "50" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  width: "50",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "SKU",
                  align: "center",
                  width: "70",
                  prop: "c_remark",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "图片", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.c_master_url != null
                          ? _c("el-image", {
                              staticClass: "w50 h50",
                              attrs: {
                                "preview-src-list": [scope.row.c_master_url],
                                src: scope.row.c_master_url,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "货品编码",
                  align: "center",
                  width: "120",
                  prop: "c_sku",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "货品名称", align: "center", prop: "s_goods" },
              }),
              _c("el-table-column", {
                attrs: { label: "单位", align: "center", width: "70" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库位号",
                  align: "center",
                  width: "70",
                  prop: "c_num",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.c_num
                                ? scope.row.c_num
                                : scope.row.c_warehouse
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库存数",
                  align: "center",
                  width: "70",
                  prop: "n_surplus",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex alignItemsCenter",
          staticStyle: { "justify-content": "space-between", flex: "1" },
        },
        [
          _c("div"),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.doSubmit(1)
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.doSubmit(2)
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.addWarehouseDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "elDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "选择库位",
                width: "670px",
                visible: _vm.addWarehouseDialogVisible,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.addWarehouseDialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "wFP paddingRight10" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex marginBottom10 flexSpaceBetween" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择货层" },
                            on: { change: _vm.inventorySelectWarehouse },
                            model: {
                              value: _vm.shelves,
                              callback: function ($$v) {
                                _vm.shelves = $$v
                              },
                              expression: "shelves",
                            },
                          },
                          _vm._l(_vm.shelvesOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "marginLeft5",
                            attrs: { placeholder: "请选择货架" },
                            on: { change: _vm.inventorySelectWarehouse },
                            model: {
                              value: _vm.layer,
                              callback: function ($$v) {
                                _vm.layer = $$v
                              },
                              expression: "layer",
                            },
                          },
                          _vm._l(_vm.layerOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-input",
                      {
                        staticClass: "input-with-select marginBottom10",
                        attrs: {
                          clearable: "",
                          placeholder: "库位/SKU/编号/货品名称",
                        },
                        on: { change: _vm.inventorySelectWarehouse },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: { click: _vm.inventorySelectWarehouse },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "h400 scrollAuto cardHeader" },
                      _vm._l(_vm.warehouseList, function (warehouse) {
                        return _c(
                          "el-card",
                          {
                            key: warehouse.warehouseId,
                            staticClass: "box-card",
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleSelectOne(warehouse)
                                      },
                                    },
                                    model: {
                                      value: warehouse.checked,
                                      callback: function ($$v) {
                                        _vm.$set(warehouse, "checked", $$v)
                                      },
                                      expression: "warehouse.checked",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "fs12" }, [
                                      _vm._v(_vm._s(warehouse.c_num)),
                                    ]),
                                  ]
                                ),
                                _c("span", { staticClass: "fs12 black" }, [
                                  _vm._v(" SKU "),
                                  _c("span", { staticClass: "fs12 grey" }, [
                                    _vm._v(_vm._s(warehouse.c_remark)),
                                  ]),
                                ]),
                                _c("span", { staticClass: "fs12 black" }, [
                                  _vm._v(" 编码 "),
                                  _c("span", { staticClass: "fs12 grey" }, [
                                    _vm._v("6901028075862"),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "text item flex h50" }, [
                              _c(
                                "div",
                                { staticClass: "w75P paddingRight20 fs13" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        warehouse.s_goods
                                          ? warehouse.s_goods
                                          : "..."
                                      ) +
                                      " "
                                  ),
                                  _c("div", { staticClass: "fs12" }, [
                                    _vm._v(
                                      _vm._s(
                                        warehouse.c_remark
                                          ? warehouse.c_remark
                                          : "... "
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "flex1 fs30" }, [
                                _vm._v(
                                  _vm._s(
                                    warehouse.n_surplus
                                      ? warehouse.n_surplus
                                      : "NA"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "h20 marginTop10" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            on: { change: _vm.handleSelectAll },
                            model: {
                              value: _vm.checked,
                              callback: function ($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked",
                            },
                          },
                          [_vm._v("全选")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "wFP paddingLeft10 flex flexSpaceBetween directionColumn",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "scrollAuto border flexGrow1 borderRadius5 padding10 flex directionColumn",
                      },
                      [
                        _c("div", { staticClass: "fs12 marginBottom10" }, [
                          _vm._v("选中的库位"),
                        ]),
                        _c("div", { staticClass: "flex1" }, [
                          _c(
                            "div",
                            { staticClass: "h450 wHP scrollAuto" },
                            _vm._l(_vm.tags, function (tag) {
                              return _c(
                                "el-tag",
                                {
                                  key: tag.warehouseId,
                                  staticClass: "marginRight5 marginTop5",
                                  attrs: { closable: "", type: tag.type },
                                },
                                [_vm._v(" " + _vm._s(tag.c_num) + " ")]
                              )
                            }),
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "h20 marginTop10 fs12 black" }, [
                      _vm._v(" 共选择 "),
                      _c(
                        "span",
                        {
                          staticClass: "fs12",
                          staticStyle: { color: "#67c23a" },
                        },
                        [_vm._v(_vm._s(_vm.tags.length))]
                      ),
                      _vm._v(" 个库位, "),
                      _c(
                        "a",
                        {
                          staticClass: "blue fs12",
                          on: { click: _vm.handleClear },
                        },
                        [_vm._v("清除")]
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "end" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }