<template>
  <div class="storeInfo">
    <!-- 门店详情-->
    <div class="storeInfo__left">
      <div class="fs20 fWB borderBottom paddingBottom10 flex alignItemsCenter">
        <el-page-header @back="goBack" />
        <div>门店详情</div>
      </div>
      <div class="flex1 scrollbar padding10">
        <el-descriptions title="基本信息">
          <el-descriptions-item label="门店编号">{{ form.ccode }}</el-descriptions-item>
          <el-descriptions-item label="门店名称">{{ form.cname }}</el-descriptions-item>
          <el-descriptions-item label="门店类型">{{ form.cstoreType === "1" ? "无人仓" : form.cstoreType === "2" ? "无人柜" : "" }}</el-descriptions-item>
          <el-descriptions-item label="所属商户">{{ form.merchantName }}</el-descriptions-item>
          <el-descriptions-item label="管理员">{{ form.cmanagerName }}</el-descriptions-item>
          <el-descriptions-item label="手机号码">{{ form.cmanagerPhone }}</el-descriptions-item>
          <el-descriptions-item label="理货员">{{ form.ctallymanName }}</el-descriptions-item>
          <el-descriptions-item label="手机号码">{{ form.ctallymanPhone }}</el-descriptions-item>
          <el-descriptions-item label="启用日期">{{ form.tstartDate }}</el-descriptions-item>
        </el-descriptions>
        <div class="flex">
          <div class="wFP">
            <el-descriptions :column="1">
              <el-descriptions-item label="所在区域">{{ form.cregion }}</el-descriptions-item>
              <el-descriptions-item label="地址">{{ form.clocation }}</el-descriptions-item>
              <el-descriptions-item label="经纬度">{{ form.clatitude + "   " + form.clongitude }}</el-descriptions-item>
              <el-descriptions-item label="详细地址">{{ form.caddr }}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="wFP">
            <el-amap :center="center" :zoom="zoom" class="h250 wHP">
              <!-- 在地图上循环显示标记 -->
              <el-amap-marker v-for="(marker, index) in markers" :key="index" :position="marker.position" />
            </el-amap>
          </div>
        </div>
        <div class="marginTop10">
          <div class="fs16 fw700 marginBottom10">配送范围</div>
          <div class="h500 wHP">
            <el-amap :center="center" :zoom="zoom">
              <el-amap-circle :center="circleCenter" :radius="radius" :stroke-color="'#ADD8E6'" :fill-color="'#ADD8E6'" :stroke-opacity="1" :stroke-weight="3" :fill-opacity="0.35"></el-amap-circle>
            </el-amap>
          </div>
        </div>
        <div class="marginTop10">
          <div class="fs16 fw700">更多信息</div>
          <div class="padding10 flex alignItemsCenter">
            <div>营业时间 24小时营业</div>
            <div class="marginLeftAuto"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="storeInfo__right">
      <div class="paddingTop15 paddingBottom15 borderBottom textCenter marginBottom10">门店形象</div>
      <div class="flex1 scrollbar flex alignItemsCenter">
        <div v-for="(url, index) in urls" :key="index">
          <div class="w150 h150 padding10 border bcGrey marginBottom5">
            <el-image :key="url" class="hHP wHP" fit="fit" :src="url" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "StoreInfo",
  props: {
    infoId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      center: [116.397455, 39.909192], // 地图中心点的经纬度
      zoom: 15, // 地图的缩放级别
      markers: [{ position: [116.397455, 39.909192] }], // 存放标记的数组+
      circleCenter: [116.397455, 39.909192], // 圆形的中心点
      radius: 1000, // 圆形的半径，单位为米
      form: {
        ccode: "",
        cname: "",
        cstoreType: "",
        merchantName: "",
        cmanagerName: "",
        cmanagerPhone: "",
        ctallymanName: "",
        ctallymanPhone: "",
        tstartDate: "",
        cregion: "",
        clocation: "",
        clongitude: "",
        clatitude: "",
        caddr: "",
        cbusinessHours: "",
        cmerchantId: "",
      },
      labelPosition: "left",
      urls: ["https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg", "https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg", "https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg", "https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg", "https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg", "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg", "https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg"],
      srcList: ["https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"],
    };
  },
  created() {
    this.topsAjax.get(`${this.baseUrls.store}/selectById`, { sid: this.infoId }).then((response) => {
      let latitude = response.store.clatitude;
      let longitude = response.store.clongitude;
      this.center = [longitude, latitude];
      this.markers = [{ position: [longitude, latitude] }];
      this.circleCenter = [longitude, latitude];
      this.form.cname = response.store.cname;
      this.form.cregion = response.regionName;
      this.form.ccode = response.store.ccode;
      this.form.cmerchantId = response.store.cmerchantId;
      this.form.caddr = response.store.caddr;
      this.form.merchantName = response.merchant.cname;
      this.form.cmanagerName = response.store.cmanagerName;
      this.form.cmanagerPhone = response.store.cmanagerPhone;
      this.form.ctallymanName = response.store.ctallymanName;
      this.form.ctallymanPhone = response.store.ctallymanPhone;
      this.form.clocation = response.store.clocation;
      this.form.clongitude = longitude;
      this.form.clatitude = latitude;
      this.form.cbusinessHours = response.store.cbusinessHours;
      if (response.store.tstartDate) this.form.tstartDate = moment(response.store.tstartDate).format("YYYY-MM-DD");
      if (response.store.cstoreType) this.form.cstoreType = response.store.cstoreType.toString();
    });
  },
  methods: {
    goBack() {
      this.$emit("storeList");
    },
  },
};
</script>
<style lang="scss">
.storeInfo {
  .el-descriptions-item__label:not(.is-bordered-label) {
    color: #86909c;
  }
  .el-descriptions-item__content {
    color: #333333;
  }
  display: flex;
  height: 100%;
  margin: 10px 5px 5px 5px;
  &__left {
    padding: 10px;
    width: 85%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 1px 1px 5px #888888;
    margin-right: 20px;
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 5px #888888;
    background-color: white;
  }
}
.thisScrollbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
<style lang="scss">
.el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-descriptions__body {
  margin-left: 10px;
}
.el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 20px;
}
</style>
