import { Message } from "element-ui";
import defaultImage from "@/assets/default_image.png";
export function msgSuccess(msg) {
  Message({
    showClose: true,
    message: msg,
    type: "success",
    duration: 2000,
  });
}

export function msgError(msg) {
  Message({
    showClose: true,
    message: msg,
    type: "error",
    duration: 2000,
  });
}

export function msgWarning(msg) {
  Message({
    showClose: true,
    message: msg,
    type: "warning",
    duration: 2000,
  });
}

export function msg(msg) {
  Message({
    showClose: true,
    message: msg,
    duration: 2000,
  });
}
export function isImage(image) {
  if (image === "" || image === undefined) {
    return defaultImage;
  }
  return image;
}
