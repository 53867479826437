var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "出库制单",
            visible: _vm.dialogVisible,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 2, placeholder: "请输入撤回原因" },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { type: "warning" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "InventoryInInfo" },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3, colon: false } },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("系统单号:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm.info.csys))]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("出库类型:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm._f("inType")(_vm.info.ptype)))]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("原始单号:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm.info.ccode))]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 3, colon: false } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "出库门店" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("出库门店:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm.info.sstore))]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "出库时间" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("出库时间:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm._f("time")(_vm.info.tout)))]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "入库门店" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("入库门店:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm.info.sallotStore))]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 3, colon: false } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "制单人" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("制单人:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm.info.sout))]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "制单时间" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("制单时间:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm._f("time")(_vm.info.tcreate)))]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "审核状态" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("审核状态:")]
                    ),
                  ]),
                  _vm.info.paudit === 3
                    ? _c("span", { staticStyle: { color: "green" } }, [
                        _vm._v(_vm._s(_vm._f("auditState")(_vm.info.paudit))),
                      ])
                    : _vm.info.paudit === 4
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm._f("auditState")(_vm.info.paudit))),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm._f("auditState")(_vm.info.paudit))),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 3, colon: false } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "审单人" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("审单人:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm.info.saudit))]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "审单时间" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("审单时间:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm._f("time")(_vm.info.taudit)))]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "审核说明" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("审核说明:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm.info.cauditRemark))]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 1, colon: false } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "单据备注" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      { class: _vm.inventoryIn.rinventoryOrder ? "red" : "" },
                      [_vm._v("单据备注:")]
                    ),
                  ]),
                  _c(
                    "span",
                    { class: _vm.inventoryIn.rinventoryOrder ? "grey" : "" },
                    [_vm._v(_vm._s(_vm.info.cremark))]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 1, colon: false } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "搜索" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "SKU/条码/货品名称" },
                    on: { input: _vm.handleInput },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "h450" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: {
                    data: _vm.tableData,
                    height: "400",
                    "header-row-class-name": _vm.inventoryIn.rinventoryOrder
                      ? "red"
                      : "",
                    "row-class-name": _vm.inventoryIn.rinventoryOrder
                      ? "grey"
                      : "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      width: "50",
                      type: "index",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "SKU",
                      align: "center",
                      width: "70",
                      prop: "c_barcode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图片", align: "center", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.c_master_url != null
                              ? _c("el-image", {
                                  staticClass: "w50 h50",
                                  attrs: {
                                    "preview-src-list": [
                                      scope.row.c_master_url,
                                    ],
                                    src: scope.row.c_master_url,
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "货品编码",
                      align: "center",
                      width: "130",
                      prop: "c_sku",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "货品名称",
                      align: "center",
                      prop: "c_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位",
                      align: "center",
                      width: "50",
                      prop: "s_unit",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "库位",
                      align: "center",
                      width: "70",
                      prop: "z_inventory",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "数量",
                      align: "center",
                      width: "50",
                      prop: "n_out",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.inventoryIn.rinventoryOrder
                                    ? -scope.row.n_out
                                    : scope.row.n_out
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "10px" } },
                [
                  _c(
                    "el-button",
                    { attrs: { plain: "" }, on: { click: _vm.handleExport } },
                    [_vm._v("导出Excel")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", plain: "" },
                      on: { click: _vm.inventoryInCh },
                    },
                    [_vm._v("冲红")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }