export const rules = {
  cnum: [{ required: true, message: "请输入库位编号", trigger: "blur" }],
  cname: [{ required: true, message: "请输入库位名称", trigger: "blur" }],
  ntotal: [
    { required: true, message: "库位容量不能为空", trigger: "blur" },
    { validator: validatePositiveNumber, trigger: "blur" },
  ],
  nindex: [{ validator: validatePositiveIntegerOrEmpty, trigger: "blur" }],
};

function validatePositiveNumber(rule, value, callback) {
  const numValue = parseFloat(value);
  if (isNaN(numValue) || numValue <= 0) {
    callback(new Error("必须为大于零的正整数"));
  } else {
    callback();
  }
}

function validatePositiveIntegerOrEmpty(rule, value, callback) {
  // 如果值为空，则验证通过
  if (!value || value.trim() === "") {
    callback();
  } else {
    // 否则，验证为大于零的正整数
    const intValue = parseInt(value, 10);
    if (isNaN(intValue) || intValue <= 0) {
      callback(new Error("必须为大于零的正整数"));
    } else {
      callback();
    }
  }
}
