<template>
  <div class="storeListTree flex directionColumn">
    <el-tabs v-model="tabs" class="TabNav" type="card">
      <el-tab-pane label="权属关系" name="2" />
      <el-tab-pane label="行政区域" name="1" />
    </el-tabs>
    <div class="padding10">
      <el-input v-model="filterText" placeholder="分类名称" class="wHP" clearable />
    </div>
    <div class="scrollbar" style="flex: 1">
      <el-tree v-show="tabs === '1'" ref="baseAreaTree" show-checkbox class="filter-tree" :props="baseAreaProps" :data="baseAreaData" :default-expand-all="false" :filter-node-method="baseAreaFilter" @check-change="handleBaseAreaCheckChange" />
      <el-tree v-show="tabs === '2'" ref="merchantTree" show-checkbox class="filter-tree" :props="merchantProps" :data="merchantData" :default-expand-all="true" :filter-node-method="merchantFilter" @check-change="handleMerchantCheckChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreLeftTree",
  data() {
    return {
      filterText: "",
      tabs: "2",
      baseAreaData: [],
      baseAreaProps: {
        children: "children",
        label: "NODE_NAME",
      },
      merchantData: [],
      merchantProps: {
        children: "children",
        label: "c_name",
      },
      checkMerchant: [],
      checkBaseArea: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.baseAreaTree.filter(val);
      this.$refs.merchantTree.filter(val);
    },
  },
  created() {
    this.queryBaseAreaList();
    this.selectMerchantTree();
  },
  methods: {
    handleMerchantCheckChange(data, checked) {
      if (checked) {
        this.checkMerchant.push(data.sid);
      } else {
        const index = this.checkMerchant.indexOf(data.sid);
        if (index > -1) {
          this.checkMerchant.splice(index, 1);
        }
      }
      this.$emit("CheckMerchant", this.checkMerchant);
    },
    handleBaseAreaCheckChange(data, checked) {
      if (checked) {
        this.checkBaseArea.push(data.NODE_CODE);
      } else {
        const index = this.checkBaseArea.indexOf(data.NODE_CODE);
        if (index > -1) {
          this.checkBaseArea.splice(index, 1);
        }
      }
      this.$emit("CheckBaseArea", this.checkBaseArea);
    },
    baseAreaFilter(value, data) {
      if (!value) return true;
      return data.NODE_NAME.indexOf(value) !== -1;
    },
    merchantFilter(value, data) {
      if (!value) return true;
      return data.c_name.indexOf(value) !== -1;
    },
    /**
     * 获取省市县数据
     */
    queryBaseAreaList() {
      let url = "/api/system/baseArea/queryList";
      this.topsAjax.get(url).then((res) => {
        this.baseAreaData = res;
      });
    },
    /**
     * 获取权属关系
     */
    selectMerchantTree() {
      let url = `${this.baseUrls.merchant}/selectMerchantTree`;
      this.topsAjax.get(url).then((res) => {
        this.merchantData = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.storeListTree {
  background-color: white;
  height: 100%;
  margin: 3px;
  overflow: hidden;
}
</style>
