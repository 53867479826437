<template>
  <!-- 无人仓智控列表-->
  <div class="storeDevice">
    <div class="storeDevice__body box-shadow marginTop10 bcWhite padding10 flex1">
      <el-tabs v-model="tabs" @tab-click="handleTabClick">
        <el-tab-pane label="设备列表" name="1" />
        <el-tab-pane label="设备日志" name="2" />
      </el-tabs>
      <div v-if="tabs === '1'" class="wHP flex1">
        <dc-search-panel :params="queryPara" @search="doSearch" @reset="doSearch">
          <el-button slot="btns" size="mini" type="primary" @click="openDialog()">新增设备</el-button>
          <el-button slot="btns" :loading="btnLoading" size="mini" type="primary" @click="flash()">刷新设备</el-button>
        </dc-search-panel>
        <el-table ref="multipleTable" v-loading="tableLoading" max-height="500" border :data="tableData" tooltip-effect="dark" class="h650 scrollAuto scrollbar">
          <el-table-column label="设备号" align="center" prop="ccode" />
          <el-table-column label="指示灯" align="center" prop="bstatus">
            <template slot-scope="scope">
              <div class="center">
                <div :class="['circle', scope.row.bstatus ? 'green' : 'gray']"></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="终端名称" align="center" prop="coriginName">
            <template slot-scope="scope">
              <p>{{ scope.row.cname === undefined ? scope.row.coriginName : scope.row.cname }}</p>
            </template>
          </el-table-column>
          <el-table-column label="控制关联" align="center">
            <template slot-scope="scope">
              <el-switch v-if="scope.row.btype !== 4" v-model="scope.row.binStore" :disabled="scope.row.bvalid === 1" @change="operationInStore(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="终端控制" align="center">
            <template slot-scope="scope">
              <el-switch v-if="scope.row.btype !== 4" v-model="scope.row.bstatus" :disabled="scope.row.bvalid === 1" @change="operation(scope.row)"></el-switch>
              <el-button v-else type="text" size="mini" @click="openVideo(scope.row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right" width="150">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="editTerminal(scope.row)">修改</el-button>
              <el-button type="text" size="mini" class="red" @click="delById(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--        <el-pagination current-page.sync="1" :page-sizes="[10, 20, 50, 100]" :page-size="1" layout="total, sizes, prev, pager, next, jumper" :total="1"></el-pagination>-->
      </div>
      <div v-if="tabs === '2'" class="wHP flex1">
        <dc-search-panel :params="queryPara" @search="doSearch" @reset="doSearch">
          <dc-input field="cname" label="日志信息" placeholder="输入日志关键字" />
        </dc-search-panel>
        <dc-datatable ref="list" class="h500" :url="urlApi2" :params="queryPara">
          <el-table-column align="center" type="index" label="序" width="100" />
          <el-table-column align="center" prop="coperator" label="操作内容" />
          <el-table-column align="center" prop="rdevice" label="IP/设备" />
          <el-table-column align="center" prop="toperator" label="操作人">
            <template slot-scope="scope">
              {{ scope.row.toperator + " (" + scope.row.coperName + ")" }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="create" label="日志时间" />
        </dc-datatable>
      </div>
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" :title="title" :visible.sync="addDeviceOpen" width="30%" :before-close="handleClose" append-to-body>
      <addDevice v-if="addDeviceOpen" ref="addDeviceInfo" :device-id="deviceId" :store-id="storeId" @handleClose="handleClose" />
    </el-dialog>

    <el-dialog class="elDialog" :close-on-click-modal="false" title="修改终端名称" :visible.sync="editTerminalOpen" width="20%" append-to-body>
      <el-form ref="form" label-position="left">
        <el-form-item label="终端名称">
          <el-input v-model="newTerminalName" placeholder="请输终端名称"></el-input>
        </el-form-item>
        <el-form-item label="禁用">
          <el-switch v-model="disabled"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editTerminalOpen = false">取 消</el-button>
        <el-button type="primary" @click="saveTerminal">保 存</el-button>
      </span>
    </el-dialog>

    <!--    <el-dialog class="videoDialog" :close-on-click-modal="false" title="监控视频" :visible.sync="videoDialog" width="50%" append-to-body>-->
    <!--      <i-mou-video v-if="videoDialog" :device-id="deviceId" :channel-id="channelId" :pwd="pwd" />-->
    <!--    </el-dialog>-->
  </div>
</template>
<script>
import addDevice from "./addDevice";
// import iMouVideo from "@/components/ImouVideo/iMouVideo";

export default {
  name: "StoreDevice",
  components: {
    addDevice,
    // iMouVideo,
  },
  props: {
    storeName: {
      type: String,
      default: "",
    },
    storeId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "1", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      urlApi1: `${this.baseUrls.device}/queryPageList`,
      urlApi2: `${this.baseUrls.deviceLog}/queryPageList`,
      queryPara: {
        id: null,
        cname: null,
      },
      queryParams: {
        currentPage: 1,
        pageSize: 10,
      },
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      videos: [],
      disabled: false,
      btnLoading: false,
      tableLoading: false,
      terminalSid: "",
      newTerminalName: "",
      editTerminalOpen: false,
      videoDialog: false,
      total: 0,
      tableData: [],
      tabs: "1",
      deviceId: "",
      channelId: "",
      pwdMap: new Map(),
      pwd: "",
      title: "",
      addDeviceOpen: false,
    };
  },
  created() {
    this.queryPara.id = this.storeId;
    this.queryParams.id = this.storeId;
    //是否刷新设备状态
    this.queryParams.isFlash = false;
  },
  mounted() {
    this.queryDeviceList();
  },
  methods: {
    queryDeviceList() {
      this.tableLoading = true;
      this.topsAjax
        .get(this.urlApi1, this.queryParams)
        .then((response) => {
          this.total = response.total;
          if (response.list.length !== 0) {
            // 使用 reduce 方法和扩展运算符将 terminalList 数组合并成一个单一数组
            let mergedTerminalList = response.list.reduce((acc, curr) => {
              return [...acc, ...curr.terminalList];
            }, []);
            this.tableData = mergedTerminalList;
            this.tableData.sort((a, b) => a.bvalid - b.bvalid);
            for (let i = 0; i < response.list.length; i++) {
              let d = response.list[i];
              if (d.btype === 4) {
                this.pwdMap.set(d.ccode, d.cpwd);
              }
            }
            console.log(this.tableData)
          }
          this.tableLoading = false;
          this.btnLoading = false;
        })
        .catch((e) => {
          this.$message({
            message: "操作失败",
            type: "error",
          });
          this.tableLoading = false;
          this.btnLoading = false;
        });
    },
    handleTabClick(tab, event) {
      if (tab.index === "0") {
        this.queryDeviceList();
      }
    },
    handlePageChange(currentPage) {
      this.queryParams.pageNum = currentPage;
      this.queryDeviceList();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryDeviceList();
    },
    handleClose() {
      this.title = "";
      this.deviceId = "";
      this.addDeviceOpen = false;
      this.queryDeviceList();
    },
    operationInStore(row) {
      this.topsAjax.post(`${this.baseUrls.device}/operationInStore`, row).then((response) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    operation(row) {
      if (row.bstatus) {
        row.busiType = 2001;
      } else {
        row.busiType = 3001;
      }
      row.terminalId = row.cterminalId;
      row.terminalName = row.cname === undefined ? row.coriginName : row.cname;
      let stoDevice = JSON.parse(JSON.stringify(row));
      stoDevice.sid = null;
      this.topsAjax.post(`${this.baseUrls.device}/activateInStore`, stoDevice).then((response) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        setTimeout(() => {
          this.$emit("handleClose");
        }, 30000);
      });
      setTimeout(() => {
        this.flash();
      }, 180000);
    },
    saveTerminal() {
      let data = {
        sid: this.terminalSid,
        cname: this.newTerminalName,
        bvalid: this.disabled ? 1 : 0,
      };
      this.topsAjax.post(`${this.baseUrls.terminal}/updateById`, data).then((response) => {
        this.editTerminalOpen = false;
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.queryDeviceList();
      });
    },
    editTerminal(row) {
      this.newTerminalName = row.cname === undefined ? row.terminalName : row.cname;
      this.terminalSid = row.sid;
      this.disabled = row.bvalid !== 0;
      this.editTerminalOpen = true;
    },
    openVideo(row) {
      this.deviceId = row.ccode;
      this.channelId = row.cterminalId;
      this.pwd = this.pwdMap.get(row.ccode);
      // 将JSON字符串拼接到URL的查询参数中
      const url = `/components/videoWindows?deviceId=${this.deviceId}&channelId=${this.channelId}&pwd=${this.pwd}&storeName=${this.storeName}&deviceName=${row.cname === undefined ? row.coriginName : row.cname}`;
      // 打开新标签页
      window.open(url, "_blank");
      // this.videoDialog = true;
    },
    flash() {
      this.btnLoading = true;
      this.queryPara.id = this.storeId;
      this.queryParams.id = this.storeId;
      //是否刷新设备状态
      this.queryParams.isFlash = true;
      this.queryDeviceList();
    },
    openDialog(sid) {
      this.title = "新增设备";
      if (sid) {
        this.title = "编辑设备";
        this.deviceId = sid;
      }
      this.addDeviceOpen = true;
    },
    delById(row) {
      this.$confirm('是否确认删除终端"' + row.cname + '"？')
        .then((_) => {
          this.topsAjax.get(`${this.baseUrls.terminal}/deleteById/` + row.sid).then((response) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.queryDeviceList();
          });
        })
        .catch((_) => {});
    },
    doSearch() {
      this.$refs.list.load();
    },
  },
};
</script>

<style lang="scss" scoped>
.storeDevice {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;

  &__top {
  }

  &__body {
    overflow: auto;
    height: 100%;
    background-color: white;
  }
}

.green {
  background-color: #60e722;
}

.gray {
  background-color: gray;
}

.center {
  display: flex;
  justify-content: center;
}

.circle {
  width: 25px; /* 圆的直径 */
  height: 25px; /* 圆的直径 */
  border-radius: 50%; /* 使元素成为圆形的关键属性 */
}
</style>
