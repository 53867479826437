<template>
  <div class="InventorySheetInfo">
    <el-descriptions :column="3">
      <el-descriptions-item label="盘点单号">{{ sheetInfo.csys }}</el-descriptions-item>
      <el-descriptions-item label="所在门店">{{ sheetInfo.cstore }}</el-descriptions-item>
      <el-descriptions-item label="盘点方式">{{ sheetInfo.pway === 1 ? "自由盘点" : "指定盘点" }}</el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions :column="3">
      <el-descriptions-item label="盘点类型">
        {{ sheetInfo.type === 1 ? "日常盘点" : "临时盘点" }}
      </el-descriptions-item>
      <el-descriptions-item label="盘点周期">
        {{ sheetInfo.pcycle === 1 ? "按年盘点" : sheetInfo.pcycle === 2 ? "按季盘点" : sheetInfo.pcycle === 3 ? "按月盘点" : "" }}
      </el-descriptions-item>
      <el-descriptions-item label="盘点人">
        <div>123</div>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <div v-if="sheetInfo.pstate === 4 || sheetInfo.pstate === 5">
      <el-descriptions :column="1">
        <el-descriptions-item label="审核意见">
          <el-input v-model="auditRemark" placeholder="请输入内容" clearable />
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
    </div>
    <div class="flex">
      <div style="width: 25%">
        <el-descriptions :column="1">
          <el-descriptions-item label="单据状态">
            {{ sheetInfo.pstate | sheetState }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div style="width: 75%">
        <el-descriptions :column="1">
          <el-descriptions-item label="单据备注">
            {{ sheetInfo.cremark }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <el-divider></el-divider>
    <div v-if="sheetInfo.pstate === 6 || sheetInfo.pstate === 7">
      <el-descriptions :column="1">
        <el-descriptions-item label="审核意见">
          {{ sheetInfo.cauditRemark }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="h450">
      <el-table ref="multipleTable" :data="recordsList" height="450">
        <el-table-column label="序号" align="center" width="50" type="index" />
        <el-table-column label="SKU" align="center" width="70" prop="c_barcode" />
        <el-table-column label="图片" align="center" width="70">
          <template #default="scope">
            <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="货品编码" align="center" width="130" prop="c_sku" />
        <el-table-column label="货品名称" align="center" prop="s_goods" />
        <el-table-column label="单位" align="center" width="50" prop="s_unit" />
        <el-table-column label="库位号" align="center" width="70" prop="c_num" />
        <el-table-column label="库存数" align="center" width="70" prop="n_old_total" />
        <el-table-column label="盘点数" align="center" width="70" prop="n_update_total" />
        <el-table-column label="差额" align="center" width="50">
          <template #default="scope">
            <div>{{ Number.isInteger(parseFloat(scope.row.n_old_total) - parseFloat(scope.row.n_update_total)) ? parseFloat(scope.row.n_old_total) - parseFloat(scope.row.n_update_total) : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="盘点时间" align="center" width="100" />
      </el-table>
    </div>
    <div class="flex alignItemsCenter marginTop10" style="justify-content: space-between; flex: 1">
      <div>
        <el-button v-if="sheetInfo.pstate === 3 || sheetInfo.pstate === 4" plain @click="copyLink">复制链接</el-button>
      </div>
      <div>
        <el-button v-if="sheetInfo.pstate === 2" type="danger" plain @click="modifyInventorySheet">撤回</el-button>
        <el-button v-if="sheetInfo.pstate === 2" type="primary" @click="startInventory">盘点</el-button>
        <el-button v-if="sheetInfo.pstate === 3" type="primary" @click="onSubmit(4)">提交审核</el-button>
        <el-button v-if="sheetInfo.pstate === 5" plain @click="approved">审核通过</el-button>
        <el-button v-if="sheetInfo.pstate === 5" plain @click="onSubmit(7)">审核拒绝</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { determineExists } from "@/utils/utils";
import moment from "moment/moment";
import { orderAudit, orderOutType, sheetState, sheetType, sheetWay } from "@/utils/inventory";

export default {
  name: "InventorySheetInfo",
  filters: {
    sheetState(value) {
      return sheetState(value);
    },
  },
  props: {
    inventorySheetId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "1", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      sheetId: "",
      sheetInfo: {},
      recordsList: [],
      auditRemark: "",
    };
  },
  created() {
    this.sheetId = this.inventorySheetId;
    this.getInventoryInfo(this.sheetId);
  },
  methods: {
    copyLink() {
      let url = "http://8.130.17.15:3000/?sheetId=" + this.sheetId;
      const textarea = document.createElement("textarea");
      textarea.value = url;
      textarea.style.position = "fixed";
      textarea.style.opacity = "0";
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message({ message: "字符串已复制到剪贴板", type: "success" });
    },
    getInventoryInfo(id) {
      let url = `${this.baseUrls.inventorySheet}/getInventoryInfo`;
      let params = {
        id: id,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.sheetInfo = res.data.inventory;
        this.recordsList = res.data.inventoryDetailList;
      });
    },
    // 撤回
    modifyInventorySheet() {
      this.$confirm("确定要撤回并修改选定的盘点单？")
        .then((_) => {
          let url = `${this.baseUrls.inventorySheet}/update`;
          let parameter = {
            inventorySheet: {
              sid: this.sheetInfo.sid,
              pstate: 1,
            },
          };
          this.topsAjax.post(url, parameter).then((res) => {
            this.onCancel();
          });
        })
        .catch((_) => {});
    },
    //开始盘点
    startInventory() {
      let url = `${this.baseUrls.inventorySheet}/startInventory`;
      let parameter = {
        inventorySheet: {
          sid: this.sheetInfo.sid,
          pstate: 3,
          pway: this.sheetInfo.pway,
        },
      };
      this.topsAjax.post(url, parameter).then((res) => {
        this.$message({ message: "操作成功", type: "success" });
        this.onCancel();
      });
    },
    approved() {
      let url = `${this.baseUrls.inventorySheet}/approved`;
      let parameter = {
        inventorySheet: {
          sid: this.sheetInfo.sid,
          rstore: this.sheetInfo.rstore,
          pstate: 6,
          cauditRemark: this.auditRemark,
        },
      };
      this.topsAjax.post(url, parameter).then((res) => {
        if (res) {
          this.$message({ message: "操作成功", type: "success" });
          this.onCancel();
        }
      });
    },
    onSubmit(state) {
      // 校驗
      if (state === 4) {
        let run = false;
        this.recordsList.forEach((p) => {
          if (p.goodsId) {
            run = true;
            return;
          }
        });
        if (run) {
          this.$message({ message: "存在盘点数量为空的库位", type: "warning" });
          return;
        }
      }
      let url = `${this.baseUrls.inventorySheet}/update`;
      let parameter = {
        inventorySheet: {
          sid: this.sheetInfo.sid,
          pstate: state,
          cauditRemark: this.auditRemark,
        },
      };
      this.topsAjax.post(url, parameter).then((res) => {
        this.$message({ message: "操作成功", type: "success" });
        this.onCancel();
      });
    },
    onCancel() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style lang="scss">
.InventorySheetInfo {
  width: 100%;
  display: flex;
  flex-direction: column;

  .el-divider--horizontal {
    margin: 8px 0;
  }

  .el-input--medium .el-input__inner {
    height: 20px;
    line-height: 20px;
  }

  .el-input__inner {
    border: 0px;
  }

  .el-descriptions__body {
    margin-left: 0px;
  }

  .el-input__icon {
    display: flex !important;
    align-items: center !important;
  }
}
</style>
