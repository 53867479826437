var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "storeInfo" }, [
    _c("div", { staticClass: "storeInfo__left" }, [
      _c(
        "div",
        {
          staticClass:
            "fs20 fWB borderBottom paddingBottom10 flex alignItemsCenter",
        },
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _c("div", [_vm._v("门店详情")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex1 scrollbar padding10" },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "基本信息" } },
            [
              _c("el-descriptions-item", { attrs: { label: "门店编号" } }, [
                _vm._v(_vm._s(_vm.form.ccode)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "门店名称" } }, [
                _vm._v(_vm._s(_vm.form.cname)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "门店类型" } }, [
                _vm._v(
                  _vm._s(
                    _vm.form.cstoreType === "1"
                      ? "无人仓"
                      : _vm.form.cstoreType === "2"
                      ? "无人柜"
                      : ""
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "所属商户" } }, [
                _vm._v(_vm._s(_vm.form.merchantName)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "管理员" } }, [
                _vm._v(_vm._s(_vm.form.cmanagerName)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "手机号码" } }, [
                _vm._v(_vm._s(_vm.form.cmanagerPhone)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "理货员" } }, [
                _vm._v(_vm._s(_vm.form.ctallymanName)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "手机号码" } }, [
                _vm._v(_vm._s(_vm.form.ctallymanPhone)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "启用日期" } }, [
                _vm._v(_vm._s(_vm.form.tstartDate)),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "wFP" },
              [
                _c(
                  "el-descriptions",
                  { attrs: { column: 1 } },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "所在区域" } },
                      [_vm._v(_vm._s(_vm.form.cregion))]
                    ),
                    _c("el-descriptions-item", { attrs: { label: "地址" } }, [
                      _vm._v(_vm._s(_vm.form.clocation)),
                    ]),
                    _c("el-descriptions-item", { attrs: { label: "经纬度" } }, [
                      _vm._v(
                        _vm._s(_vm.form.clatitude + " " + _vm.form.clongitude)
                      ),
                    ]),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "详细地址" } },
                      [_vm._v(_vm._s(_vm.form.caddr))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "wFP" },
              [
                _c(
                  "el-amap",
                  {
                    staticClass: "h250 wHP",
                    attrs: { center: _vm.center, zoom: _vm.zoom },
                  },
                  _vm._l(_vm.markers, function (marker, index) {
                    return _c("el-amap-marker", {
                      key: index,
                      attrs: { position: marker.position },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "marginTop10" }, [
            _c("div", { staticClass: "fs16 fw700 marginBottom10" }, [
              _vm._v("配送范围"),
            ]),
            _c(
              "div",
              { staticClass: "h500 wHP" },
              [
                _c(
                  "el-amap",
                  { attrs: { center: _vm.center, zoom: _vm.zoom } },
                  [
                    _c("el-amap-circle", {
                      attrs: {
                        center: _vm.circleCenter,
                        radius: _vm.radius,
                        "stroke-color": "#ADD8E6",
                        "fill-color": "#ADD8E6",
                        "stroke-opacity": 1,
                        "stroke-weight": 3,
                        "fill-opacity": 0.35,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._m(0),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "storeInfo__right" }, [
      _c(
        "div",
        {
          staticClass:
            "paddingTop15 paddingBottom15 borderBottom textCenter marginBottom10",
        },
        [_vm._v("门店形象")]
      ),
      _c(
        "div",
        { staticClass: "flex1 scrollbar flex alignItemsCenter" },
        _vm._l(_vm.urls, function (url, index) {
          return _c("div", { key: index }, [
            _c(
              "div",
              {
                staticClass: "w150 h150 padding10 border bcGrey marginBottom5",
              },
              [
                _c("el-image", {
                  key: url,
                  staticClass: "hHP wHP",
                  attrs: { fit: "fit", src: url },
                }),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "marginTop10" }, [
      _c("div", { staticClass: "fs16 fw700" }, [_vm._v("更多信息")]),
      _c("div", { staticClass: "padding10 flex alignItemsCenter" }, [
        _c("div", [_vm._v("营业时间 24小时营业")]),
        _c("div", { staticClass: "marginLeftAuto" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }