var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeDeviceList bcWhite box-shadow padding10" },
    [
      _c("div", { staticClass: "fs20 fWB borderBottom paddingBottom10" }, [
        _vm._v("门店列表"),
      ]),
      _c(
        "dc-search-panel",
        {
          attrs: { params: _vm.queryPara },
          on: { search: _vm.doSearch, reset: _vm.doSearch },
        },
        [
          _c("div", { staticClass: "align-items" }, [
            _c(
              "div",
              [
                _c(
                  "el-input",
                  {
                    staticClass: "w250 input-with-select",
                    attrs: {
                      id: "searchInput",
                      clearable: "",
                      placeholder: "输入门店名称或地址",
                    },
                    model: {
                      value: _vm.queryPara.cname,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryPara, "cname", $$v)
                      },
                      expression: "queryPara.cname",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.doSearch },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "dc-datatable",
        { ref: "list", attrs: { url: _vm.urlApi, params: _vm.queryPara } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "index",
              label: "序",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "left",
              "header-align": "center",
              label: "门店名称",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "paddingLeft10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "cornflowerblue pointer",
                          attrs: { slot: "reference" },
                          on: {
                            click: function ($event) {
                              return _vm.openDialog(scope.row)
                            },
                          },
                          slot: "reference",
                        },
                        [_vm._v(" " + _vm._s(scope.row.cname) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "fs12 lightgray",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.clocation) +
                              "（" +
                              _vm._s(scope.row.caddr) +
                              "）"
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "类型", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.cstoreType === 1 ? "无人仓" : "无人柜"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "cmanagerName",
              label: "管理员",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "cmanagerPhone",
              label: "手机号",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cornflowerblue pointer",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [_vm._v(" " + _vm._s(scope.row.cmanagerPhone) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "address",
              label: "操作",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.openDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v("管理")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "5vh",
            visible: _vm.dialogVisible,
            width: "90%",
            "append-to-body": "",
            title: _vm.title,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("StoreDevice", {
                attrs: { "store-name": _vm.title, "store-id": _vm.storeId },
                on: { handleClose: _vm.onCancer },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }