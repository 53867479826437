<template>
  <div>
    <div class="flex alignItemsCenter" style="justify-content: space-between">
      <div class="flex alignItemsCenter">
        <el-radio-group v-model="radio2" @change="handleInventorySheetChange">
          <el-tooltip class="item" effect="dark" content="出库单" placement="top-end">
            <el-radio-button label="盘点单">
              <i class="el-icon-s-unfold"> </i>
            </el-radio-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="出库商品" placement="top-end">
            <el-radio-button label="盘点记录"><i class="el-icon-menu"></i></el-radio-button>
          </el-tooltip>
        </el-radio-group>
        <el-input v-model="search1" clearable placeholder="货品名称/货品编号/条形码" class="w300 marginLeft10" @keyup.enter.native="queryPageByStoreId">
          <el-button slot="append" icon="el-icon-search" @click="queryPageByStoreId"></el-button>
        </el-input>
      </div>
      <div class="flex alignItemsCenter">
        <el-select v-model="sheetWay" placeholder="盘点制单" style="width: 120px" class="marginTop10" @change="sheetWayChange">
          <el-option label="自由盘点" value="1" />
          <el-option label="指定盘点" value="2" />
          <el-option label="导入数据" value="3" />
        </el-select>
      </div>
    </div>
    <el-table v-if="radio2 === '盘点单'" class="marginTop10" height="500" :data="inventorySheetList">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" type="index" label="序号" width="50" />
      <el-table-column align="center" prop="csys" label="盘点单号" />
      <el-table-column align="center" label="盘点类型" width="100">
        <template slot-scope="scope">
          <div>{{ scope.row.ptype | sheetType }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="盘点方式" width="100">
        <template slot-scope="scope">
          <div>{{ scope.row.pway | sheetWay }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" width="100">
        <template slot-scope="scope">
          <div :class="{ grey: scope.row.pstate === 1, green: scope.row.pstate === 6, red: scope.row.pstate === 7, blue: scope.row.pstate === 3 || scope.row.pstate === 4 || scope.row.pstate === 5 }">{{ scope.row.pstate | sheetState }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="制单人" prop="screate" width="100" />
      <el-table-column align="center" label="盘点时间" width="160">
        <template slot-scope="scope">
          <div>{{ scope.row.tcreate | time }}</div>
        </template>
      </el-table-column>
      <el-table-column align="left" header-align="center" prop="address" label="操作" width="160">
        <template slot-scope="scope">
          <el-button v-if="scope.row.pstate === 1" type="text" size="mini" @click="updateById(scope.row.sid)">修改</el-button>
          <el-button v-if="scope.row.pstate === 2 || scope.row.pstate === 3" type="text" size="mini" @click="checkInventorySheetInfo(scope.row.sid)">详细</el-button>
          <el-button v-if="scope.row.pstate === 2" type="text" size="mini" @click="startInventory(scope.row)">盘点</el-button>
          <el-button v-if="scope.row.pstate === 3" type="text" size="mini" @click="submitAudit(scope.row.sid)">送审</el-button>
          <el-button v-if="scope.row.pstate === 4 || scope.row.pstate === 5 || scope.row.pstate === 6 || scope.row.pstate === 7" type="text" size="mini" @click="openInventorySheetDialog(scope.row.sid)">详情</el-button>
          <el-button v-if="scope.row.pstate === 4 || scope.row.pstate === 5" type="text" size="mini" @click="auditInventorySheet(scope.row)">审核</el-button>
          <el-button v-if="scope.row.pstate === 1" type="text" size="mini" class="red" @click="delByInventorySheet(scope.row.sid)">删除</el-button>
          <el-button v-if="scope.row.pstate === 2" type="text" size="mini" class="red" @click="modifyInventorySheet(scope.row)">撤回</el-button>
          <el-button v-if="scope.row.pstate === 4" type="text" size="mini" class="red" @click="goBackInventorySheet(scope.row.sid, '退回')">退回</el-button>
          <el-button v-if="scope.row.pstate === 6" type="text" size="mini" class="red">冲红</el-button>
          <span v-if="scope.row.pstate === 3" class="pointer icon iconfont grey marginLeft10" @click="copyLink(scope.row.sid)">&#xe683;</span>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="radio2 === '盘点单'" class="block">
      <el-pagination :current-page="queryParams2.currentPage" :page-size="queryParams2.pageSize" :total="total2" @current-change="handleCurrentChange2" />
    </div>
    <el-table v-if="radio2 === '盘点记录'" class="marginTop10" height="500" :data="inventoryRecordsList">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" type="index" label="序号" width="50" />
      <el-table-column align="center" label="盘点单号" prop="c_sys" />
      <el-table-column align="center" label="SKU" prop="c_barcode" width="100" />
      <el-table-column align="center" label="图片" width="70">
        <template #default="scope">
          <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="货品条码" prop="c_sku" />
      <el-table-column align="center" label="单位" prop="s_unit" width="100" />
      <el-table-column align="center" label="库位号" prop="c_warehouse" width="100" />
      <el-table-column align="center" label="库存数" prop="n_surplus" width="100" />
      <el-table-column align="center" label="盘点数" prop="n_update_total" width="100" />
      <el-table-column align="center" label="盘点时间">
        <template slot-scope="scope">
          <div>{{ scope.row.t_create | time }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="radio2 === '盘点记录'" class="block">
      <el-pagination :current-page="queryParams3.currentPage" :page-size="queryParams3.pageSize" :total="total3" @current-change="handleCurrentChange3" />
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="盘点详细" :visible.sync="dialogData.inventorySheetInfoDialogVisible" width="1000px" append-to-body>
      <inventorySheetInfo v-if="dialogData.inventorySheetInfoDialogVisible" :inventory-sheet-id="checkInventorySheet" @handleClose="handleClose" />
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="盘点单制单" :visible.sync="dialogData.inventorySheetEditDialogVisible" width="1000px" append-to-body @close="onclose">
      <inventorySheetEdit v-if="dialogData.inventorySheetEditDialogVisible" :set-way="sheetWay" :store-id="storeId" :inventory-id="inventoryId" @handleClose="handleClose" />
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { orderOutType, orderAudit } from "@/utils/inventory";
import { sheetState, sheetType, sheetWay } from "@/utils/inventory";
import { exportInventorySheet } from "@/api/goods/goods";
import inventorySheetEdit from "@/views/store/inventorySheet/inventorySheetEdit.vue";
import inventorySheetInfo from "@/views/store/inventorySheet/inventorySheetInfo.vue";

export default {
  name: "InventoryOrderIn",
  components: { inventorySheetEdit, inventorySheetInfo },
  filters: {
    time(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    InventoryOrderOutType(value) {
      return orderOutType(value);
    },
    InventoryOrderInType(value) {
      return orderInType(value);
    },
    orderAudit(value) {
      return orderAudit(value);
    },
    sheetState(value) {
      return sheetState(value);
    },
    sheetType(value) {
      return sheetType(value);
    },
    sheetWay(value) {
      return sheetWay(value);
    },
  },
  data() {
    return {
      sheetWay: "",
      search1: "",
      inventorySheetList: [],
      inventoryRecordsList: [],
      checkInventorySheet: null,
      storeId: null,
      inventoryId: null,
      queryParams2: {
        currentPage: 1,
        pageSize: 999,
      },
      queryParams3: {
        currentPage: 1,
        pageSize: 999,
      },
      radio2: "盘点单",
      total2: 0,
      total3: 0,
      dialogData: {
        inventorySheetInfoDialogVisible: false,
        inventorySheetEditDialogVisible: false,
      },
    };
  },
  methods: {
    onclose() {
      this.inventoryId = "";
      this.sheetWay = "";
    },
    sheetWayChange(value) {
      if (value === "3") {
        console.log(3);
      } else {
        this.dialogData.inventorySheetEditDialogVisible = true;
      }
    },
    copyLink(value) {
      let url = "http://8.130.17.15:3000/?sheetId=" + value;
      const textarea = document.createElement("textarea");
      textarea.value = url;
      textarea.style.position = "fixed";
      textarea.style.opacity = "0";
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message({ message: "字符串已复制到剪贴板", type: "success" });
    },
    // 送审
    submitAudit(id) {
      this.$confirm("是否提交审核？")
        .then((_) => {
          //
          let url = `${this.baseUrls.inventorySheet}/update`;
          let parameter = {
            inventorySheet: {
              sid: id,
              pstate: 5,
            },
          };
          this.topsAjax.post(url, parameter).then((res) => {
            this.queryPageByStoreId();
            this.checkInventorySheetInfo(id);
          });
        })
        .catch((_) => {});
    },
    // 开始盘点
    startInventory(row) {
      this.$confirm("是否开始盘点？")
        .then((_) => {
          let url = `${this.baseUrls.inventorySheet}/startInventory`;
          let parameter = {
            inventorySheet: {
              sid: row.sid,
              pstate: 3,
              pway: row.pway,
            },
          };
          this.topsAjax.post(url, parameter).then((res) => {
            this.queryPageByStoreId();
            this.checkInventorySheetInfo(row.sid);
          });
        })
        .catch((_) => {});
    },
    modifyInventorySheet(row) {
      this.$confirm("确定要撤回并修改选定的盘点单？")
        .then((_) => {
          let url = `${this.baseUrls.inventorySheet}/update`;
          let parameter = {
            inventorySheet: {
              sid: row.sid,
              pstate: 1,
            },
          };
          this.topsAjax.post(url, parameter).then((res) => {
            this.queryPageByStoreId();
            // this.updateById(row.sid);
          });
        })
        .catch((_) => {});
    },
    checkInventorySheetInfo(id) {
      this.checkInventorySheet = id;
      this.dialogData.inventorySheetInfoDialogVisible = true;
    },
    updateById(id) {
      this.inventoryId = id;
      this.dialogData.inventorySheetEditDialogVisible = true;
    },
    handleClose() {
      if (this.dialogData.inventorySheetEditDialogVisible) this.dialogData.inventorySheetEditDialogVisible = false;
      if (this.dialogData.inventorySheetInfoDialogVisible) this.dialogData.inventorySheetInfoDialogVisible = false;
      this.queryPageByStoreId();
    },
    activeAndSearchTabs(storeId) {
      this.storeId = storeId;
      this.queryPageByStoreId();
    },
    openInventorySheetDialog(value) {
      this.checkInventorySheet = value;
      this.dialogData.inventorySheetInfoDialogVisible = true;
    },
    auditInventorySheet(row) {
      if (row.pstate === 5) {
        this.checkInventorySheet = row.sid;
        this.dialogData.inventorySheetInfoDialogVisible = true;
      } else {
        let url = `${this.baseUrls.inventorySheet}/update`;
        let parameter = {
          inventorySheet: {
            sid: value,
            pstate: 5,
          },
        };
        this.topsAjax.post(url, parameter).then((res) => {
          this.queryPageByStoreId();
          this.checkInventorySheet = row.sid;
          this.dialogData.inventorySheetInfoDialogVisible = true;
        });
      }
    },
    exportInventorySheet() {
      this.topsAjax.get(`${this.baseUrls.store}/selectById`, { sid: this.storeId }).then((response) => {
        exportInventorySheet({ storeId: response.store.sid, storeName: response.store.cname });
      });
    },
    handleInventorySheetChange(val) {
      if (val === "盘点单") {
        this.queryPageByStoreId();
      } else {
        this.recordsQueryPageByStoreId();
      }
    },
    queryPageByStoreId() {
      this.search1 = this.search1.trim();
      this.inventorySheetList = [];
      let url = `${this.baseUrls.inventorySheet}/queryPageByStoreId`;
      let params = this.queryParams2;
      params.search = this.search1;
      params.storeId = this.storeId;
      this.topsAjax.get(url, params).then((res) => {
        this.total3 = res.total;
        this.inventorySheetList = res.list;
      });
    },
    handleCurrentChange2(val) {
      this.queryParams2.currentPage = val;
      this.queryPageByStoreId();
    },
    handleCurrentChange3(val) {
      this.queryParams3.currentPage = val;
      this.recordsQueryPageByStoreId();
    },
    recordsQueryPageByStoreId() {
      this.search1 = this.search1.trim();
      let url = `${this.baseUrls.inventoryRecords}/queryPageByStoreId`;
      let params = this.queryParams2;
      params.storeId = this.storeId;
      params.search = this.search1;
      this.topsAjax.get(url, params).then((res) => {
        this.total3 = res.total;
        this.inventoryRecordsList = res.list;
      });
    },
    delByInventorySheet(value) {
      this.$confirm("是否删除当前记录？")
        .then((_) => {
          let url = `${this.baseUrls.inventorySheet}/delById`;
          let params = { sheetId: value };
          this.topsAjax.post(url, params).then((res) => {
            this.$message("删除成功");
            this.queryPageByStoreId();
          });
        })
        .catch((_) => {});
    },
    goBackInventorySheet(value, title) {
      this.$confirm("是否" + title + "当前记录？")
        .then((_) => {
          let url = `${this.baseUrls.inventorySheet}/goBack`;
          let params = { sheetId: value };
          this.topsAjax.post(url, params).then((res) => {
            this.queryPageByStoreId();
            this.$message(title + "成功");
          });
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table .warning-row {
  color: red;
}

.el-radio-button__inner {
  width: 55px;
}
</style>
