<template>
  <!-- 出库制单-->
  <div style="text-align: center">
    <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url" :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">点击上传</div>
      <div slot="tip" class="el-upload__tip text-center">
        <div slot="tip" class="el-upload__tip"><el-button type="text" @click="handleExportTemplate">下载模板</el-button></div>
      </div>
    </el-upload>
    <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
      <div>
        <!-- 门店：<span style="color: blue">{{ storeInfo.cname }}</span> -->
      </div>
      <el-button type="primary" @click="submitFileForm">确定</el-button>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import clipboard from '@/utils/clipboard.min.js'
import { importInventoryDboutTemplate, exportInventoryDbout } from "@/api/goods/goods";
export default {
  name: "ImplInventoryDbout",
  props: {
    storeId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "1", // 设置一个默认值，如空字符串
    }
  },
  data() {
    return {
      // 门店信息
      storeInfo: {},
      upload: {
        isUploading: false,
        isConver: true,
        headers: { Authorization: getToken() },
        url: "/api/repository/repInventoryOrderOut/implInventoryOrderDbOut",
      },
    };
  },
  mounted() {
    this.getStoreInfo(this.storeId)
  },
  methods: {
    getStoreInfo(id) {
      let url = `${this.baseUrls.store}/get/` + id;
      this.topsAjax.get(url).then((res) => {
        this.storeInfo = res;
      });
    },
    submitFileForm() {
      this.$refs.upload.submit();
    },
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    handleFileSuccess(response, file, fileList) {
      console.log(response);
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + (response.code === 200 ? response.data.msg : response.message) + "</dev>", "导入结果", 
        { 
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          showConfirmButton: response.data && response.data.errorList.length > 0 ? true : false,
          confirmButtonText: '下载异常数据',
          callback: (action) => {
            console.log(action)
            if (action === 'confirm') {
              // clipboard.copy(response.code === 200 ? response.data.msg : response.message)
              // this.$message({
              //   type: 'success',
              //   message: `复制成功`
              // });
              // console.log(JSON.stringify(response.data.errorList))
              exportInventoryDbout({ parameters: JSON.stringify(response.data.errorList) })
            }
          }
        });
      this.$emit("handleClose");
    },
    handleExportTemplate() {
      importInventoryDboutTemplate()
    }
  },
};
</script>
<style lang="scss" scope>
.addDeliveryNote {
  height: 620px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .el-divider--horizontal {
    margin: 8px 0;
  }
  .el-input--medium .el-input__inner {
    height: 20px;
    line-height: 20px;
  }
  .el-input__inner {
    border: 0px;
  }
  //隐藏图标
  .el-input__icon {
    display: none;
  }
}
</style>
