var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "elDialog",
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.addInventoryInDialogVisible,
        width: "800px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.addInventoryInDialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "AddInventoryOut" },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "单号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "207px" },
                    attrs: { placeholder: "请输入内容", clearable: "" },
                    model: {
                      value: _vm.cSys,
                      callback: function ($$v) {
                        _vm.cSys = $$v
                      },
                      expression: "cSys",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "出库类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.inventoryInType,
                        callback: function ($$v) {
                          _vm.inventoryInType = $$v
                        },
                        expression: "inventoryInType",
                      },
                    },
                    _vm._l(_vm.inventoryInTypeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "原始单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", clearable: "" },
                    model: {
                      value: _vm.originalOrder,
                      callback: function ($$v) {
                        _vm.originalOrder = $$v
                      },
                      expression: "originalOrder",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 2 } },
            [
              _c("el-descriptions-item", { attrs: { label: "出库门店" } }, [
                _vm._v(_vm._s(_vm.storeInfo.cname)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "出库时间" } }, [
                _vm._v(_vm._s(_vm.deliveryNoteTime)),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _vm.inventoryInType === "20"
            ? _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "wFP" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1 } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "调入门店" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "w300",
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  "filter-method": _vm.selectByStoreName,
                                },
                                on: { change: _vm.checkStore },
                                model: {
                                  value: _vm.allotStoreInfo.cname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.allotStoreInfo, "cname", $$v)
                                  },
                                  expression: "allotStoreInfo.cname",
                                },
                              },
                              _vm._l(_vm.allotStoreOptions, function (item) {
                                return _c("el-option", {
                                  key: item.sid,
                                  attrs: { label: item.cname, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wFP AddInventoryIn__hideColon" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1 } },
                      [
                        _c("el-descriptions-item", [
                          _c(
                            "div",
                            { staticClass: "wFP flex" },
                            [
                              _c("div", { staticClass: "w100" }, [
                                _vm._v("调拔类型:"),
                              ]),
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择调拔类型" },
                                  model: {
                                    value: _vm.allotType,
                                    callback: function ($$v) {
                                      _vm.allotType = $$v
                                    },
                                    expression: "allotType",
                                  },
                                },
                                _vm._l(_vm.allotTypeOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "wFP flex" },
                            [
                              _c("div", { staticClass: "w100" }, [
                                _vm._v("调拔价格:"),
                              ]),
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择调拔价格" },
                                  model: {
                                    value: _vm.allotPrice,
                                    callback: function ($$v) {
                                      _vm.allotPrice = $$v
                                    },
                                    expression: "allotPrice",
                                  },
                                },
                                _vm._l(_vm.allotPriceOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.inventoryInType === "20" ? _c("el-divider") : _vm._e(),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "单据备注" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入内容", clearable: "" },
                        model: {
                          value: _vm.remarks,
                          callback: function ($$v) {
                            _vm.remarks = $$v
                          },
                          expression: "remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w200 flex",
                  staticStyle: { "justify-content": "flex-end" },
                },
                [
                  !_vm.repInventoryOrderInId
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini", plain: "" },
                          on: { click: _vm.ImpInventoryOrderOut },
                        },
                        [_vm._v("导入")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini", plain: "" },
                      on: { click: _vm.showOrderGoodsAdd },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.repInventoryOrderInId ? "重新选取" : "添加货品"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.removeSelectedRows },
                    },
                    [_vm._v("移除")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 1, colon: false } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "搜索" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "SKU/条码/货品名称" },
                    on: { input: _vm.handleInput },
                    model: {
                      value: _vm.selectSearch,
                      callback: function ($$v) {
                        _vm.selectSearch = $$v
                      },
                      expression: "selectSearch",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "h450" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: {
                    data: _vm.tableData,
                    height: "400",
                    "header-row-class-name": _vm.isRed ? "red" : "",
                    "row-class-name": _vm.isRed ? "grey" : "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      width: "50",
                      type: "index",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "SKU",
                      align: "center",
                      width: "70",
                      prop: "c_barcode",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图片", align: "center", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.c_master_url != null
                              ? _c("el-image", {
                                  staticClass: "w50 h50",
                                  attrs: {
                                    "preview-src-list": [
                                      scope.row.c_master_url,
                                    ],
                                    src: scope.row.c_master_url,
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "货品编码",
                      align: "center",
                      width: "130",
                      prop: "c_sku",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "货品名称",
                      align: "center",
                      prop: "c_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "单位", align: "center", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "库位",
                      align: "center",
                      width: "70",
                      prop: "c_num",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "数量",
                      align: "center",
                      width: "50",
                      prop: "num",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isRed ? -scope.row.num : scope.row.num
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "货品批次",
                      align: "center",
                      width: "60",
                      prop: "todo",
                    },
                  }),
                  _vm.isRed
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "address",
                          label: "操作",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showEditNum(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1498005855
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex alignItemsCenter",
              staticStyle: { "justify-content": "space-between", flex: "1" },
            },
            [
              _c("div", [
                _vm._v(" 共计 "),
                _c("span", { staticClass: "blue" }, [
                  _vm._v(_vm._s(_vm.tableData.length) + "种"),
                ]),
                _vm._v(" 货品 "),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit(0)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit(1)
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("implInventoryOut", {
            ref: "implInventoryOut",
            attrs: { title: "出库导入" },
            on: { handleClose: _vm.handleClose },
          }),
          _c("inventOrderGoodsAdd", {
            ref: "orderGoodsAdd",
            attrs: {
              goods: [],
              "select-store": { rstore: _vm.storeInfo.sid },
              "max-num": true,
            },
            on: { OrderGoodsAdd: _vm.AddGoods },
          }),
          _c(
            "el-dialog",
            {
              staticClass: "elDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "修改数量",
                visible: _vm.dialogEditVisible,
                width: "250px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogEditVisible = $event
                },
              },
            },
            [
              _c("el-input-number", {
                staticStyle: { width: "210px" },
                attrs: { placeholder: "数量", type: "number" },
                model: {
                  value: _vm.editNum,
                  callback: function ($$v) {
                    _vm.editNum = $$v
                  },
                  expression: "editNum",
                },
              }),
              _c("br"),
              _c("br"),
              _c(
                "div",
                {
                  staticClass: "flex alignItemsCenter",
                  staticStyle: {
                    "justify-content": "space-between",
                    flex: "1",
                  },
                },
                [
                  _c("div"),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-button", { on: { click: _vm.handleEditNum } }, [
                        _vm._v("确定"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }