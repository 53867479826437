var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeLocation" },
    [
      _c(
        "div",
        { staticClass: "storeLocation__left" },
        [
          _c("el-input", {
            staticClass: "wHP padding10",
            attrs: { placeholder: "输入门店名称查询" },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c(
            "div",
            { staticClass: "flex1 scrollbar" },
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree",
                attrs: {
                  data: _vm.storeList,
                  props: _vm.defaultProps,
                  "default-expand-all": "",
                  "filter-node-method": _vm.filterNode,
                },
                on: { "node-click": _vm.handleNodeClick },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "storeLocation__right" }, [
        _c(
          "div",
          { staticClass: "box-shadow bcWhite" },
          [_c("storeBrief", { attrs: { "store-id": _vm.queryPara.id } })],
          1
        ),
        _c(
          "div",
          { staticClass: "flex1 marginTop10 padding10 bcWhite border" },
          [
            _c(
              "div",
              { staticClass: "borderBottom paddingBottom10 flex" },
              [
                _c("el-page-header", { on: { back: _vm.goBack } }),
                _c("div", { staticClass: "fs20 fWB" }, [_vm._v("库位管理")]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "wHP flex1" },
              [
                _c(
                  "div",
                  { staticClass: "marginBottom10 marginTop10 search" },
                  [
                    _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "w300",
                            attrs: {
                              clearable: "",
                              placeholder: "请输入库位编号或库位名称",
                            },
                            model: {
                              value: _vm.queryPara.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryPara, "search", $$v)
                              },
                              expression: "queryPara.search",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: { slot: "append", icon: "el-icon-search" },
                              on: { click: _vm.doSearch },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "btns" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              slot: "btns",
                              size: "mini",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadTemp()
                              },
                            },
                            slot: "btns",
                          },
                          [_vm._v("下载模板")]
                        ),
                        _c("input", {
                          ref: "upload",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: ".xlsx,.xls" },
                          on: { change: _vm.onImportExcel },
                        }),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              slot: "btns",
                              size: "mini",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.excel_file()
                              },
                            },
                            slot: "btns",
                          },
                          [_vm._v("导入")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "marginLeft10",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.openDialog()
                              },
                            },
                          },
                          [_vm._v("新增库位")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "dc-datatable",
                  {
                    ref: "list",
                    attrs: { url: _vm.urlApi, params: _vm.queryPara },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        type: "index",
                        label: "序",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "cnum",
                        label: "库位编号",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "cname",
                        label: "库位名称",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "cremark",
                        label: "库位叙述",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "ntotal",
                        label: "库位容量",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "nindex",
                        label: "排序号",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "状态", width: "150" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.bvalid === 0 ? "启用" : "停用"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "address",
                        label: "操作",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialog(scope.row.sid)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delById(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.addLocationOpen,
            width: "30%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addLocationOpen = $event
            },
          },
        },
        [
          _vm.addLocationOpen
            ? _c("addLocation", {
                ref: "addLocationInfo",
                attrs: {
                  "store-id": _vm.queryPara.id,
                  "warehouse-id": _vm.warehouseId,
                },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }