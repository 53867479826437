var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeManage bcWhite box-shadow padding10" },
    [
      _c("div", { staticClass: "fs20 fWB borderBottom paddingBottom10" }, [
        _vm._v("门店列表"),
      ]),
      _c(
        "div",
        {
          staticClass: "flex",
          staticStyle: { padding: "10px", border: "1px solid #ebeef5" },
        },
        [
          _c(
            "el-input",
            {
              staticClass: "w250 input-with-select",
              attrs: { clearable: "", placeholder: "输入门店名称或地址" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.queryList.apply(null, arguments)
                },
              },
              model: {
                value: _vm.queryPara.search,
                callback: function ($$v) {
                  _vm.$set(_vm.queryPara, "search", $$v)
                },
                expression: "queryPara.search",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.queryList },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flexGrow1", staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                border: "",
                "element-loading-text": "加载中，请稍等...",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "index",
                  index: _vm.indexMethod,
                  label: "序",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  "header-align": "center",
                  label: "门店名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "paddingLeft10" }, [
                          _c(
                            "div",
                            {
                              staticClass: "cornflowerblue pointer",
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.StoreGoods(scope.row.sid)
                                },
                              },
                              slot: "reference",
                            },
                            [_vm._v(" " + _vm._s(scope.row.c_name) + " ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "fs12 lightgray",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                _vm._s(scope.row.c_location) +
                                  "（" +
                                  _vm._s(scope.row.c_addr) +
                                  "）"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "类型", width: "100" } },
                [_vm._v("无人仓")]
              ),
              _c("el-table-column", {
                attrs: { align: "center", label: "管理员", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: scope.row.c_manager_phone,
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "cornflowerblue pointer" },
                              [_vm._v(_vm._s(scope.row.c_manager_name))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "goodsVariety",
                  label: "货品种类",
                  width: "130",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "inventoryQuantity",
                  label: "库存总量",
                  width: "130",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.queryPara.total != 0
        ? _c(
            "div",
            { staticClass: "h50" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryPara.currentPage,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryPara.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.queryPara.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }