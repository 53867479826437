<template>
  <!-- 门店信息 -->
  <div class="storeList bcWhite box-shadow padding10">
    <div class="fs20 fWB borderBottom paddingBottom10">门店列表</div>
    <div style="padding: 10px;border: 1px solid #ebeef5;">
      
      <el-input v-model="queryPara.cname" clearable placeholder="输入门店名称或地址" class="w250 input-with-select" @keyup.enter.native="queryList">
        <el-button slot="append" icon="el-icon-search" @click="queryList"></el-button>
      </el-input>
      <div class="btns" style="float: right;">
          <el-button slot="btns" size="mini" style="margin-left: 10px" type="primary" @click="downloadTemp()">下载模板</el-button>
          <input ref="upload" type="file" style="display: none" accept=".xlsx,.xls" @change="onImportExcel" />
          <el-button slot="btns" size="mini" style="margin-left: 10px" type="primary" @click="excel_file()">导入</el-button>
          <el-button slot="btns" size="mini" style="margin-left: 10px" type="primary" @click="storeAddAndSet()">新增门店</el-button>
        </div>
    </div>
    <!-- <dc-search-panel :params="queryPara" @search="doSearch" @reset="doSearch">
      <div class="flex search">
        <div>
          <el-input id="searchInput" v-model="queryPara.cname" clearable placeholder="输入门店名称或地址" class="w250 input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
          </el-input>
        </div>
        <div class="btns">
          <el-button slot="btns" size="mini" style="margin-left: 10px" type="primary" @click="downloadTemp()">下载模板</el-button>
          <input ref="upload" type="file" style="display: none" accept=".xlsx,.xls" @change="onImportExcel" />
          <el-button slot="btns" size="mini" style="margin-left: 10px" type="primary" @click="excel_file()">导入</el-button>
          <el-button slot="btns" size="mini" style="margin-left: 10px" type="primary" @click="storeAddAndSet()">新增门店</el-button>
        </div>
      </div>
    </dc-search-panel> -->
    <el-table ref="list" :data="tableData" border v-loading="loading" element-loading-text="加载中，请稍等...">
      <el-table-column align="center" type="index" label="序号" :index="indexMethod" width="100" />
      <el-table-column align="left" header-align="center" label="门店名称">
        <template slot-scope="scope">
          <div class="paddingLeft10">
            <div slot="reference" class="cornflowerblue pointer" @click="storeInfo(scope.row.sid)">
              {{ scope.row.cname }}
            </div>
            <div slot="reference" class="fs12 lightgray">{{ scope.row.clocation }}（{{ scope.row.caddr }}）</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="类型" width="100">
        <template slot-scope="scope">
          {{ scope.row.cstoreType === 1 ? "无人仓" : scope.row.cstoreType === 2 ? "无人柜" : "" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="cmanagerName" label="管理员" width="100"></el-table-column>
      <el-table-column align="center" prop="cmanagerPhone" label="手机号" width="130">
        <template slot-scope="scope">
          <div slot="reference" class="cornflowerblue pointer">
            {{ scope.row.cmanagerPhone }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="storeAddAndSet(scope.row.sid)">编辑</el-button>
          <el-button type="text" size="mini" @click="handleDelete(scope.row)">
            <span class="red">删除</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="h50" v-if="queryPara.total != 0">
        <el-pagination :current-page="queryPara.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="queryPara.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="queryPara.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "StoreList",
  components: {},
  props: {
    merchantList: {
      type: Array,
      default: null,
    },
    baseAreaList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      urlApi: `${this.baseUrls.store}/queryPageList`,
      queryPara: {
        cname: null,
        baseArea: null,
        merchant: null,
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      loading: false
    };
  },
  watch: {
    merchantList() {
      this.queryPara.merchant = this.merchantList + "";
      this.doSearch();
    },
    baseAreaList() {
      this.queryPara.baseArea = this.baseAreaList + "";
      this.doSearch();
    },
  },
  created() {},
  mounted() {
    // let that = this;
    // var searchInput = document.getElementById("searchInput");
    // searchInput.addEventListener("keydown", function (event) {
    //   if (event.key === "Enter") {
    //     that.doSearch();
    //   }
    // });
    this.queryList();
  },
  methods: {
    handleSizeChange(val) {
      this.queryPara.pageSize = val;
      this.queryList();
    },
    handleCurrentChange(val) {
      this.queryPara.currentPage = val;
      this.queryList();
    },
    queryList() {
      this.loading = true
      if (this.queryPara.search) {
        this.queryPara.search = this.queryPara.search.trim()
      }
      
      let url = `${this.baseUrls.store}/queryPageList`;
      this.topsAjax.get(url, this.queryPara).then((res) => {
        this.tableData = res.list;
        this.queryPara.total = res.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      });
    },
    indexMethod(index) {
      // let page = this.$refs.list.page;
      return index + 1 + (this.queryPara.currentPage - 1) * this.queryPara.pageSize;
    },
    doSearch: _.debounce(function () {
      // 这里是你想要执行的代码
      this.$refs.list.load();
    }, 1000),
    /**
     * 门店详细
     * @constructor
     */
    storeInfo(id) {
      this.$emit("StoreInfo", id);
    },
    /**
     * 门店添加与修改
     * @constructor
     */
    storeAddAndSet(id) {
      this.$emit("AddAndSet", id);
    },
    /**
     * 軟刪除
     * @param row
     */
    handleDelete(row) {
      this.$confirm('是否确认删除商户"' + row.cname + '"？')
        .then((_) => {
          const url = `${this.baseUrls.store}/deleteById`;
          this.topsAjax.post(url, { sid: row.sid }).then((response) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.$refs.list.load();
          });
        })
        .catch((_) => {});
    },
    //选择excel按钮
    excel_file() {
      this.$refs.upload.click();
    },
    //excel导入文件
    onImportExcel(e) {
      // 通过文件选择器选择文件
      this.$loading();
      let selectedFile = event.target.files;
      let url = `${this.baseUrls.store}/implStore`;
      let params = {
        file: selectedFile[0],
      };
      this.topsAjax.postForm(url, params).then((res) => {
        this.$loading().close();
        this.$message.success("导入成功！");
      });
      this.$refs.upload.value = null;
    },
    downloadTemp() {
      let url = `${this.baseUrls.store}/downloadTemp`;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.storeList {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .search {
    justify-content: space-between;
    .btns {
      display: flex;
      flex-direction: row;
    }
  }
}
</style>
